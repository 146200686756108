<template>
  <div>
    <div>
      <div v-if="items.length > 0">
        <div class="row mobileoff" style="font-weight:bold;">
          <div class="column" style="flex-grow:3;">
              Name
          </div>
          <div class="column" style="flex-grow:1;text-align:right;">
              Qty
          </div>
          <div class="column" style="flex-grow:1;text-align:right;">
              Price
          </div>
          <div class="column" style="flex-grow:1;text-align:right;">
              Amt
          </div>
        </div>
        <div v-for="(item, index) in items" :key="item.item_id">
          <div v-on:click="edititem(item.invoice_item_id, item.item_name, item.item_description, item.item_price, item.item_quantity,  index)" class="row" style="cursor:pointer;border:0px solid #ccc;border-top:1px solid #ccc;font-weight:bold;">
            <div class="column" style="flex-grow:3;">
                {{ item.item_name }}
            </div>
            <div class="column" style="flex-grow:1;text-align:right;">
                {{ item.item_quantity }}
            </div>
            <div class="column" style="flex-grow:1;text-align:right;">
                {{ item.item_price / 100 | toCurrency }}
            </div>
            <div class="column" style="flex-grow:1;text-align:right;">
                {{ parseFloat(item.item_price / 100) * (item.item_quantity) | toCurrency }}
            </div>
          </div>
          <div v-on:click="edititem(item.invoice_item_id, item.item_name, item.item_description, item.item_price, item.item_quantity,  index)" class="row" style="cursor:pointer;">
            <div class="column" style="flex-grow:4;">
                {{ item.item_description }}
            </div>
          </div>
        </div> 
      </div>

      <div class="additembox">
        <div class="row">
            <div class="column" style="flex-grow:3;">
                <input type="text" v-model="new_item_name" placeholder="Item Name">
            </div>
            <div class="column" style="flex-grow:0;">
                <input type="number" v-model="new_item_quantity" placeholder="Quantity">
            </div>
            <div class="column" style="flex-grow:2;">
                <input type="number" v-model="new_item_price" placeholder="Price">
            </div>
        </div>
        <div class="row">
            <div class="column" style="flex-grow:4;">
                <input type="text" v-model="new_item_description" placeholder="Item Description">
            </div>
            <div class="column" style="flex-grow:0;min-width:100px;padding-top:12px;">
                <button class="btn-add" style="width:100px;" v-on:click="makeitem" :disabled="new_item_name.length < 1">Save Item</button>
            </div>
        </div>
      </div>

    </div>

    <div class="row">
        <div class="column" style="text-align:right;">
        <h2>
            Total: {{ invoice_total / 100 | toCurrency }}
        </h2>
        </div>
    </div>

    
    <!-- use the modal component, pass in the prop -->
    <modal v-if="showEditItem" @close="showEditItem = false">
      <template v-slot:header>
        <h3>Edit Item</h3>
      </template>
      <template v-slot:body>
        <div class="row">
            <div class="column" style="flex-grow:3;">
            <input type="text" v-model="edit_item_name" placeholder="Item Name">
            </div>
        </div>
        <div class="row">
            <div class="column" style="flex-grow:4;">
            <input type="text" v-model="edit_item_description" placeholder="Item Description">
            </div>
        </div>
        <div class="row">
            <div class="column" style="flex-grow:1;">
            <input type="number" v-model="edit_item_quantity" placeholder="Quantity">
            </div>
            <div class="column" style="flex-grow:2;">
            <input type="number" v-model="edit_item_price" placeholder="Price">
            </div>
        </div>
        <div class="form-group" style="display:flex;flex-direction:row;justify-content:space-between;">
            <button style="margin-right:10px;" class="btn btn-primary" @click="editInvoiceItem">Save</button>
            <button class="btn btn-danger" @click="deleteInvoiceItem(edit_item_id, edit_index)">Delete Item</button>
        </div>
      </template>
      <template v-slot:footer>
        <div v-if="edit_item_msg">{{edit_item_msg}}</div>
        <div v-else>Save your changes or click "Close" to cancel</div>
      </template>
    </modal>
  </div>
</template>

<script>
import { itemService } from '../../_services' ;
import modal from "@/components/includes/InfoModal"

export default {
  props: [
    'iid',
    'cid'
  ],
  data () {
      return {
        showCompleted: false,
        members: [],
        items: [],
        invoice_total: '',
        new_item_name: '',
        new_item_description: '', 
        new_item_price: '',
        new_item_quantity: '',
        loading: true,
        error: '',
        open: true,
        showEditItem: false,
        edit_item_id: '',
        edit_item_name: '',
        edit_item_description: '', 
        edit_item_price: 0,
        edit_item_quantity: 0,
        edit_index: 0,
        edit_item_msg: "",
      }
  },
  methods: {
    construction: (d) => {
      alert(d)
    },
    edititem: function (item_id, item_name, item_description, item_price, item_quantity, index) {
        // console.log(item_id)
        this.edit_item_id = item_id ;
        this.edit_item_name = item_name ;
        this.edit_item_description = item_description ;
        this.edit_item_price = item_price / 100 ;
        this.edit_item_quantity = item_quantity ;
        this.edit_index = index ;
        this.showEditItem = true ;
    },
    makeitem: function () {
        let ip = parseInt(parseFloat(this.new_item_price) * 100)
        let iq = parseInt(this.new_item_quantity)
      itemService.createItem(this.cid,
                            this.iid,
                            this.new_item_name, 
                            this.new_item_description, 
                            ip, 
                            iq ).then(
        data => {
          this.items.push(data.invoice_item)
          this.invoice_total = this.invoice_total + (data.invoice_item.item_price * data.invoice_item.item_quantity)
          this.new_item_name = '' ; 
          this.new_item_description = '' ; 
          this.new_item_price = '' ; 
          this.new_item_quantity = '' ;
        }
      )
    },
    deleteInvoiceItem: function (item_id, index) {
      if(confirm("Really want to delete this item?")){
        itemService.deleteItem(this.cid, this.iid, item_id).then(
          data => {
            if (data.status == "success") {
              this.items.splice(index, 1) ;
              this.edit_item_id = '',
              this.edit_index = ''
              this.showEditItem = false ;
            } else {
              alert("Ran into an issue trying to delete this item. Please try again.")
            }
          }
        )
      }
    },
    editInvoiceItem: function () {
        let ip = parseInt(parseFloat(this.edit_item_price) * 100)
        let iq = parseInt(this.edit_item_quantity)
      itemService.updateItem(this.cid, this.iid, 
                                    this.edit_item_id,
                                    this.edit_item_name,
                                    this.edit_item_description,
                                    ip,
                                    iq ).then(
        data => {
          if (data.status == "success") {
            this.loadInvoiceItems() ;
            // this.items[this.edit_index].item_name = data.invoice_item.item_name ;
            // this.items[this.edit_index].item_description = data.invoice_item.item_description ;
            // this.items[this.edit_index].item_price = data.invoice_item.item_price ;
            // this.items[this.edit_index].item_quantity = data.invoice_item.item_quantity ;            
            this.edit_item_id = '' ; 
            this.edit_item_name = '' ; 
            this.edit_item_description = '' ; 
            this.edit_item_price = '' ; 
            this.edit_item_quantity = '' ;
            this.edit_index= 0
            this.edit_item_msg = ""
            this.showEditItem = false ;
          } else {
            alert("Ran into an issue trying to update this item. Please try again.")
          }
        },
        () => {
          // console.debug(error)
          alert("Ran into an issue trying to update this item. Please try again.")
        }
      )
    },
    loadInvoiceItems: function () {
      itemService.readClientItems(this.cid, this.iid).then(
        data => {
          //console.log(data)
          this.items = data.invoice_items
          this.invoice_total = data.invoice_total
          this.loading = false
        },
        error => {
          //console.log(error)
          this.error = error
          this.loading = false
        }
      )
    },
  },
  components: {
    modal
  },
  created () {
    this.loadInvoiceItems();
  },
  computed: {
  }
};
</script>

<style>
@media print {
  .edlink {
      display: none ;
  }
  .additembox {
      display: none ;
  }
  .breadcrumbs {
      display: none ;
  }
  .column.editbtn {
    display: none;
  }
}




.additembox {
  border-top: 2px dotted #ccc;
  border: 2px dotted #ccc;
  margin: 20px 0 0 0;
  padding:10px;
}

.row.itemheader {
    border-bottom:4px solid #ccc ;
    font-weight:bold ;
}
@media (max-width: 415px) {
  .row.itemheader {
      display:none;
  }
}
.item {
  padding: 10px ;
}
.newitem {
  text-align: center ;
  padding: 40px 4px ;
  cursor: pointer ;
}

  /* wrapper-div for including arrow */
  .custom-select {
    background-color: #eee;
    float: left;
    margin-right: 10px;
    position: relative;    
  }

  .custom-select select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none; /* remove default styling */
    background-color: inherit;
    border: none;
    color: #333;
    display: block;
    font-size: 16px;
    height: 32px;
    padding: 5px 30px 5px 10px;
    margin: 0;
    outline: none;
  }

  /* drop arrow */
  .custom-select:after {
    content: '\25bc'; 
    color: #AAA;
    font-size: 12px;
    position: absolute;
    right: 8px;
    top: 10px;
  }

</style>