<template>
  <div class="content homebg">
    <div class="row" style="max-width:1200px;margin:auto;">
      <div class="homehero column">
        <div class="marketline">Business Coaching</div>
        <h1>
          We help startup teams and small businesses work through challenges.
        </h1>

        <p style="max-width:600px;margin-bottom:30px;"> 
          We have both in-person and Zoom seminars to help startup teams, and leaders, overcome challenges,
          improve efficiency, and get on track.
        </p>

        <p>
          Want to learn more?
          <a href="/contact">
            Schedule a call
          </a>
        </p>
        
        <!-- use the modal component, pass in the prop -->
        <modal v-if="showModal" @close="showModal = false">
          <template v-slot:header>
            <h3>Foclarity Business Management Demo</h3>
          </template>
          <template v-slot:body>
            <iframe style="margin-top:10px;" width="100%" height="315" src="https://www.youtube.com/embed/yx35wgWOS34" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        
          </template>
          <template v-slot:footer>
            <p>Foclarity helps you see the big picture... try it free!</p>
          </template>
        </modal>
      </div>
      <div class="column">
        <div class="quotebubble">
          <div style="font-weight:700;color:#eead0e;padding-top:10px;">CLIENT FEEDBACK</div>
          <div style="margin-top:30px;">
            “Our employees were feeling disorganized and unclear about how they could impact the business most. 
            We engaged with the team at Foclarity and they made it fun and easy to align on goals, set up tracking and share progress.”
          </div>
          <div style="margin-top:10px;">
            <p class="tiny" style="line-height:.8rem;">
              - Anthony Ettinger<br>
              Owner, ProFullStack.com
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row middled">
      <div class="column" style="padding:20px;text-align:center;">
        <h2 style="font-size:2rem;">
          Align. Measure. Achieve.
        </h2>
        <p>
          <a href="/contact">
            <button style="padding:10px 14px;"> Schedule a Call </button>
          </a>
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";
import modal from "@/components/includes/InfoModal"

export default {
  title: 'Entrepreneur Coaching | Foclarity',
  name: "BusinessCoaching",
  mounted: function() {
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
    register: function () {
      this.$router.push("register");
    }
  },
  components: {
    modal
  },
  data () {
      return {
        showModal: false
      }
  },
  created () {
  }
};
</script>

<style lang="scss" scoped>
.homebg {
  background: #fff url("~@/assets/homebg-gray@2x-80.jpg")no-repeat top right ; 
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-size: contain ;
  @media only screen and (max-width: 440px) {
    background: #ffffff url("~@/assets/homebg-gray-80.jpg") top right no-repeat;
    background-position-x: 0px;
  }
}
.homehero {
  padding: 20px ;
  max-width: 1200px ;
  min-width: 300px;
  margin-left:auto;
  margin-right:auto;
  & h1 {
    margin:20px 0 70px 0;
    max-width: 600px ;
  }
  & a {
    text-decoration: none;
    & .signupbox {
      text-align: center;
      padding: 8px;
      background: #0066CC ;
      // background: #41495B;
      width: 250px;
      border-radius: 4px;
      color: #fff;
    }
  }
  @media only screen and (max-width: 440px) {
    & h1 {
      margin:20px 0 20px 0;
      font-size: 2rem ;
    }
    padding: 0px 8px ;
  }
}
.heroblue {
  background: #ffffff; //linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  min-height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #333;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
}
.marketline {
  font-size: 1rem;
  text-transform: uppercase;
  color:#333;
  font-weight: 700;
}

.quotebubble {
  color:#fff;
  border:10px solid #eeeeee;
  background:#333;
  padding:40px;
  border-radius:50%;
  width:400px;
  height:400px;
  text-align:center;
  margin: 20px auto;
  @media (max-width: 415px) {
    border:0px solid #0066CC;
    background:#333;
    padding:20px 10px;
    border-radius:0%;
    width:100%;
    height:auto;
  }
}
</style>

