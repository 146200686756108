<template>
  <div class="content">
    <div class="middled">
      <div>
        <h1>
          Templates
        </h1>
      </div>
      <div class="bodyblock" v-show="loading" style="text-align:center;">Loading...</div>
      <div class="column" v-show="!loading">
        <div v-if="templates.length > 0">
          <div v-for="(scp, index) in templates" :key="index">
            <div class="row spacer">
              <div class="categorycard" v-on:click="gotoScorecard(scp.scorecard_id)">
                <div class="catname">
                  {{scp.scorecard_name}}
                </div>
              </div>
            </div>
          </div>

        </div>
        <div v-else>
          <p style="text-align:center;">No Templates.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { router } from '../_helpers';
import { isLoggedIn } from "../utils/auth";
import { scorecardService } from "../_services/scorecard.service"

export default {
  title: 'Templates | Foclarity',
  name: "Templates",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  methods: {
    premiumFeature: (msg) => {
      alert(msg + " is a Premium Feature. Contact us to upgrade.")
    },
    isLoggedIn() {
      return isLoggedIn();
    },
    gotoScorecard: (q) => {
      if (q == 'marketing') {
        router.push("scorecards/1")
      } else if (q == 'sales') {
        router.push("scorecards/10")
      }
      else {
        // console.debug('going to ' + q)
        router.push("/scorecards/" + q)
      }
    },
    switchScorecard: (scorecard_id) => {
      scorecardService.switchScorecard(scorecard_id).then(router.push("scorecards/"+scorecard_id))
    },
    // eslint-disable-next-line no-unused-vars
    handleSubmit (e) {
        this.submitted = true;
        const { name } = this;

        // stop here if form is invalid
        if (!(name)) {
            return;
        }

        this.loading = true;
        scorecardService.createScorecard(name)
            .then(
                // eslint-disable-next-line no-unused-vars
                scorecard => router.push("dashboards"),
                error => {
                    this.error = error ;
                    this.loading = false ;
                    this.submitted = false ;
                }
            );
    }
  },
  components: {},
  data () {
      return {
        templates: [],
        name: '',
        submitted: false,
        loading: true,
        returnUrl: '',
        error: '',
        lastlogin: ''
      }
  },
  created () {
    scorecardService.readPublicScorecards().then(data => {
      this.templates = data
      this.loading = false
    },
    error => {
      console.log(error)
    }
    );

    //userService.getMe()

  }
};
</script>

<style lang="scss" scoped>
.welcomeback {
  padding: 40px;
  text-align: center;
  border: 1px solid #ccc;
}

.heroblue {
  background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
  & a {
    text-decoration: none;
    color: #fff;
    & .signupbox {
      text-align: center;
      padding: 8px;
      font-size: 24px;
      font-weight: bold;
      background: #ff7f24;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: auto;
    }
  }
}

</style>

