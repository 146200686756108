<template>    
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <div @click="$emit('close')" style="float:right;cursor:pointer;">
                Close
            </div>
            <slot name="header">
                <h4>Ask anything, or send feedback:</h4>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
                <div>
                <div class="form-grp">
                    <textarea v-model="message" name="message" />
                </div>
                <div class="form-grp">
                    <button class="btn btn-primary" :disabled="submitted" v-on:click="contactSupport">Send</button>&nbsp;
                    <img v-show="submitted" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    <div v-if="emsg" class="emsg">{{emsg}}</div>
                    <div v-if="error" class="alert alert-danger">{{error}}</div>
                </div>
                </div>
            </slot>
          </div>

          <div class="modal-footer">
            <p class="tiny">*We will respond via email within 2 hours.</p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { accountService } from '../../_services';
export default {
    data () {
        return {
            message: "",
            error: "",
            emsg: "",
            submitted: false
        }
    },
    methods: {
        contactSupport: function () {
        const subj = "Beta Feedback " + this.$route.path
        this.submitted = true ;

        if (this.message === "") {
            this.emsg = "" ;
            this.error = "Please enter a message" ;
            this.submitted = false ;
            return ;
        } 

        accountService.contactSupport(subj, this.message).then(
            data => {
            if (data.status == 'failed') {
                this.submitted = false ;
                this.error = 'failed to send message' ;
            } else {
                this.error = ""
                this.message = ""
                this.submitted = false ;
                this.emsg = "Feedback sent, thank you!"
            }
        },
        error => {
            this.error = error
            this.loading = false
        });
        },        
    }
}
</script>
