<template>
    <div class="content">
      <div class="middled">
        <div>{{ account.account_name }}</div>
        <div class="breadcrumbs">
            <router-link :to="{ name: 'dashboards'}">Dashboard</router-link> :: Todos
        </div>
        <div>
          <div class="row">
            <div class="column">
              <div style="display: flex;">
              <input style="flex-grow:2" type="text" v-model="new_todo_description" placeholder="Enter a task" v-on:keyup.enter="maketodo">
              <button v-on:click="maketodo" style="flex-grow:1;margin:4px;min-width:100px;">Save</button>
            </div>
            <div v-if="error">{{ error }}</div>
  
              <div style="display: flex ; flex-direction: row ; flex-wrap: nowrap ; justify-content: space-between ; cursor:pointer;  margin:2px 0 0 0;padding:8px;background:#ccc;">
                <div style="flex: 1;">
                  Urgent?
                </div>
                <div style="flex: 3;">
                  To do
                </div>
                <div style="flex: 1; color:#676767;">
                  Due by
                </div>
              </div>

              <draggable v-model="todos" group="todo" @start="drag=true" @end="drag=false">
                <div style="display: flex ; flex-direction: row ; flex-wrap: nowrap ; justify-content: space-between ; cursor:pointer;  margin:2px 0 0 0;padding:8px;background:#eee;" v-on:click="gotoTodo(todo.todo_id)" v-for="todo in todos" :key="todo.todo_id">
                  <div style="flex: 1;">
                    <img v-if="todo.urgent" style="width:20px;height:20px;cursor:pointer;" :src="require('@/assets/ic_star_on.png')" alt="star on" />
                    <img v-else style="width:20px;height:20px;cursor:pointer;" :src="require('@/assets/ic_star_off.png')" alt="star off" />
                  </div>
                  <div style="flex: 3;">
                    {{ todo.todo_description }}
                  </div>
                  <div style="flex: 1; color:#676767;">
                    <span>{{ todo.due_by | moment("MMM DD, YYYY") }}</span>
                  </div>
                </div>
              </draggable>
    
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { isLoggedIn } from "../utils/auth";
  import { router } from '../_helpers';
  import draggable from 'vuedraggable';
  import { accountService, todoService, userService } from '../_services';
  
  export default {
    title: 'Todos | Foclarity',
    name: "Todos",
    mounted: function() {
      if (isLoggedIn()) {
        // this.$router.push("dashboards");
      }
    },
    data () {
        return {
          name: '',
          account: "",
          todos: [],
          loading: true,
          returnUrl: '',
          error: '',
          lastlogin: '',
          new_parent_id: '',
          new_urgent: '',
          new_todo_description: '',
          new_important: '',
          new_estimated_hours: '',
          new_define_done: '',
          new_metrics: '',
          new_deliverables: '',
          new_due_by: '',
          new_done: ''
        }
    },
    methods: {
      premiumFeature: (msg) => {
        alert(msg + " is a Premium Feature. Contact us to upgrade.")
      },
      isLoggedIn() {
        return isLoggedIn();
      },
      activeAccount() {
      this.account = accountService.getActiveAccount() ;
      this.user = userService.getActiveUser() ;
    },
      getTodos: function () {
        this.loading = true ;
        todoService.readAccountTodos().then(
        data => {
          //console.log(data)
          this.todos = data.todos ;
          this.loading = false;
        }
        )
        .catch(error => {
            //console.debug(error)
            this.error = error ;
            this.loading = false ;
        });      
      },
      maketodo: function() {
        var parent_id = this.new_parent_id
        var urgent = this.new_urgent
        var todo_description = this.new_todo_description
        var important = this.new_important
        var estimated_hours = this.new_estimated_hours
        var define_done = this.new_define_done
        var metrics = this.new_metrics
        var deliverables = this.new_deliverables
        var due_by = this.new_due_by
        var done = this.new_done
        console.log(todo_description)

        todoService.createTodo(parent_id, urgent, todo_description, important, estimated_hours, define_done, metrics, deliverables, due_by, done).then(
        data => {
          // console.log(data)
          this.todos.push(data.todo)
          this.new_todo_description = ""
        }
        )
        .catch(error => {
            //console.debug(error)
            this.error = error ;
            this.loading = false ;
        });      
      },
      gotoTodo: function (tid) {
        router.push({name: "todo", params: {todo_id: tid} })
      }    
    },
    components: {
      draggable
    },
    created () {
      this.getTodos();
      this.activeAccount() ;
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .welcomeback {
    padding: 40px;
    text-align: center;
    border: 1px solid #ccc;
  }
  
  .heroblue {
    background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
    height: 320px;
    margin: -20px -20px 20px -20px;
    border-radius: 4px 4px 0 0;
    @media only screen and (max-width: 440px) {
      border-radius: 0px 0px 0 0;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    & h1 {
      text-align: center;
      color: #fff;
      font-size: 45px;
      max-width: 600px;
      @media (max-width: 415px) {
        font-size: 36px;
        padding: 20px;
      }
    }
    & a {
      text-decoration: none;
      color: #fff;
      & .signupbox {
        text-align: center;
        padding: 8px;
        font-size: 24px;
        font-weight: bold;
        background: #ff7f24;
        width: 300px;
        border-radius: 10px;
        border: 1px solid #fff;
        margin: auto;
      }
    }
  }
  
  </style>
  
  