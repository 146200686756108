<template>
  <div class="content bg8">
    <div class="middled">
      <div class="breadcrumbs">
          <router-link :to="{ name: 'clients'}">Clients</router-link>
          >
          <router-link :to="{ name: 'client', params: { client_id: this.$route.params.client_id }}">{{ client.client_company_name }}</router-link>
      </div>
        <div class="row" style="justify-content:flex-end;">
            <div class="column" style="flex-grow:5;">
                <h1>
                    INVOICE
                    <span class="inv-pill" @click="markaspaid" v-if="invoice.invoice_paid == true">PAID</span>
                    <span class="inv-pill" @click="markaspaid" v-if="invoice.invoice_paid == false">DUE</span>
                </h1>
            </div>
        </div>

        <div class="row" v-if="invoice != ''">
            <div class="column">
                <div class="row">
                    <div class="column" style="min-width:170px;flex-grow:1;">Number:</div>
                    <div class="column" style="min-width:170px;font-weight:bold;flex-grow:3">
                        <input v-show="edit_billing === true" type="number" v-model.number="invoice.invoice_number" name="invoice_number" placeholder="Invoice Number" :disabled="invoice.invoice_sent == true" />
                        <span v-show="!edit_billing" @click="edit_billing = !edit_billing" style="cursor:pointer;">INV-{{ invoice.invoice_number }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="column" style="min-width:170px;flex-grow:1">Date:</div>
                    <div class="column" style="min-width:170px;font-weight:bold;flex-grow:3">
                        <span @click="edit_billing = !edit_billing" style="cursor:pointer;">{{ invoice.invoice_date | moment("DD MMM, YYYY") }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="column" style="min-width:170px;flex-grow:1">Due By:</div>
                    <div class="column" style="min-width:170px;font-weight:bold;flex-grow:3">
                        <span @click="edit_billing = !edit_billing" style="cursor:pointer;">{{ invoice.invoice_due_date | moment("DD MMM, YYYY") }}</span>
                    </div>
                </div>
            </div>
            <div class="column" v-if="account.logo_url">
                <div style="text-align:center;vertical-align:middle;">
                    <router-link :to="{name: 'account', params: {account_id: account.account_id}, query: {r: this.$route.fullPath }}">
                        <img style="max-width:100px;" :src="'https://corl8-files.s3.amazonaws.com/' + account.logo_url" alt="logo">
                    </router-link>                        
                </div>
            </div>
            <div class="column" v-else>
                <div style="text-align:center;vertical-align:middle;">
                    <router-link class="edlink" :to="{name: 'account', params: {account_id: account.account_id}, query: {r: this.$route.fullPath }}">Upload Logo</router-link>                        
                </div>
            </div>
        </div>


      <div>        
        <div class="edit_invoice">
            <div class="row">
              <div class="column billing_group">
                <h2>Bill To:</h2>
                <input v-show="edit_billing === true" type="text" v-model="invoice.billing_company_name" name="billing_company_name" placeholder="Company" :disabled="invoice.invoice_sent == true" />
                    <p style="font-weight:bold" v-show="edit_billing === false" >{{ invoice.billing_company_name }}</p>
                <input v-show="edit_billing === true" type="text" v-model="invoice.billing_contact_name" name="billing_contact_name" placeholder="Contact Person" :disabled="invoice.invoice_sent == true" />
                    <p style="font-weight:bold" v-show="edit_billing === false" >{{ invoice.billing_contact_name }}</p>
                <input v-show="edit_billing === true" type="text" v-model="invoice.billing_address" name="billing_address" placeholder="Address" :disabled="invoice.invoice_sent == true" />
                    <p v-show="edit_billing === false" >{{ invoice.billing_address }}</p>
                    <p v-show="edit_billing === false" v-if="invoice.billing_city">{{ invoice.billing_city }}, {{ invoice.billing_state }} {{ invoice.billing_zip }}</p>
                <input v-show="edit_billing === true" type="text" v-model="invoice.billing_city" name="billing_city" placeholder="City" :disabled="invoice.invoice_sent == true" />
                <input v-show="edit_billing === true" type="text" v-model="invoice.billing_state" name="billing_state" placeholder="State" maxlength="2" :disabled="invoice.invoice_sent == true" />
                <input v-show="edit_billing === true" type="text" v-model="invoice.billing_zip" name="billing_zip" placeholder="Zip" :disabled="invoice.invoice_sent == true" />
                    <p v-show="edit_billing === false">{{ invoice.billing_email }}</p>
                <input v-show="edit_billing === true" type="email" v-model="invoice.billing_email" name="billing_email" placeholder="Email Address" :disabled="invoice.invoice_sent == true" />
                    <p v-show="edit_billing === false" style="margin-top:20px;">
                        <a href="#" class="edlink" @click.prevent="edit_billing = !edit_billing">Edit 'Bill To' info</a>
                    </p>
                    <div class="column" v-show="edit_billing === true" >
                        <div>
                            <button style="margin-left:10px;" :disabled="loading" v-show="invoice.invoice_sent == false" @click.prevent="updateInvoice">Save Changes</button>
                            <button class="btn" style="background:#000;margin-left:10px;" :disabled="loading" @click.prevent="cancelEditBilling">Cancel</button>
                        </div>
                    </div>
              </div>
              <div class="column invoice_details">
                <div class="account_contact_info">
                    <h2>
                        Pay To: 
                    </h2>
                    <p style="font-weight:bold;">{{ account.account_name }}</p>
                    <p v-if="account.account_contact_name != ''" style="font-weight:bold">{{ account.account_contact_name }}</p>
                    <p>{{ account.account_address || 'Address' }}</p>
                    <p>{{ account.account_city || 'City' }}, {{ account.account_state || 'State' }} {{ account.account_zip || 'Zip' }}</p>
                    <p v-show="account.account_phone">PH: {{ account.account_phone }}</p>
                    <p v-show="account.account_fax">FAX: {{ account.account_phone }}</p>
                    <p>{{ account.account_email }}</p>
                    <p v-show="account.account_tid">TID: {{ account.account_tid }}</p>
                    <p v-show="account.account_license">LIC: {{ account.account_license }}</p>
                    <p style="margin-top:20px;">
                        <router-link class="edlink" :to="{name: 'account', params: {account_id: account.account_id}, query: {r: this.$route.fullPath }}">Edit 'Pay To' info</router-link>                        
                    </p>
                </div>
              </div>
            </div>

            <div class="row" v-if="invoice.invoice_id > 0">
              <div class="column invoice_items">
                <h2 style="padding:12px;border:1px solid #ccc;background:#eee;">Invoice Items</h2>
                <InvoiceItems 
                    v-bind:iid="invoice.invoice_id"
                    v-bind:cid="invoice.client_id"
                    :key="invoice.invoice_id"
                />
              </div>
            </div>

            <div class="row">
              <div class="column invoice_notes">
                <h2>Notes for Customer:</h2>
                <textarea v-model="edit_invoice_note" style="resize:none;" />
              </div>
            </div>

            <div class="row">
              <div class="column invoice_actions">
                <transition name="fade">
                    <div v-if="msg" class="message">
                        <div @click="msg = ''" style="cursor:pointer;float:right;position:relative;margin-left:20px;">
                            <img :src="require('@/assets/ic_delete.png')" alt="Close" style="width:30px;height:30px;" />
                        </div>
                        {{ msg }}
                    </div>
                </transition>
                <transition name="fade">
                    <div v-if="error" class="message error">
                        <div @click="error = ''" style="cursor:pointer;float:right;position:relative;margin-left:20px;">
                            <img :src="require('@/assets/ic_delete.png')" alt="Close" style="width:30px;height:30px;" />
                        </div>
                        {{ error }}
                    </div>
                </transition>
                <div class="row">
                    <div class="column"><button :disabled="loading" v-show="invoice.invoice_sent == false" @click.prevent="updateInvoice">Save Changes</button></div>
                    <div class="column"><button style="background:#000;color:#fff;" @click="cancelEdit">Exit</button></div>
                    <div class="column"><button :disabled="loading || edit_billing || (edit_invoice_note != invoice.invoice_note)" class="btn-add" @click.prevent="printInvoice">Print Invoice</button></div>
                    <div class="column"><button :disabled="loading || edit_billing || (edit_invoice_note != invoice.invoice_note)" class="btn-add" @click.prevent="sendInvoice">Email Invoice</button></div>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="column status">
                    <div v-show="loading">loading...</div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isLoggedIn } from "../utils/auth";
import { router } from '../_helpers';
import { accountService, clientService, invoiceService } from '../_services';
import InvoiceItems from "@/components/includes/InvoiceItems";
import moment from 'moment';



export default {
  title: "Invoice",
  name: "Edit-Invoice",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  data () {
    return {
        account: '',
        client: '',
        invoice: '',
        invoice_items: [],
        edit_billing: false ,
        edit_invoice_note: '',
        submitted: false,
        loading: true,
        msg: '',
        error: ''
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
    fixDate(event){
        return moment(event).format();
    },    
    getActiveAccount: function () {
        this.account = accountService.getActiveAccount()
    },
    getInvoice: function () {
        this.loading = true ;
        invoiceService.readInvoice(this.$route.params.client_id, this.$route.params.invoice_id)
        .then(
            data => {
                this.invoice = data.invoice
                this.edit_invoice_note = data.invoice.invoice_note
                this.loading = false
            },
            error => {
                this.error = error
                this.loading = false
            }
        );
    },
    construction: (d) => {
      alert(d)
    },
    printInvoice: function () {
        this.updateInvoice() ;
        window.print() ;
    },
    cancelEditBilling: function () {
        if(confirm("Close without saving?")){
            this.edit_billing = false ;
            this.getInvoice() ;
        }
    },
    cancelEdit: function () {
        router.push("/clients/" + this.invoice.client_id)
    },
    markaspaid: function () {
        var msg = "Mark Invoice as PAID?"
        if (this.invoice.invoice_paid == true) {
            msg = "Mark Invoice as DUE?"
        }
        if(confirm(msg)){
            invoiceService.markAsPaid(this.invoice.client_id,this.invoice.invoice_id).
            then(
                data => {
                    if (data.invoice) {
                        this.invoice = data.invoice
                        this.msg = data.message
                        this.edit_billing = false
                        window.setInterval(() => {
                            this.msg = '';
                        }, 6000)                    
                    } else {
                        this.error = data
                    }
                },
                error => {
                    this.error = error
                }
            )
        }
    },
    sendInvoice: function () {
        if (this.invoice.billing_email == "") {
            alert("Please add an email address for your client in the Bill To section.")
            return
        }
        if (this.account.account_email == "") {
            alert("Please add your email address in the Pay To section.")
            return
        }
        // make sure they didn't just enter a note and forgot to save
        if (this.edit_invoice_note != this.invoice.invoice_note) {
            alert("You have unsaved changes. Click Save Draft then try again.")
            return
        }
        // then confirm they really want to send this
        var msg = "Send Invoice to Billing Email Address?"
        if(confirm(msg)){
            invoiceService.sendInvoice(this.invoice.client_id,this.invoice.invoice_id).
            then(
                data => {
                    if (data.status == "success") {
                        this.msg = data.message
                        this.edit_billing = false
                        window.setInterval(() => {
                            this.msg = '';
                        }, 3000)                    
                    } else {
                        // console.log(data)
                        this.error = data
                        this.edit_billing = false
                        window.setInterval(() => {
                            this.error = '';
                        }, 3000)                    
                    }
                },
                error => {
                    this.error = error
                }
            )
        }
    },
    updateInvoice: function () {
        invoiceService.updateInvoice(
            this.invoice.client_id,
            this.invoice.invoice_id,
            this.invoice.invoice_number,
            this.invoice.invoice_date,
            this.invoice.invoice_due_date,
            this.edit_invoice_note,
            this.invoice.billing_company_name,
            this.invoice.billing_contact_name,
            this.invoice.billing_address,
            this.invoice.billing_city,
            this.invoice.billing_state,
            this.invoice.billing_zip,
            this.invoice.billing_email
        ).then(
            data => {
                if (data.invoice) {
                    this.invoice = data.invoice
                    this.msg = data.message
                    this.edit_billing = false
                    window.setInterval(() => {
                        this.msg = '';
                    }, 3000)                    
                } else {
                    this.error = data
                }
            },
            error => {
                this.error = error
            }
        )
    }
  },
  components: {
      InvoiceItems
  },
  created () {
      this.getActiveAccount();
      this.getInvoice();
      clientService.readClient(this.$route.params.client_id).then(
        data => {
          this.client = data.client
        }
      ) ;
  }
};
</script>

<style lang="scss" scoped>
@media print {
//   .billing_group p, .account_contact_info p {
//       font-size: 14px;
//   }
  .edlink {
      display: none ;
  }
  .additembox {
      display: none ;
  }
  .breadcrumbs {
      display: none ;
  }
  .invoice_actions {
      display: none ;
  }
  .invoice_notes h2 {
      display: none ;
  }
  .invoice_notes textarea {
      border: 0 ;
      font-size: 16px ;
  }
}

.inv-pill {
  font-family: "Lato", "Avenir", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  padding: 2px 10px;
  margin: 0px;
  border:1px solid #ccc;
  border-radius: 20px;
  vertical-align: middle;
  cursor: pointer;
}

.edit_invoice {
    .billing_group {
        padding:12px ;
        // border:1px solid #ccc;
        @media (max-width: 415px) {
            padding: 8px;
        }
        & p {
            padding: 0 ;
            margin: 0 ;
            line-height: 1.5rem;
        }
        & input {
            margin:4px 0 ;
        }
        & .edit-link {
            margin-top:20px;
            text-align:left;
            @media (max-width: 415px) {
            text-align:left;
            }
        }

    }
    .invoice_details {
        min-width: 300px;
        font-size: .9rem;
        line-height: 1.25rem;
        // border:1px solid #ccc;
        padding-top:0px;
        & .account_contact_info {
            min-width: 300px ;
            text-align: right ;
            padding: 12px ;
            @media (max-width: 600px) {
                text-align: left ;
                padding: 3px ;
            }
            & p {
                padding: 0 ;
                margin: 0 ;
                line-height: 1.5rem;
            }
            & .column {
                padding: 0;
                min-width: 150px;
            }
            & .edit-link {
                margin-top:20px;
                text-align:right;
                @media (max-width: 415px) {
                    text-align:left;
                }
            }
        }
    }
    .invoice_actions {
        text-align: center;
        & button {
            margin:auto ;
            min-width:200px;
        }
        & .message {
            position:fixed;
            top: 10px;
            left: 10px;
            right: 10px;
            z-index: 1000;
            font-weight: bold ;
            background: #D5F1FC;
            padding:20px;
            border: 1px solid #0099FF;
            &.error {
                background: #EEFBCA;
                border: 1px solid #eead0e; 
            }
        }
    }
    .column.status {
        text-align: center;
    }
}
</style>

