import { authHeader, router } from '../_helpers';

export const invoiceService = {
    createInvoice,
    readInvoice,
    updateInvoice,
    deleteInvoice,
    markAsPaid,
    sendInvoice,
    readClientInvoices,
    readAccountInvoices
};

function createInvoice(client_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({})
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/clients/" + client_id 
        + "/invoices" 

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            // replace invoice in local storage
            // localStorage.removeItem('invoice');
            // localStorage.setItem('invoice', JSON.stringify(data.invoice));
            router.push('/clients/' + client_id + '/invoices/' + data.invoice.invoice_id)
        })
        .catch(error => {
            //console.log(error)
            // localStorage.removeItem('invoice')
            router.push('/dashboards?error=' + error)
        });
}

function readInvoice(client_id, invoice_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" 
        + uid 
        + "/accounts/" 
        + aid 
        + "/clients/" 
        + client_id
        + "/invoices/" 
        + invoice_id

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            // replace invoice in local storage
            // localStorage.removeItem('invoice');
            // localStorage.setItem('invoice', JSON.stringify(data.invoice));
            return data;
        })
        .catch(error => {
            //console.log(error)
            // localStorage.removeItem('invoice')
            router.push('/dashboards?error=' + error)
        });
}

function updateInvoice( client_id,
                        invoice_id, 
                        invoice_number,
                        invoice_date,
                        invoice_due_date,
                        invoice_note,
                        billing_company_name,
                        billing_contact_name,
                        billing_address,
                        billing_city,
                        billing_state,
                        billing_zip,
                        billing_email ) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ invoice_number,
                        invoice_date,
                        invoice_due_date,
                        invoice_note,
                        billing_company_name,
                        billing_contact_name,
                        billing_address,
                        billing_city,
                        billing_state,
                        billing_zip,
                        billing_email })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid 
    + "/accounts/" + aid 
    + "/clients/" + client_id 
    + "/invoices/" + invoice_id 

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            return data;
        })
        .catch(error => {
            //console.log(error)
            return error ;
        });
}

function deleteInvoice(client_id, invoice_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" 
        + uid 
        + "/accounts/" 
        + aid 
        + "/clients/" 
        + client_id
        + "/invoices/" 
        + invoice_id

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            // store invoice details in local storage 
            // localStorage.removeItem('invoice');
            return data;
        })
        .catch(error => {
            //console.log(error)
            router.push('/clients?error=' + error)
        });
}

function markAsPaid(client_id, invoice_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" 
        + uid 
        + "/accounts/" 
        + aid 
        + "/clients/" 
        + client_id
        + "/invoices/" 
        + invoice_id
        + "/paid"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            // store invoice details in local storage 
            // localStorage.removeItem('invoice');
            return data;
        })
        .catch(error => {
            //console.log(error)
            router.push('/clients?error=' + error)
        });
}

function sendInvoice(client_id, invoice_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" 
        + uid 
        + "/accounts/" 
        + aid 
        + "/clients/" 
        + client_id
        + "/invoices/" 
        + invoice_id
        + "/send"

        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            return data
        })
        .catch(error => {
            //console.log(error)
            return error
        });
}

function readClientInvoices(client_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" 
        + uid 
        + "/accounts/" 
        + aid 
        + "/clients/" 
        + client_id
        + "/invoices"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            return data;
        })
        .catch(error => {
            console.log(error)
            router.push('/clients?error=' + error)
        });
}

function readAccountInvoices() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" 
        + uid 
        + "/accounts/" 
        + aid 
        + "/invoices"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            return data;
        })
        .catch(error => {
            console.log(error)
            //router.push('/dashboards?error=' + error)
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

