<template>
  <div class="content bg8">
    <div class="middled">
      <div class="verify" style="margin:auto;">
        <h1 style="padding: 20px 0;">Forgot Password</h1>
        <form @submit.prevent="handleSubmit">
            <div class="form-grp">
                <label for="email">Enter your email address:</label>
                <input type="email" v-model="email" name="email" class="form-control" :class="{ 'is-invalid': submitted && !email }" />
                <div v-show="submitted && !email" class="invalid-feedback">Email is required</div>
            </div>
            <div class="form-grp">
                <button class="btn btn-primary wide" :disabled="loading">Reset Password</button>
                <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
            <div v-if="error" class="alert alert-danger">{{error}}</div>
            <div v-show="getverifiedlink">
                <p>
                    Can't find your verification id?<br />
                    <router-link to="/resend-verification-id">Resend Verification ID</router-link>
                </p>
            </div>
            <div>
                <p>
                    Don't have an account?
                    <router-link to="/register">Register</router-link>
                </p>
            </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { userService } from '../_services';

export default {
    data () {
        return {
            email: '',
            submitted: false,
            getverifiedlink: false,
            loading: false,
            returnUrl: '',
            error: ''
        }
    },
    created () {
        // reset login status
        userService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.$route.query.returnUrl || '/login';
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        handleSubmit (e) {
            this.submitted = true;
            const { email } = this;

            // stop here if form is invalid
            if (!(email)) {
                return;
            }

            this.loading = true;
            userService.sendforgotpassword(email)
                .then(
                    // eslint-disable-next-line no-unused-vars
                    message => {
                      this.error = 'Check your email for a link to reset your password.' ;
                      this.loading = false ;
                      this.submitted = false ;
                      this.email = '' ;
                    },
                    error => {
                      this.error = error ;
                      this.loading = false ;
                      this.submitted = false ;
                      this.email = '' ;
                      if (this.error == "account not yet verified") {
                        this.getverifiedlink = true ;
                      }
                    }
                );
        }
    }
};
</script>

<style lang="scss" scoped>
.verify {
  max-width: 400px;
  padding: 20px;
  & input {
    background: #eee ;
  }
}
</style>
