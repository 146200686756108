import Vue from 'vue';

import { router } from './_helpers';
import App from './app/App';
// import VueGtag from "vue-gtag";
import titleMixin from './mixins/titleMixin'
import VueApexCharts from 'vue-apexcharts'
import linkify from 'vue-linkify'

Vue.directive('linkified', linkify)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

/** Vue Filters Start */
Vue.filter('truncate', function (text, length, suffix) {
  if (text.length > length) {
      return text.substring(0, length) + suffix;
  } else {
      return text;
  }
});

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
  });
  return formatter.format(value);
});
/** Vue Filters End */

Vue.mixin(titleMixin);
Vue.use(require('vue-moment'));

/*
Vue.use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_GTAG }
    },
    router
  );
*/
  
new Vue({
    el: '#app',
    router,
    render: h => h(App)
});
