import { authHeader } from '../_helpers';

export const measureOwnerService = {
    addMeasureOwner,
};

function addMeasureOwner(scorecard_id, category_id, measure_id, owner_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ measure_id, owner_id })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" 
        + uid 
        + "/accounts/" 
        + aid 
        + "/scorecards/"
        + scorecard_id
        + "/categories/"
        + category_id
        + "/measures/"
        + measure_id
        + "/owners/"
        + owner_id

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            // measure creation is successful if there's a measure in the response
            if (data.measure_owner) {
                return data.measure_owner;
            }
            return data
        })
        .catch(error => {
            return error
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

