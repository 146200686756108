<template>
  <div class="content bg8">
    <div class="middled">
        <div class="verify" style="margin: auto;">
            <h1 style="margin:20px 0;">Reset Password</h1>
        <form @submit.prevent="handleSubmit">
            <div class="form-grp">
                <label for="token">Password Reset Token</label>
                <input type="text" v-model="token" name="token" class="form-control" />
                <div v-show="submitted && !token" class="invalid-feedback">Reset Token is required</div>
            </div>
            <div class="form-grp">
                <label for="email">Confirm Email</label>
                <input type="email" v-model="email" name="email" class="form-control" :class="{ 'is-invalid': submitted && !email }" />
                <div v-show="submitted && !email" class="invalid-feedback">Email is required</div>
            </div>
            <div class="form-grp">
                <label htmlFor="password">New Password</label>
                <input type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" />
                <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
            </div>
            <div class="form-grp">
                <button class="btn btn-primary wide" :disabled="loading">Save</button>
                <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
            <div v-if="error" class="alert alert-danger">{{error}}</div>
            <div>
                <p>
                    Don't have a Reset Token?<br />
                    Check your inbox and junk mail. If you still can't find it get another one
                    <router-link to="/forgot-password">here</router-link>
                </p>
            </div>
        </form>
        </div>
    </div>
  </div>
</template>

<script>
import { router } from '../_helpers';
import { userService } from '../_services';

export default {
  title: 'Reset Password | Foclarity',
    data () {
        return {
            token: this.$route.query.q,
            email: '',
            password: '',
            submitted: false,
            loading: false,
            returnUrl: '',
            error: ''
        }
    },
    created () {
        // reset login status
        userService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = '/login';
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        handleSubmit (e) {
            this.submitted = true;
            const { token, email, password } = this;

            // stop here if form is invalid
            if (!(token) || !(email) || !(password)) {
                return;
            }

            this.loading = true;
            userService.resetpassword(token, email, password)
                .then(
                    // eslint-disable-next-line no-unused-vars
                    message => router.push(this.returnUrl),
                    error => {
                        if (error == "sql: no rows in result set") {
                            this.error = "Reset Token has expired"
                        } else {
                            this.error = error;
                        }
                        this.loading = false;
                    }
                );
        }
    }
};
</script>

<style lang="scss" scoped>
.verify {
  max-width: 400px;
  padding: 20px;
  & input {
    background: #eee ;
  }
}

label {
  display: block;
  margin-top: 10px;
  display: none;
}
</style>
