<template>
  <div class="content bg8">
    <div class="middled">
      <h1>
        Project for {{ client.client_company_name }}
      </h1>
      <div>
        <div class="register">
          <form @submit.prevent="handleSubmit">
              <div class="form-grp">
                <label for="project_name">Project Name</label>
                <input type="text" v-model="project_name" name="project_name" maxlength="144" />
              </div>
              <div class="form-grp">
                  <label for="project_description">Project Description</label>
                  <textarea v-model="project_description" name="project_description" />
              </div>
              <div class="form-group">
                  <button class="btn btn-primary" :disabled="loading">Save</button> | <a href="/dashboards">Cancel</a>
                  <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              </div>
              <div v-if="error" class="alert alert-danger">{{error}}</div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";
import { clientService, projectService } from '../_services';

export default {
  title: 'New Board | Foclarity',
  name: "New-Project",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
    getclient: function () {
      clientService.readClient(this.$route.params.client_id).then(
        data => {
          this.client = data.client
        }
      )
    },
    // eslint-disable-next-line no-unused-vars
    handleSubmit (e) {
        this.submitted = true;
        const { project_name, project_description } = this;

        this.loading = true;
        //console.debug("starting")
        projectService.createProject(this.client.client_id, project_name, project_description)
            .then(
                data => {
                    this.error = 'Project ' + data.project_name + ' created and saved. Returning to Projects....';
                    this.loading = false
                    // console.log(data)
                    window.location.href= `/clients/` + this.client.client_id
                },
                error => {
                    this.error = error;
                    this.loading = false;
                }
            );
    }    
  },
  components: {},
  data () {
      return {
        client: '',
        project_name: '',
        project_description: '',
        submitted: false,
        loading: false,
        error: ''
      }
  },
  created () {
      this.getclient()
  }
};
</script>

<style lang="scss" scoped>
</style>

