<template>
  <div class="content bg8">
    <div class="middled">
      <h1>
        Give your category a name.
      </h1>
      <p>
        A category is like a folder that holds tiles you'll be working on.
      </p>
      <div>
        <div class="register">
          <form @submit.prevent="handleSubmit">
              <div class="form-grp">
                <label for="category_name">Category Name</label>
                <input type="text" v-model="category_name" name="category_name" maxlength="144" />
              </div>
              <div class="form-grp">
                  <label for="category_description">Category Description</label>
                  <textarea v-model="category_description" name="category_description" placeholder="Optional" />
              </div>
              <div class="form-group">
                  <button class="btn btn-primary" :disabled="loading">Save</button> | <router-link :to="{ name: 'scorecard', params: { scorecard_id: $route.params.scorecard_id }}">Cancel</router-link>
                  <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              </div>
              <div v-if="error" class="alert">{{error}}</div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";
import { scorecardService } from '../_services';

export default {
  title: 'New Category | Foclarity',
  name: "New-Category",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
    getScorecard: function () {
      this.scid = this.$route.params.scorecard_id
      scorecardService.readScorecard(this.scid).
      then(
        data => {
          //console.log(data.scorecard.scorecard_id)
          this.scorecard = data.scorecard
          if (data.categories) {
            this.categories = data.categories
            this.categories.reverse();
          }
        }).
        catch(error => {
            this.error = error
            this.loading = false
        })
    },
    // eslint-disable-next-line no-unused-vars
    handleSubmit (e) {
        this.submitted = true;
        const { category_name, category_description } = this;

        this.loading = true;
        //console.debug("starting")
        scorecardService.createCategory(category_name, category_description, this.scorecard.scorecard_id )
            .then(
                data => {
                    this.error = 'Category ' + data.category_name + ' created and saved. Returning to Board....';
                    this.loading = false
                    // console.log(data)
                    window.location.href= `/scorecards/` + this.scorecard.scorecard_id
                },
                error => {
                    this.error = error;
                    this.loading = false;
                }
            );
    }    
  },
  components: {},
  data () {
      return {
        scorecard: '',
        category_name: '',
        category_description: '',
        submitted: false,
        loading: false,
        error: ''
      }
  },
  created () {
    this.getScorecard() ;
  }
};
</script>

<style lang="scss" scoped>
</style>

