import { authHeader, router } from '../_helpers';

export const todoService = {
    readAccountTodos,
    createTodo,
    readTodo,
    readTodosByParent,
//    updateTodo,
//    deleteTodo
};

function readAccountTodos() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/todos"
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // localStorage.setItem('snapshots', JSON.stringify(data.snapshots));
            return data;
        });
}

function createTodo(parent_id, urgent, todo_description, important, estimated_hours, define_done, metrics, deliverables, due_by, done) {
    /*
        "parent_id": if empty default to 0,
        "creator_id": // user,
        "account_id": ,
        "todo_description": // can't be empty,
        "urgent": // default to false,
        "important": false,
        "estimated_hours": 0,
        "define_done": "",
        "metrics": "",
        "deliverables": "",
        "due_by": "0000-12-31T16:07:02-07:52",
        "done": false,
    */
        if (parent_id == "" || parent_id < 1) {
            parent_id = 0
        }
        if (todo_description == "") {
            return Promise.reject("Todo must include a description");
        }
        if (urgent == "") {
            urgent = false
        }
        if (important == "") {
            important = false
        }
        if (estimated_hours == "") {estimated_hours = 0}
        due_by = new Date().toISOString()
        done = false
    
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({ 
                parent_id, urgent, todo_description, important, estimated_hours, define_done, metrics, deliverables, due_by, done
            })
        };
    
        if (localStorage.getItem('account') === null) {
            router.push("/accounts")
            return Promise.reject("No account selected");
        }
    
        let uid = JSON.parse(localStorage.getItem('user')).user_id
        let aid = JSON.parse(localStorage.getItem('account')).account_id
    
        var endpoint = process.env.VUE_APP_API_URL 
            + "/api/v1/users/" + uid 
            + "/accounts/" + aid 
            + "/todos"
        
            return fetch(endpoint, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
    
}

function readTodo(tid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/todos/" + tid
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // localStorage.setItem('snapshots', JSON.stringify(data.snapshots));
            return data;
        });
}


function readTodosByParent(tid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/todos/" + tid
        + "/children"
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // localStorage.setItem('snapshots', JSON.stringify(data.snapshots));
            return data;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
