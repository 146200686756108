<template>
  <div class="content">
    <div class="middled">

      <div class="bodyblock" v-show="loading" style="text-align:center;">Loading...</div>
      <div class="breadcrumbs">
          <router-link :to="{ name: 'clients'}">Clients</router-link>
      </div>

      <div class="dashboard-content" v-show="!loading"  v-if="client">
        <div class="row">

          <div class="column">
            <h1>
              {{ client.client_company_name }}
            </h1>
            <div>
              {{ client.client_description }}
              <div style="font-size:.8rem;margin:8px 0;">
                Created: {{ client.created_at | moment("MMM DD, YYYY") }} | 
                Projects: {{ projects.length }} |
                <a href="#" @click.prevent="assignClient(client.client_company_name, client.client_description, client.client_id)">Edit</a>
              </div>
            </div>
            <div>
              <div class="cl-cat-heading">
                <div class="row">
                  <div class="column ched">
                    Projects
                  </div>
                  <div class="column ched" style="text-align:right;">
                    <router-link class="addnew" :to="{ name: 'new-project'}">
                      New <span class="addnewtext">Project</span>
                    </router-link>
                  </div>
                </div>
              </div>

              <div v-for="project in projects" :key="project.project_id" >
                <div style="display: flex; flex-direction:column; justify-content:space-between; cursor:pointer; margin:2px 0 0 0;padding:8px;background:#eee;" v-on:click="gotoProject(client.client_id, project.project_id)">
                  <div style="flex:0 1 auto;font-weight:bold;text-transform:capitalize;">
                    {{project.project_name}}
                  </div>
                  <div style="flex:5 1 auto;font-size:1rem;">
                    {{project.project_description | truncate(250, '...') }}
                  </div>
                </div>
              </div>

              <div v-show="projects.length < 1" class="row spacer" >
                  <div class="catname" style="padding:10px 0 ;text-align:center;width:100%;">
                    No Projects
                  </div>
              </div>
            </div>

            <div style="margin-top:20px;">
              <div class="cl-cat-heading">
                <div class="row">
                  <div class="column ched">
                    Invoices
                  </div>
                  <div class="column ched" style="text-align:right;">
                    <a class="addnew" @click.prevent="createInvoice()">
                      New <span class="addnewtext">Invoice</span>&nbsp;
                    </a>
                  </div>
                </div>
              </div>

              <div style="display: flex ; flex-direction: row ; flex-wrap: nowrap ; justify-content: space-between ; cursor:pointer;  margin:2px 0 0 0;padding:8px;background:#eee;" v-on:click="gotoInvoice(invoice.invoice_id)" v-for="invoice in invoices" :key="invoice.invoice_id">
                <div style="flex: 1;font-weight:bold;font-size:1rem;">
                  #{{invoice.invoice_number}}
                </div>
                <div style="flex: 1; color:#676767;font-size:1rem;">
                  <span v-if="invoice.invoice_paid == false" >{{ invoice.invoice_due_date | moment("MMM DD, YYYY") }}</span>
                  <span v-else>PAID</span>
                </div>
                <div style="flex: 1;color:#676767;text-align:right;font-size:1rem;">
                  {{ invoice.invoice_total / 100 | toCurrency }}
                </div>
              </div>

              <div v-show="invoices.length < 1" class="row spacer" >
                <div class="catname" style="padding:10px 0 ;text-align:center;width:100%;">
                  No Invoices
                </div>
              </div>

            </div>

            <div style="margin-top:20px;">
              <div class="cl-cat-heading">
                <div class="row">
                  <div class="column ched">
                    Transactions
                  </div>
                  <div class="column ched" style="text-align:right;">
                    <a class="addnew" @click.prevent="construction('work in progress')">
                      New <span class="addnewtext">Transaction</span>&nbsp;
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div class="row spacer" >
                  <div class="catname" style="padding:10px 0 ;text-align:center;width:100%;">
                    No Transactions
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <!-- use the modal component, pass in the prop -->
      <modal v-if="showEditClient" @close="showEditClient = false">
        <template v-slot:header>
          <h3>Edit Client</h3>
        </template>
        <template v-slot:body>
          <div class="form-grp">
              <label for="client_company_name">Client Name</label>
              <input v-model="edit_client_company_name" style="width:100%;" />
          </div>
          <div class="form-grp">
              <label for="client_description">Client Description</label>
              <textarea v-model="edit_client_description" />
          </div>
          <div class="form-group">
              <button style="margin-right:10px;" class="btn btn-primary" @click="editClient">Save</button>
          </div>
        </template>
        <template v-slot:footer>
          <div v-if="edit_client_msg">{{edit_client_msg}}</div>
          <div v-else>Save your changes or click "Close" to cancel</div>
        </template>
      </modal>

    </div>
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";
import { userService, accountService, clientService, invoiceService } from "../_services"
import { router } from '../_helpers';
import modal from "@/components/includes/InfoModal"

export default {
  title: 'Client | Foclarity',
  name: "Client",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  data () {
      return {
        user: '',
        account: '',
        client: '',
        client_id: this.$route.params.client_id,
        showEditClient: false,
        edit_client_id: 0,
        edit_client_company_name: '',
        edit_client_description: '',
        edit_client_msg: "",
        loading: true,
        error: "",
        projects: [],
        invoices: []
      }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
    construction: (d) => {
      alert(d)
    },
    switchClient: function() {
      clientService.readClient(this.$route.params.client_id).then(
        data => {
          this.client = data.client ;
        }
      )
      .catch(error => {
        this.error = error
        this.loading = false ;
      })
    },
    assignClient: function (client_company_name, client_description, client_id) {
      this.edit_client_company_name = client_company_name
      this.edit_client_description = client_description
      this.edit_client_id = client_id
      this.showEditClient = true
    },
    editClient: function () {
      clientService.updateClient(this.edit_client_id, this.edit_client_company_name, this.edit_client_description).then(
        data => {
          if (data.status == "success") {
            this.client.client_company_name = data.client.client_company_name ;
            this.client.client_description = data.client.client_description ;
            this.edit_client_id = 0
            this.edit_client_company_name = ""
            this.edit_client_description = ""
            this.edit_client_msg = ""
            this.showEditClient = false ;
          } else {
            // console.log(data.error)
            alert("Ran into an issue trying to update this client. Please try again.")
          }
        }
      )
      .catch(error => {
        // console.log(error)
        this.error = error ;
        this.loading = false ;
      })
    },
    getProjects: function () {
      this.loading = true ;
      clientService.readClientProjects(this.$route.params.client_id).then(
      data => {
        this.projects = data.projects ;
        this.loading = false;
      }
      )
      .catch(error => {
          //console.debug(error)
          this.error = error ;
          this.loading = false ;
      });      
    },
    createInvoice: function () {
      invoiceService.createInvoice(this.$route.params.client_id)
    },
    getInvoices: function () {
      this.loading = true ;
      invoiceService.readClientInvoices(this.$route.params.client_id).then(
      data => {
        // console.log(data)
        this.invoices = data.invoices ;
        this.loading = false;
      }
      )
      .catch(error => {
          //console.debug(error)
          this.error = error ;
          this.loading = false ;
      });      
    },
    goto: (q) => {
      router.push(q)
    },
    gotoProject: (cid, pid) => {
      router.push({ name: 'project', params: { client_id: cid, project_id: pid } })
    },
    gotoInvoice: function (q) {
      router.push("/clients/" + this.client.client_id + "/invoices/" + q)
    }
  },
  components: {
    modal
  },
  created () {
    this.switchClient() ;
    this.account = accountService.getActiveAccount();
    userService.getMe().then(data => {
      this.user = data;
      this.getProjects();
      this.getInvoices();
      
      if (this.account == null) {
        router.push("/accounts")
        return
      }
      this.loading = false ;
    });
  },
  computed: {
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }  
};
</script>

<style lang="scss" scoped>
.cl-cat-heading {
  padding: 0;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom:4px solid #676767;
}

.column .ched {
  padding: 2px 0 ;
  min-width:50px;
}

.addnew {
  background: #fff url("~@/assets/ic_add.png") center right no-repeat;
  background-size: 28px;
  text-align: right;
  cursor: pointer;
  padding-right:34px;
  text-transform: capitalize;
  @media only screen and (max-width: 375px) {
    color: #fff;
    & .addnewtext {
      display: none;
    }
  }
}

.categorycard {
  cursor: pointer ;
}

.score {
  font-size: 4rem ;
  color: #000 ;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.mission {
  padding:0 10px 20px 20px;
  margin:0px 0px 0 0;
  border-radius: 0 0 4px 4px;
  border-top: 2px solid #000;
  color:#000;
  background: #fff url("~@/assets/parchment-bg2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

</style>

