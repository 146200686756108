<template>
    <div class="content bg8">
      <div class="middled">
        <div class="basehero" style="">
          <h1>
            Resources for Startup Founders and Business Leaders
          </h1>
          <h2 style="margin-top:50px;">
            Helpful books, websites, videos, and communities to help in your journey. 
          </h2>
          <div>
            <p>
                <ol>
                    <li>"The Lean Startup" by Eric Ries: This book introduces the concept of lean methodology, emphasizing rapid iteration, experimentation, and customer feedback to build successful startups. It offers practical advice on how to validate ideas, iterate quickly, and build a sustainable business. [Source: Ries, E. (2011). The Lean Startup: How Today's Entrepreneurs Use Continuous Innovation to Create Radically Successful Businesses.]</li>
                    <li>Y Combinator Startup Library: Y Combinator, a renowned startup accelerator, has compiled an extensive collection of resources for startup founders. It includes articles, essays, and videos covering various aspects of entrepreneurship, from idea validation to scaling a business. Visit their website for a wealth of valuable insights. [Source: Y Combinator Startup Library, https://www.ycombinator.com/library]</li>
                    <li>"Zero to One" by Peter Thiel: In this book, Thiel shares his insights on building transformative businesses. He highlights the importance of creating unique value and outlines strategies for building monopolies through innovation. It's a thought-provoking read for founders looking to create breakthrough companies. [Source: Thiel, P., & Masters, B. (2014). Zero to One: Notes on Startups, or How to Build the Future.]</li>
                    <li>Harvard Business Review (HBR): HBR is a reputable source for business insights and thought leadership. It covers a wide range of topics relevant to startups and leaders, including strategy, leadership, marketing, and innovation. Their articles, case studies, and research papers can provide valuable guidance. [Source: Harvard Business Review, https://hbr.org/]</li>
                    <li>"Sprint" by Jake Knapp, John Zeratsky, and Braden Kowitz: This book presents the concept of a five-day design sprint, which helps teams quickly validate ideas and solve business challenges. It provides a step-by-step guide on running sprints and offers practical techniques to foster innovation and collaboration. [Source: Knapp, J., Zeratsky, J., & Kowitz, B. (2016). Sprint: How to Solve Big Problems and Test New Ideas in Just Five Days.]</li>
                    <li>GrowthHackers.com: GrowthHackers is an online community focused on growth hacking and marketing. It offers articles, discussions, and case studies on growth strategies, user acquisition, conversion optimization, and more. The community aspect provides opportunities for learning from and connecting with experienced growth practitioners. [Source: GrowthHackers, https://growthhackers.com/]</li>
                    <li>TED Talks: TED Talks feature inspiring and informative talks by experts from various fields. Several talks are relevant to startups and business growth. Some recommended TED Talks include Simon Sinek's "How Great Leaders Inspire Action," Amy Cuddy's "Your Body Language Shapes Who You Are," and Angela Duckworth's "Grit: The Power of Passion and Perseverance." [Source: TED Talks, https://www.ted.com/]</li>
                    <li>"The Hard Thing About Hard Things" by Ben Horowitz: Horowitz, a successful entrepreneur and investor, shares his experiences and insights on building and leading startups through challenging times. The book offers practical advice on managing tough decisions, fostering company culture, and navigating the complexities of entrepreneurship. [Source: Horowitz, B. (2014). The Hard Thing About Hard Things: Building a Business When There Are No Easy Answers.]</li>
                    <li>Moz Blog: Moz is a reputable source for search engine optimization (SEO) insights. Their blog provides comprehensive articles, guides, and videos on SEO techniques, content marketing, link building, and website optimization. It's a valuable resource for startups looking to improve their online visibility. [Source: Moz Blog, https://moz.com/blog]</li>
                    <li>"How to Start a Startup" (Stanford University): This video lecture series features guest speakers, including successful entrepreneurs and investors, sharing their expertise on building and scaling startups. It covers various topics, from idea generation to hiring and managing teams. The series provides valuable insights from industry experts. [Source: "How to Start a Startup," Stanford University, https://www.youtube.com/playlist?list=PL5q_lef6zVkaTY_cT1k7qFNF2TidHCe-]</li>
                </ol>         
            </p>
          </div>
          <p style="font-weight: bold;">
            <router-link to="contact">Contact us</router-link> to learn how it works, get a demo, and a free account.
          </p>
    
        </div>
      </div>    
    </div>
  </template>
  
  <script>
  import { isLoggedIn } from "../utils/auth";
  
  export default {
    title: 'Resources for founders and leaders | Foclarity',
    name: "Resources",
    mounted: function() {
    },
    methods: {
      isLoggedIn() {
        return isLoggedIn();
      },
      register: function () {
        this.$router.push("register");
      }
    },
    data () {
        return {
          showModal: false
        }
    },
    created () {
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .homebg {
    background: #fff url("~@/assets/homebg-gray@2x-80.jpg")no-repeat top right ; 
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-size: contain ;
    @media only screen and (max-width: 440px) {
      background: #ffffff url("~@/assets/homebg-gray-80.jpg") top right no-repeat;
      background-position-x: 0px;
    }
  }
  .homehero {
    padding: 20px ;
    max-width: 1200px ;
    min-width: 300px;
    margin-left:auto;
    margin-right:auto;
    & h1 {
      margin:40px 0 70px 0;
      max-width: 600px ;
    }
    & a {
      text-decoration: none;
      & .signupbox {
        text-align: center;
        padding: 8px;
        background: #0066CC ;
        // background: #41495B;
        width: 250px;
        border-radius: 4px;
        color: #fff;
      }
    }
    @media only screen and (max-width: 440px) {
      & h1 {
        margin:20px 0 20px 0;
        font-size: 2rem ;
      }
      padding: 0px 8px ;
    }
  }
  .heroblue {
    background: #ffffff; //linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
    min-height: 320px;
    margin: -20px -20px 20px -20px;
    border-radius: 4px 4px 0 0;
    @media only screen and (max-width: 440px) {
      border-radius: 0px 0px 0 0;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    & h1 {
      text-align: center;
      color: #333;
      font-size: 45px;
      max-width: 600px;
      @media (max-width: 415px) {
        font-size: 36px;
        padding: 20px;
      }
    }
  }
  .marketline {
    font-size: 1rem;
    text-transform: uppercase;
    color:#333;
    font-weight: 700;
  }
  
  .inlinebubble {
    color:#fff;
    border:15px solid #f3bc45;
    background:#fff url("~@/assets/dylan-web.jpg") no-repeat top left;
    background-size:contain;
    padding:40px;
    border-radius:50%;
    width:400px;
    height:400px;
    text-align:center;
    margin: 10px auto;
    @media (max-width: 415px) {
      border:0px solid #0066CC;
      padding:20px 10px;
      // border-radius:0%;
      width:300px;
      height:300px;
    }
  }
  
  .quotebubble {
    color:#fff;
    border:10px solid #eeeeee;
    background:#333;
    padding:40px;
    border-radius:50%;
    width:400px;
    height:400px;
    font-size:19px;
    text-align:center;
    margin: 10px auto;
    @media (max-width: 415px) {
      border:0px solid #0066CC;
      background:#333;
      padding:20px 10px;
      border-radius:0%;
      width:100%;
      height:auto;
    }
  }
  </style>
  
  