<template>
  <div class="content">
    <div class="middled">

      <div class="bodyblock" v-show="loading" style="text-align:center;">Loading...</div>
      <div class="breadcrumbs">
          <router-link :to="{ name: 'clients'}">Clients</router-link>
          >
          <router-link :to="{ name: 'client', params: { client_id: this.$route.params.client_id }}">{{ client.client_company_name }}</router-link>
      </div>

      <div class="dashboard-content" v-show="!loading" v-if="project">
        <div class="row">
          <div class="column">
            <h1>
                {{ project.project_name }}
            </h1>
            <div>
              {{ project.project_description }}
              <div style="font-size:.8rem;margin:8px 0;" >
                Updated: {{ project.updated_at | moment("MMM DD, YYYY") }} | 
                Tasks: {{ ptasks.length }} |
                <a href="#" @click.prevent="assignProject(project.project_name, project.project_description, project.project_id)">Edit Project Description</a>
              </div>
            </div>
          </div>
        </div>

        <h3>Project Tasks:</h3>
        <div class="row" style="padding:8px 0;border:0px solid #ccc;border-top:1px solid #ccc;" v-for="(ptask, index) in ptasks" :key="ptask.ptask_name">
          <div class="column ptask" style="min-width:30px;flex: 0 0 auto;vertical-align:top;">
            <div v-bind:class="{ checked: ptask.completed }" class="ptask_checkbox" @click="ptaskdone(ptask.ptask_id, index, ptask.completed)"></div>
          </div>
          <div class="column ptask" style="font-size:1rem;vertical-align:top;cursor:pointer;" @click="assignPTask(ptask.ptask_name, ptask.ptask_description, ptask.ptask_id, ptask.due_by, ptask.prioritized, index)">
            <div class="row">
              <div class="column" style="padding:0;flex:0 1 auto;font-weight:bold;color:#0066cc" :class="{strike: ptask.completed}">
                {{ ptask.ptask_name }}
              </div>
              <div class="column" style="padding:0;flex:1 0 auto;">
                {{ ptask.ptask_description | truncate(100, "...") }}
              </div>
              <div class="column dueby" v-if="!ptask.completed" style="flex:0 1 auto;">
                Due: {{ ptask.due_by | moment("MMM DD, YYYY") }}
              </div>
              <div class="column dueby" v-else style="flex:0 1 auto;">
                Done: {{ ptask.updated_at | moment("MMM DD, YYYY") }}
              </div>
            </div>
          </div>
        </div> 

        <div class="row" style="border-top: 1px solid #ccc;" >
          <div class="column" style="flex:0 1 auto;">
            <input type="text" placeholder="Enter a task name" class="ptaskinput" v-model="ptask_name" v-on:keyup.enter="makeptask">
          </div>
          <div class="column" style="flex:1 0 auto;">
            <input type="text" placeholder="Description" class="ptaskinput" v-model="ptask_description" v-on:keyup.enter="makeptask">
          </div>
          <div class="column" style="flex:0 1 auto">
            <button :disabled="ptask_name.length == 0" style="width:auto;margin:4px;" v-on:click="makeptask"> Save </button>
          </div>
        </div>

        <!-- use the modal component, pass in the prop -->
        <modal v-if="showEditProject" @close="showEditProject = false">
          <template v-slot:header>
            <h3>Edit Project</h3>
          </template>
          <template v-slot:body>
            <div class="form-grp">
                <label for="project_name">Project Name</label>
                <input v-model="edit_project_name" style="width:100%;" />
            </div>
            <div class="form-grp">
                <label for="project_description">Project Description</label>
                <textarea v-model="edit_project_description" />
            </div>
            <div class="form-group">
                <button style="margin-right:10px;" class="btn btn-primary" @click="editProject">Save</button>
            </div>
          </template>
          <template v-slot:footer>
            <div v-if="edit_project_msg">{{edit_project_msg}}</div>
            <div v-else>Save your changes or click "Close" to cancel</div>
          </template>
        </modal>
        
        <!-- use the modal component, pass in the prop -->
        <modal v-if="showEditPTask" @close="showEditPTask = false">
          <template v-slot:header>
            <h3>Edit Project Task</h3>
          </template>
          <template v-slot:body>
            <div class="form-grp">
                <label for="ptask_name">Task Name</label>
                <input v-model="edit_ptask_name" style="width:100%;" />
            </div>
            <div class="form-grp">
                <label for="ptask_description">Task Description</label>
                <textarea v-model="edit_ptask_description" />
            </div>
            <div class="form-grp">
              <label for="due_by">Due By</label>
              <template>
                <div class='date-picker'>
                  <div class='custom-select'>
                    <select @change='editValue("month", $event)' v-model='month'>
                      <option
                        v-for='(month, index) in months'
                        :key='month'
                        :value='index'>
                        {{ month }}
                      </option>
                    </select>
                  </div>
                  <div class='custom-select'>
                    <select @change='editValue("date", $event)' v-model='date'>
                      <option
                        v-for='i in daysInMonth'
                        :key='i'
                        :value='i'>
                        {{ i }}
                      </option>
                    </select>
                  </div>
                  <div class='custom-select'>
                    <select @change='editValue("year", $event)' v-model='year'>
                      <option
                        v-for='i in numberofYears'
                        :key='i'
                        :value='startingYear + (i - 1)'>
                        {{ startingYear + (i - 1) }}
                      </option>
                    </select>
                  </div>
                </div>
              </template>
              <div style="clear:both;"></div>
            </div>
            <div class="form-group">
                <button style="margin-right:10px;" class="btn btn-primary" @click="editPTask">Save</button>
                <button class="btn btn-edit" @click="showEditPTask = false">Cancel</button>
                <button style="float:right;" class="btn btn-danger" @click="deletePTask(edit_ptask_id, edit_index)">Delete Task</button>
            </div>
          </template>
          <template v-slot:footer>
            <div v-if="edit_ptask_msg">{{edit_ptask_msg}}</div>
            <div class="tiny" v-else>Save changes before closing</div>
          </template>
        </modal>
        
      </div>
    </div>
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";
import { userService, accountService, projectService, ptaskService, clientService } from "../_services"
import { router } from '../_helpers';
import modal from "@/components/includes/InfoModal"
import moment from 'moment'

export default {
  title: 'Project | Foclarity',
  name: "Project",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  data () {
      return {
        user: '',
        account: '',
        client: '',
        project: '',
        project_id: this.$route.params.project_id,
        ptasks: [],
        ptask_name: '',
        ptask_description: '',
        loading: true,
        error: "",
        projects: [],
        open: true,
        showEditProject: false,
        edit_project_id: 0,
        edit_project_name: "",
        edit_project_description: "",
        edit_project_msg: "",
        showEditPTask: false,
        edit_ptask_id: 0,
        edit_ass_id: 0,
        edit_ptask_name: "",
        edit_ptask_description: "",
        edit_due_by: "",
        edit_prioritized: false,
        edit_index: 0,
        edit_ptask_msg: "",
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        startingYear: 2021,
        numberofYears: 5,
        daysInMonth: 30,
        dateValue: "",
        month: "",
        date: "",
        year: ""
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
    construction: (d) => {
      alert(d)
    },
    editValue: function (unit, evt) {
      this.dateValue.set(unit, evt.target.value)
      this.daysInMonth = this.dateValue.daysInMonth()
      this.edit_due_by = this.dateValue.format()
      // 2021-05-28T12:53:12.247522-07:00
      this.updateElements()
    },
    updateElements: function () {
      // month.value gives us the <select> 
      this.month = this.dateValue.month()
      this.date = this.dateValue.date()
      this.year = this.dateValue.format('YYYY')
    },
    assignProject: function (project_name, project_description, project_id) {
      this.edit_project_name = project_name
      this.edit_project_description = project_description
      this.edit_project_id = project_id
      this.showEditProject = true
    },
    editProject: function () {
      projectService.updateProject(this.$route.params.client_id, this.edit_project_id, this.edit_project_name, this.edit_project_description).then(
        data => {
          if (data.status == "success") {
            this.project.project_name = data.project.project_name ;
            this.project.project_description = data.project.project_description ;
            this.edit_project_id = 0
            this.edit_project_name = ""
            this.edit_project_description = ""
            this.edit_project_msg = ""
            this.showEditProject = false ;
          } else {
            console.log(data.error)
            alert("Ran into an issue trying to update this project. Please try again.")
          }
        }
      )
      .catch(error => {
        console.log(error)
      })
    },
    assignPTask: function (ptask_name, ptask_description, ptask_id, due_by, prioritized, index) {
      this.edit_ptask_name = ptask_name
      this.edit_ptask_description = ptask_description
      this.edit_ptask_id = ptask_id
      this.edit_due_by = due_by
      this.edit_prioritized = prioritized
      this.edit_index = index
      this.dateValue = moment(due_by)
      this.daysInMonth = this.dateValue.daysInMonth()
      this.updateElements()
      this.showEditPTask = true
    },
    editPTask: function () {
      var cid = this.$route.params.client_id ;
      var pid = this.$route.params.project_id ;
      ptaskService.updatePTask(cid, pid, this.edit_ptask_id, this.edit_ptask_name, this.edit_ptask_description, this.edit_due_by, this.edit_prioritized).then(
        data => {
          if (data.status == "success") {
            this.ptasks[this.edit_index].ptask_name = data.ptask.ptask_name ;
            this.ptasks[this.edit_index].ptask_description = data.ptask.ptask_description ;
            this.ptasks[this.edit_index].completed = data.ptask.completed ;
            this.ptasks[this.edit_index].due_by = data.ptask.due_by ;
            this.ptasks[this.edit_index].prioritized = data.ptask.prioritized ;
            this.edit_ptask_id = 0
            this.edit_ass_id = 0
            this.edit_ptask_description = ""
            this.edit_due_by = ""
            this.edit_prioritized = false
            this.edit_index= 0
            this.edit_ptask_msg = ""
            this.showEditPTask = false ;
          } else {
            console.log(data.error)
            alert("Ran into an issue trying to update this project task. Please try again.")
          }
        }
      )
      .catch(error => {
        console.log(error)
      })
    },
    readptasks: function () {
      var cid = this.$route.params.client_id ;
      var pid = this.$route.params.project_id ;
      ptaskService.readPTasks(cid, pid).then(
        data => {
          this.ptasks = data.ptasks
          // console.log(data)
        }
      )
      .catch(error => {
        this.error = error ;
        console.log(error)
        //this.loading = false ;
      })
    },
    makeptask: function () {
      if (this.ptask_name == '') {
        alert('enter a task')
        return
      }
      var cid = this.$route.params.client_id ;
      var pid = this.$route.params.project_id ;
      ptaskService.createPTask(cid, pid, this.ptask_name, this.ptask_description).then(
        data => {
          this.ptasks.push(data.ptask)
          this.ptask_name = ''
          this.ptask_description = ''
        }
      )
      .catch(error => {
        this.error = error ;
        this.loading = false ;
      })
    },
    deletePTask: function (ptask_id, index) {
      if(confirm("Really want to delete this project task?")){
        var cid = this.$route.params.client_id ;
        var pid = this.$route.params.project_id ;
        ptaskService.deletePTask(cid, pid, ptask_id).then(
          data => {
            if (data.status == "success") {
              this.ptasks.splice(index, 1) ;
              this.showEditPTask = false ;
            } else {
              alert("Ran into an issue trying to delete this project task. Please try again.")
            }
          }
        )
      }
    },
    ptaskdone: function (ptask_id, index, completed) {
      var cid = this.$route.params.client_id ;
      var pid = this.$route.params.project_id ;
      ptaskService.togglePTask(cid, pid, ptask_id, completed).then(
        data => {
          if (data.status == "success") {
            this.ptasks[index].completed = !completed ;
          } else {
            alert("Ran into an issue trying to update this ptask. Please try again.")
          }
        }
      )
    },
    getProject: function () {
        projectService.readProject(this.$route.params.client_id, this.project_id).then(
        data => {
          this.project = data.project ;
          this.loading = false;
        }
      )
      .catch(error => {
          //console.log(error)
          this.error = error ;
          this.loading = false ;
      });      
    },
  },
  components: {
    modal
  },
  created () {
    this.account = accountService.getActiveAccount();
    userService.getMe().then(data => {
      this.loading = true ;
      this.user = data;      
      if (this.account == null) {
        router.push("/accounts")
        return
      }
      projectService.readProject(this.$route.params.client_id, this.project_id).then(
        data => {
          this.project = data.project
          this.project_id = data.project.project_id
          this.readptasks() ;
        }
      )
      clientService.readClient(this.$route.params.client_id).then(
        data => {
          this.client = data.client
        }
      )
      this.loading = false ;
    });
  },
  computed: {
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }  
};
</script>

<style lang="scss" scoped>
.strike {
  text-decoration: line-through ;
  color: #333 !important;
}
.column.ptask {
  min-width: 30px ;
}
.column.dueby {
  font-size:.8rem;
  color:#0066cc;
  text-transform:capitalize;
  text-align:right;
  padding:4px 0 0 0;  
  @media (max-width: 600px) {
    text-align:left;
    min-width: 100%;
  }
}
.ptask_checkbox {
  width:20px;
  height:20px;
  border:1px solid #ccc;
  border-radius:50%;
  background:#fff;
  cursor:pointer;
  &.checked {
    background: #888;
    border: 1px solid #888;
  }
}

.ptaskinput:focus::placeholder {
  color: transparent;
}

.categorycard {
  cursor: pointer ;
}

.score {
  font-size: 4rem ;
  color: #000 ;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.mission {
  padding:0 10px 20px 20px;
  margin:0px 0px 0 0;
  border-radius: 0 0 4px 4px;
  border-top: 2px solid #000;
  color:#000;
  background: #fff url("~@/assets/parchment-bg2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

</style>

