import { authHeader, router } from '../_helpers';

export const clientService = {
    createClient,
    readClients,
    readClient,
    updateClient,
    deleteClient,
    readClientProjects
};

function deleteClient(client_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" 
        + uid 
        + "/accounts/" 
        + aid 
        + "/clients/" 
        + client_id

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            // store client details in local storage 
            localStorage.removeItem('client');
            return data;
        })
        .catch(error => {
            console.log(error)
            router.push('/clients')
        });
}

function createClient(client_company_name, client_description) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ client_company_name, client_description })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/clients" 

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // console.log(data)
            // client creation is successful if there's a client in the response
            if (data.client) {
                // add client to local storage
                localStorage.removeItem('client');
                localStorage.setItem('client', JSON.stringify(data.client));
            }
            return data.client;
        });
}

function updateClient(client_id, client_company_name, client_description) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ client_id, client_company_name, client_description })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/clients/" + client_id 

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // console.log(data)
            return data;
        });
}

function readClient(client_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" 
        + uid 
        + "/accounts/" 
        + aid 
        + "/clients/" 
        + client_id

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            return data;
        })
        .catch(error => {
            console.log(error)
            // router.push('/dashboard')
        });
}

function readClients() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/clients" 

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // clients array in the response
                return data;
    });

}

function readClientProjects(client_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" 
        + uid 
        + "/accounts/" 
        + aid 
        + "/clients/" 
        + client_id
        + "/projects"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // projects array in the response
            return data;
        });

}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

