<template>
  <div class="content">
    <div class="middled">
      <div v-show="loading" style="text-align:center;">Loading...</div>

      <div v-show="!loading" style="border-bottom:2px solid #333;padding:6px 0 0 0;margin:0 6px;">
        <div style="padding:6px 0;">
          <h2 style="margin-top: 0;">{{ account.account_name }} Clients</h2>
        </div>
      </div>

      <div class="row">
        <div class="column">
          <div v-show="!loading">
            <div v-if="clients.length > 0">
              <div v-for="client in clients" :key="client.client_id">
                <div class="" >
                  <div style="padding:8px 0; margin:1px 0;border-bottom:1px solid #ccc;cursor:pointer;" v-on:click="switchClient(client.client_id)">
                    <div style="font-weight:bold;color:#0066cc;">
                      {{ client.client_company_name }}
                    </div>
                    <div class="tiny">
                      {{ client.client_description }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <p>{{ account.account_name }} doesn't have any clients yet.</p>
              <p>This area is for Consultants to manage projects for {{ account.account_name}} clients.</p>
              <p>
                Need help?<br><a href="/contact">Schedule a free help session</a>
              </p>
            </div>
            <div class="action-panel">
              <a href="/new-client">
                <button> Add client </button>
              </a>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { router } from '../_helpers';
import { isLoggedIn } from "../utils/auth";
import { clientService } from "../_services/client.service"
import { accountService, userService } from '../_services';

export default {
  title: 'Clients | Foclarity',
  name: "Clients",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  methods: {
    premiumFeature: (msg) => {
      alert(msg + " is a Premium Feature. Contact us to upgrade.")
    },
    isLoggedIn() {
      return isLoggedIn();
    },
    deleteclient: (client_id) => {
      //console.debug('trying to delete client')
      clientService.deleteClient(client_id).then(router.push("/dashboards"))
    },
    switchClient: (client_id) => {
      // alert("not ready yet: " + client_id)
      router.push("/clients/" + client_id)
    },
    // eslint-disable-next-line no-unused-vars
    handleSubmit (e) {
        this.submitted = true;
        const { name } = this;

        // stop here if form is invalid
        if (!(name)) {
            return;
        }

        this.loading = true;
        clientService.createClient(name)
            .then(
                // eslint-disable-next-line no-unused-vars
                client => router.push("dashboards"),
                error => {
                    this.error = error ;
                    this.loading = false ;
                    this.submitted = false ;
                }
            );
    }
  },
  components: {},
  data () {
      return {
        user: '',
        account: '',
        clients: [],
        name: '',
        submitted: false,
        loading: true,
        returnUrl: '',
        error: '',
        lastlogin: ''
      }
  },
  created () {
    this.account = accountService.getActiveAccount();
    userService.getMe().then(data => {
      this.user = data
      if (this.user.user_id === this.account.user_id) {
        clientService.readClients().then(data => {
          this.clients = data.clients
          this.loading = false
        });
      } else {
        this.$router.push("/dashboards")
      }
    })
  }
};
</script>

<style lang="scss" scoped>
.welcomeback {
  padding: 40px;
  text-align: center;
  border: 1px solid #ccc;
}

.heroblue {
  background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
  & a {
    text-decoration: none;
    color: #fff;
    & .signupbox {
      text-align: center;
      padding: 8px;
      font-size: 24px;
      font-weight: bold;
      background: #ff7f24;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: auto;
    }
  }
}

</style>

