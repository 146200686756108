<template>
  <div class="content bg8">
    <div class="middled">
      <h1>
        {{ first_name }} {{ last_name }}
      </h1>
      <div class="form-grp">
        <span v-if="consultant">(Premium Consultant Account)</span>
        <span v-else>
          Free Account (<a href="/contact">Upgrade</a>)
        </span>
      </div>

      <div class="row">
        <div class="column">
          <div v-if="profile_image">
            <img v-on:click="editProfileImage = true" style="width:80px;cursor:pointer;border-radius: 10px;" :src="profile_image_url" alt="edit profile" />
          </div>
          <div v-else>
            <img v-on:click="editProfileImage = true" style="width:80px;cursor:pointer;border-radius: 10px;" :src="require('@/assets/ic_profile_image.png')" alt="edit profile" />
          </div>
          <div style="margin:20px 0">
            <a href="" v-on:click.prevent="editProfileImage = true">Change Image</a><br>
            <span class="tiny">(80x80 jpeg or png)</span></div>
        </div>
        <div class="column">
          <form @submit.prevent="handleSubmit">
              <div class="form-grp">
                  <label>First Name</label>
                  <input type="text" v-model="first_name" name="first_name" />
              </div>
              <div class="form-grp">
                  <label>Last Name</label>
                  <input type="text" v-model="last_name" name="last_name" />
              </div>
              <div class="form-grp">
                  <label for="email">Email</label>
                  <span>{{email}}</span>
              </div>
              <div class="form-group">
                  <button class="btn btn-primary" :disabled="loading">Update</button>
                  <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              </div>
              <div v-if="error" class="alert alert-danger">{{error}}</div>
          </form>
        </div>
      </div>
    </div>
    <!-- use the modal component, pass in the prop -->
    <UploadProfileImage v-if="editProfileImage" @close="closeModal" :profile_image="profile_image"></UploadProfileImage>

  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";
import { userService } from "../_services/user.service"
import UploadProfileImage from "../components/includes/UploadProfileImage.vue";

export default {
  title: 'Profile | Foclarity',
  name: "Profile",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  computed: {
    profile_image_url() {
      var fullurl = "https://corl8-files.s3.amazonaws.com/" + this.profile_image
      return fullurl
    } 
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
    closeModal (value) {
      this.profile_image = value
      this.editProfileImage = false
    },
    // eslint-disable-next-line no-unused-vars
    handleSubmit (e) {
      this.submitted = true;
      const { first_name, last_name } = this;

      this.loading = true;
      userService.updateMe(first_name, last_name)
          .then(
              // eslint-disable-next-line no-unused-vars
              user => {
                this.error = 'Profile updated successfully';
                setTimeout(() => this.$router.push("dashboards"), 2000);
              },
              error => {
                  this.error = error;
                  this.loading = false;
              }
          );
        }    
  },
  components: { UploadProfileImage },
  data () {
      return {
        first_name: '',
        last_name: '',
        email: '',
        profile_image: '',
        consultant: false,
        submitted: false,
        loading: true,
        returnUrl: 'profile',
        error: '',
        editProfileImage: false
      }
  },
  created () {
    userService.getMe().then(data => {
      this.first_name = data.first_name
      this.last_name = data.last_name
      this.email = data.email
      this.profile_image = data.profile_image
      this.consultant = data.consultant
      this.loading = false
    })
  }
};
</script>

<style lang="scss" scoped>
.login {
  max-width: 400px;
  padding: 20px;
  & input {
    background: #eee ;
  }
}
</style>

