import { authHeader, formAuthHeader } from '../_helpers';

export const accountService = {
    createAccount,
    readAccount,
    updateAccount,
    deleteAccount,
    readAccounts,
    readMemberships,
    changeOwner,
    deleteMembership,
    readAccountMembers,
    switchAccount,
    switchMembership,
    getActiveAccount,
    clearActiveAccount,
    contactSupport,
    getInvitations,
    getOpenInvitations,
    deleteOpenInvitation,
    acceptInvitation,
    createInvitation,
    createMission,
    getMission,
    getAccountReport,
    emRpt,
    emStatus,
    summarize,
    getAccountSummary,
    getSavedSummary,
    measureTips,
    generateMissionSummary,
    uploadLogo
};
function updateAccount(
            account_name, 
            account_contact_name,
            account_address,
            account_city,
            account_state,
            account_zip,
            account_country,
            account_phone,
            account_fax,
            account_email,
            account_tid,
            account_license,
            deleted) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ 
            account_name, 
            account_contact_name,
            account_address,
            account_city,
            account_state,
            account_zip,
            account_country,
            account_phone,
            account_fax,
            account_email,
            account_tid,
            account_license,
        deleted })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid 
        + "/accounts/" + aid 

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // console.log(data)
            if (data.account) {
                // replace account in local storage
                localStorage.removeItem('account');
                localStorage.setItem('account', JSON.stringify(data.account));
            }
            return data;
        });
}

function deleteAccount() {}

function createAccount(account_name) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ account_name })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts"
    //console.log("UID: " + uid)

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // account creation is successful if there's an account in the response
            if (data.account) {
                // add account to local storay
                localStorage.removeItem('account');
                localStorage.setItem('account', JSON.stringify(data.account));
            }
            return data.account;
        });
}

function readAccount(account_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + account_id
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // account read successful
            return data.account;
        });
}

function readMemberships() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/memberships"
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // accounts array in the response
            if (data.memberships) {
                // store accounts in local storage 
                localStorage.setItem('memberships', JSON.stringify(data.memberships));
            }

            return data.memberships;
        });

}

function changeOwner(mid) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ mid })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/owners/" + uid + "/new_owner/" + mid
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // message in the response
            return data;
        });
}

function deleteMembership(mid) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/memberships/" + mid
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // message in the response
            return data;
        });
}

function readAccountMembers() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/memberships"
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // accounts array in the response
            return data;
        });

}

function readAccounts() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts"
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // accounts array in the response
            if (data.accounts) {
                // store accounts in local storage 
                localStorage.setItem('accounts', JSON.stringify(data.accounts));
            }

            return data.accounts;
        });

}


function switchAccount(account_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    
    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + account_id
    return fetch(endpoint, requestOptions)
    .then(handleResponse)
    .then(data => {
        // login successful if there's an account in the response
        if (data.account) {
            // remove active account from local storage
            localStorage.removeItem('account');
            localStorage.removeItem('invoice'); // get rid of old stuff
            // store account details in local storage 
            localStorage.setItem('account', JSON.stringify(data.account));
            return data.account;
        }
    });
}

function switchMembership(account_id) {
    // remove active account from local storage
    localStorage.removeItem('account');
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + account_id
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // login successful if there's an account in the response
            if (data.account) {
                // remove active account from local storage
                localStorage.removeItem('account');
                localStorage.removeItem('invoice'); // get rid of old stuff

                // store account details in local storage 
                localStorage.setItem('account', JSON.stringify(data.account));
            }

            return data.account;
        });

}

function getActiveAccount() {
    let account = JSON.parse(localStorage.getItem('account'))
    return account
}

function clearActiveAccount() {
    // remove active account from local storage
    localStorage.removeItem('account');
    return true
}

function getAccountReport() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/report"
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // report in the response
            return data.report ;
        });
}

function contactSupport(subject, message) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ subject, message })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/contact"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });

}

function getMission() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/missions"
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // mission in the response
            return data.mission ;
        });
}

function getInvitations() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/invitations"
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // invitations array in the response
            return data.invitations;
        });
}

function createMission(account_vision, account_mission, account_strategy, account_values, account_priorities, account_summary) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ account_vision, account_mission, account_strategy, account_values, account_priorities, account_summary })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/missions"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // mission creation is successful if there's a success message
            return data;
        })
}

function emRpt() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({})
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/emreport"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // email report is successful if there's a success message
            return data;
        })
}

function summarize(scid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/scorecards/" + scid + "/summary/generate"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // email report is successful if there's a success message
            return data;
        })
}

function getAccountSummary() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/summary"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // account summary is successful if there's a success message
            switchAccount(aid)
            return data;
        })
        .catch(error => {
            // console.log("error: " + error)
            return error
        });
}

function getSavedSummary(scid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/scorecards/" + scid + "/summary"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // email report is successful if there's a success message
            return data;
        })
}

function measureTips(scid, cid, mid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/scorecards/" + scid + "/categories/" + cid + "/measures/" + mid + "/tips"
    // console.log(endpoint)

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // successful if there's a success message
            return data;
        })
}

function generateMissionSummary(account_vision, account_mission, account_strategy, account_values, account_priorities) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ account_vision, account_mission, account_strategy, account_values, account_priorities })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/missionsummary"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // mission creation is successful if there's a success message
            return data;
        })
}


function emStatus(scid, emlist) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({emlist})
    };

    // console.log(emlist)

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/scorecards/" + scid + "/emstatus"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // email report is successful if there's a success message
            return data;
        })
}

function createInvitation(invitation_email) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ invitation_email })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/invitations"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // invite creation is successful if there's a success message
            return data;
        });
}

function getOpenInvitations() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/invitations"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // invitations could be empty
            return data;
        });
}

function deleteOpenInvitation(iid) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/invitations/" + iid

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function acceptInvitation(iid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/invitations/" + iid + "/accept"
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                location.reload();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function uploadLogo(logoimage) {
    let formData = new FormData();
    formData.append("logo", logoimage);

    const requestOptions = {
        method: 'POST',
        headers: formAuthHeader(),
        body: formData
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/logo"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // console.log("success: " + data)
            var account = JSON.parse(localStorage.getItem('account'))
            account['logo_url'] = data.new_filename ;
            localStorage.setItem('account', JSON.stringify(account))
            return data
        })
        .catch(error => {
            // console.log("error: " + error)
            return error
        });
}