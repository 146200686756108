<template>
  <div class="content">
    <div class="middled">
      <div class="bodyblock" v-show="loading" style="text-align:center;padding:40px;font-size:2rem;">Loading...</div>
      <div class="dashboard-content" v-show="!loading">
        
        <div class="row searchrow">
          <div class="column" v-show="false">
            <div class="breadcrumbs">
              {{ account.account_name }}
            </div>
          </div>
          <div class="column" v-show="chat_messages.length > 1">
            <searchbar v-bind:reported="report" />
          </div>
        </div>
        
        <div v-if="chat_messages.length == 0">
          <div style="text-align: center;margin:20px;">
            <img :src="require('@/assets/ic_sparkle.png')" width="20" height="20" alt="AI Chat" title="AI Chat" style="margin:8px;" />
          </div>
          <div class="greeting" style="text-align: center;">
            <strong>{{ greeting_message }}, {{ user.first_name }}! </strong>
          </div>
          <div style="text-align: center;">
            <p>How can I help you today?</p>
          </div>
        </div>

        <div class="row" v-if="user && user.first_name" style="margin-left:6px;font-size: .8em;">
          <div class="column" style="max-width: 600px;margin:auto;">
            <!-- I need 4 small boxes in a 2x2 grid that I can put some text into -->
            <div class="row" style="padding:0;" v-if="chat_messages.length < 1" v-show="false">
              <div class="column">
                <div class="dashcard" style="padding:10px;">
                  <div style="text-align:center;">
                    <a href="#" v-on:click.prevent="clickChat('What am I responsible for?')">What am I responsible for?</a>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="dashcard" style="padding:10px;">
                  <div style="text-align:center;">
                    <a href="#" v-on:click.prevent="clickChat('What should I be working on?')">What should I be working on?</a>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="dashcard" style="padding:10px;">
                  <div style="text-align:center;">
                    <a href="#" v-on:click.prevent="clickChat('What are others working on?')">What are others working on?</a>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="dashcard" style="padding:10px;">
                  <div style="text-align:center;">
                    <a href="#" v-on:click.prevent="clickChat('What is falling behind?')">What is falling behind?</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- If chat has objects, loop through them here -->
        <div class="row" v-if="chat_messages.length > 0" style="max-width: 800px;margin:auto;">
          <div class="column">
            <div class="chatbox">
              <div v-for="(msg, index) in chat_messages" :key="index" :class="msg.sender
              ? 'chatmsg user'

              : 'chatmsg bot'">
                <div v-if="msg.sender === 'user'" style="text-align:left;margin-bottom:40px;">
                  <div style="font-weight: bold;">
                    <div v-if="user.profile_image" :style="'border-radius:50%;width:30px;height:30px;background:url(https://corl8-files.s3.amazonaws.com/' + user.profile_image +') no-repeat;background-size:cover;display:inline-block;vertical-align:middle;margin-right:8px;'"></div>
                    <div v-else style="border-radius:50%;width:30px;height:30px;display:inline-block;vertical-align:middle;margin-right:10px;" >
                      <img style="width:30px;height:30px;" :src="require('@/assets/ic_person.png')" alt="profile icon" />
                    </div>
                    <div style="display:inline-block;vertical-align:middle;">
                      {{ user.first_name }}
                    </div>
                  </div>
                  <div style="padding:10px 0 0 40px;font-size:smaller;" class="scaps">
                    {{ msg.text }}
                  </div>
                </div>
                <div v-else style="text-align:left;margin-bottom:40px;">
                  <div style="font-weight: bold;">
                    <div style="width:30px;height:30px;display:inline-block;vertical-align:middle;text-align:center;padding:2px;margin-right:10px;" >
                      <img style="transform: rotateY(0deg) rotate(135deg);border-radius:50%;width:26px;height:26px;" :src="require('@/assets/foclarity-icon.png')" alt="Foclarity AI" />
                    </div>
                    <div style="display:inline-block;vertical-align:middle;" >
                      Foclarity.AI
                    </div>
                  </div>
                  <div style="padding:10px 0 0 40px;font-size:smaller;">
                    <div v-html="markedup(msg.text)"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <!-- I need one big div that stays at the bottom of the screen and holds my chat input field -->
        <div class="row" style="max-width: 800px;margin:auto;">
          <div class="column" >
            <div class="chatinput">
              <div style="float:right;height: 0;top:8px;">
                <!-- add the submit icon -->
                <img :src="require('@/assets/foclarity-icon.png')" @click="clickChat(chatmsg)" style="width:28px;height:28px;text-align:left;border-radius: 50%;margin-right:8px;cursor:pointer;transform: rotateY(0deg) rotate(135deg);" />
              </div>
              <textarea ref="chatBox" type="text" @disabled="chatmsg.length > 0" placeholder="Message Foclarity AI..." v-model="chatmsg" @keyup.enter="clickChat(chatmsg)" enterkeyhint="send" style="padding-right:40px;" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userService } from "../_services/user.service"
import { accountService } from "../_services/account.service"
import { router } from '../_helpers';
import { chatService, scorecardService } from '../_services';
import searchbar from "@/components/includes/SearchBar"
import marked from 'marked'

export default {
  title: 'AI Chatbot | Foclarity',
  name: "Dashboard",
  data () {
      return {
        user: '',
        account: '',
        greeting_message: 'Hello',
        welcome_message: "Here's your overview for today.",
        account_summary: '',
        full_summary: false,
        summary_stream: [],
        generate_summary_link: "Generate an account summary using AI.",
        members: [],
        account_score: 0,
        snapshots: [],
        scid: 0,
        summary: "",
        loading: true,
        scploading: true,
        active: false,
        message: "",
        invite_email: "",
        invite_message: "",
        show_invite_message: false,
        open_invites: [],
        inviting: false,
        emsg: "",
        error: "",
        submitted: false,
        help: false,
        mission: "",
        report: [],
        showVideo: false,
        scorecards: [],
        showall: "",
        disableDraggable: true,
        show_pending: false,
        showMission: true,
        showInitiatives: true,
        showTeam: true,
        showAccount: true,
        toggleTeam: false,
        blurry: true,
        chatID: 0,
        chatmsg: "",
        chat_messages: [],
      }
  },
  methods: {
    clickChat: function (msg) {
      // console.log(this.chatID);
      this.chatmsg = "Analyzing..."
      this.chat_messages.push({ text: msg, sender: 'user' });

      if (this.chatID == 0) {
        // first chat message
        chatService.chat(msg, 0).then(
          data => {
            // console.log(data);
            this.chat_messages.push({ text: data.chat_message.bot_message, sender: 'bot' });
            this.chatID = data.chat_message.chat_id;
            this.chatmsg = ""
            // this.scrollToBottom();
          },
          error => {
            // console.log(error);
            this.chat_messages.push({ text: "I'm sorry, there was an error: " + error, sender: 'bot' });
            this.chatmsg = ""
          }
        )
      } else {
        // subsequent chat messages
        chatService.chat(msg, this.chatID).then(
          data => {
            // console.log("has id: " + data.chat_message.chat_id);
            this.chat_messages.push({ text: data.chat_message.bot_message, sender: 'bot' });
            this.chatID = data.chat_message.chat_id;
            this.chatmsg = ""
            // this.scrollToBottom();
          },
          error => {
            this.chat_messages.push({ text: "I'm sorry, there was an error: " + error, sender: 'bot' });
            this.chatmsg = ""

          }
        )
      }
    },
    markedup: function (text) {
      return marked(text)
    },
    scrollToBottom() {
      const scrollHeight = document.documentElement.scrollHeight;
      const height = window.innerHeight;
      const fromBottom = 150; // pixels from the bottom

      window.scroll({
        top: scrollHeight - height - fromBottom,
        behavior: 'smooth' // Optional: for smooth scrolling
      });

      this.$nextTick(() => {
        this.$refs.chatBox.scrollIntoView({ 
            behavior: 'smooth',
            block: 'center' 
        });
      });
    },
      getScorecards: function () {
        this.loading = true
        scorecardService.readDashboards().then(
          // scorecardService.readScorecards().then(
            data => {
            this.scploading = true
            this.report = data.report
            this.scorecards = data.dashboards.scorecards ;
            this.scploading = false
            this.loading = false
          } 
        )
        .catch(error => {
            this.loading = false ;
            this.error = error
        });      
      },
    construction: (d) => {
      alert(d)
    },
    goto: function (q) {
        router.push(q)
    },
    gotomeasure: function (q) {
      router.push("/scorecards/" + q.scorecard_id + "/categories/" + q.recent_snapshot.category_id + "/measures/" + q.recent_snapshot.measure_id)
    },
    gotoScorecard: (q) => {
      if (q == 'marketing') {
        router.push("scorecards/1")
      } else if (q == 'sales') {
        router.push("scorecards/10")
      } else if (q == 'ideas') {
        router.push("scorecards/86")
      }
      else {
        // console.debug('going to ' + q)
        router.push("/scorecards/" + q)
      }
    },
    checkDateStuff: function (d) {
      // check if user has logged in within the last 7 days
      var last_login = new Date(d)
      var today = new Date()
      var diff = (today - last_login) / (1000 * 60 * 60 * 24)
      if (diff > 7) {
        // console.debug('last login was ' + diff + ' days ago')
        userService.logout() ;
        router.push("/login") ;
      } else {
        // if it's evening say good evening
        var hour = today.getHours()
        if (hour > 17) {
          this.greeting_message = "Good evening"
        } else if (hour > 12) {
          this.greeting_message = "Good afternoon"
        } else {
          this.greeting_message = "Good morning"
        }
      }
    },
  },
  components: {
    searchbar
  },
  created () {
    userService.getMe().then(data => {
      this.user = data
      this.checkDateStuff(this.user.last_login)
      this.account = accountService.getActiveAccount()
      if (this.account == null) {
        router.push("/accounts")
        return
      }
      if (this.account.account_name) {
        window.document.title = this.account.account_name + " AI Chat | Foclarity"
      }
      this.getScorecards()
      this.loading = false
    });
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
.categorycard {
  cursor: pointer ;
  & .catname {
    color: #333 !important;
  }
}

.score {
  font-size: 4rem ;
  color: #000 ;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.mission {
  padding:0 10px 0px 10px;
  margin:0px 0px 0 0;
  border-radius: 0 0 4px 4px;
  border-top: 2px solid #000;
  color:#000;
  background: #fff;
  background-repeat: no-repeat;
  background-size: cover;
}
#mission {
  white-space: break-spaces;
}

.activeButton {
  background: #0066CC;
  color: #fff;
  border: 1px solid #ccc;
}

h2, h4 {
  color: #333;
}

.column.dashcard, .dashcard {
  flex: 0 ;
  background: #fff;
  box-shadow: 0px 2px 3px #ccc;
  border-radius: 4px;
  padding:0px;
  // min-width:338px;
  min-width: 32%;
  border:1px solid #919191;
  @media only screen and (max-width: 1500px) {
    // min-width:320px;
    min-width: 32%;
    // flex: 1;
  }
  @media only screen and (max-width: 1130px) {
    // min-width:320px;
    min-width: 32%;
    // flex: 1;
  }
  @media only screen and (max-width: 1050px) {
    // min-width:320px;
    min-width: 49%;
    // flex: 1;
  }
  @media only screen and (max-width: 780px) {
    // min-width:320px;
    min-width: 100%;
    // flex: 1;
  }
}
.dashrow::after {
  content: "";
  flex: 1;
  max-width: 32%;
  min-width: 32%;
  @media only screen and (max-width: 1500px) {
    // min-width:320px;
    max-width: 32%;
    // flex: 1;
  }
  @media only screen and (max-width: 1145px) {
    // min-width:320px;
    max-width: 32%;
    // flex: 1;
  }
  @media only screen and (max-width: 1050px) {
    // min-width:320px;
    max-width: 49%;
    // flex: 1;
  }
  @media only screen and (max-width: 780px) {
    // min-width:320px;
    max-width: 100%;
    // flex: 1;
  }
}
.greeting {
  margin:10px 0;
  font-size: 1.25em;
  font-weight: bold;
  color: #000;
}
.greeting-small {
  margin:0;
  font-size: 1em;
  color:#666;
}

// todo - create a blur on mouseover for the card, not all the cards
.blur000 {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  width: 100px;
  height: 100px;
  background-color: #fff;
}

input:focus::placeholder {
  color: transparent;
}

</style>

