<template>
  <div class="content bg8">
    <div class="middled">
      <h1>
        Add a Client
      </h1>
      <div>
        <div class="register">
          <form @submit.prevent="handleSubmit">
              <div class="form-grp">
                <label for="client_company_name">Client Name</label>
                <input type="text" v-model="client_company_name" name="client_company_name" maxlength="144" />
              </div>
              <div class="form-grp">
                  <label for="client_description">Client Description</label>
                  <textarea v-model="client_description" name="client_description" />
              </div>
              <div class="form-group">
                  <button class="btn btn-primary" :disabled="loading">Save</button> | <a href="/dashboards">Cancel</a>
                  <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              </div>
              <div v-if="error" class="alert alert-danger">{{error}}</div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";
import { clientService } from '../_services/client.service';

export default {
  title: 'New Client | Foclarity',
  name: "New-Client",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
    // eslint-disable-next-line no-unused-vars
    handleSubmit (e) {
        this.submitted = true;
        const { client_company_name, client_description } = this;

        this.loading = true;
        console.log("starting")
        clientService.createClient(client_company_name, client_description)
            .then(
                data => {
                    // this.error = 'Client ' + data.client_company_name + ' created and saved. Returning to dashboards....';
                    // this.loading = false
                    // console.log(data)
                    window.location.href= `/clients/`+data.client_id
                },
                error => {
                    this.error = error;
                    this.loading = false;
                }
            );
    }    
  },
  components: {},
  data () {
      return {
        client_company_name: '',
        client_description: '',
        submitted: false,
        loading: false,
        returnUrl: 'clients',
        error: ''
      }
  },
  created () {
  }
};
</script>

<style lang="scss" scoped>
</style>

