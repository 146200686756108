<template>
  <div class="content bg8">
    <div class="middled">
      <div class="basehero" style="">
        <h1 style="text-align: center;">Help your team get focused.</h1>
        <p style="text-align: center;">Choose an option below.</p>
        <div class="row">
          <div class="column">
            <div style="padding:0 40px;background:#f0f8ff;border:4px solid #ccc;flex:1;">
              <h3 style="border-bottom:1px solid #ccc;text-align:center" title="Individuals and collaborators join free">Collaborators</h3>
              <ul>
                <li>Template Library</li>
                <li>AI Insight Generator</li>
                <li>Join Teams</li>
                <li>Goals & Tasks</li>
                <li>Progress Tracking</li>
                <li>Visual Reporting</li>
              </ul>
              <p style="font-size:2rem;text-align:center">
                Free<br>
                <span style="font-size:.8rem;">includes one account</span>
              </p>
              <p style="text-align:center">
                <router-link v-if="!isLoggedIn()" :to="{ name: 'register', params: { r: 'r8jw934uHJnr' }}">
                  <button class="btn-add" style="padding:10px 20px;" title="Individuals are free"> Sign Up </button>
                </router-link>
                <router-link v-else to="dashboards">
                  <button style="background:#333;padding:10px 20px;"> Go to Dashboards </button>
                </router-link>
              </p>
            </div>
          </div>
          <div class="column">
            <div style="padding:0 40px;background:#f0f8ff;border:4px solid #87bbfb;flex:1;">
              <h3 style="border-bottom:1px solid #ccc;text-align:center" title="Collaborate with your team">Teams</h3>
              <ul>
                <li>AI Program Assistant</li>
                <li>Mission Alignment</li>
                <li>Frameworks</li>
                <li>Team Collaboration</li>
                <li>Visual Reporting</li>
                <li>Live Status</li>
              </ul>
              <p style="font-size:2rem;text-align:center">
                Get a Quote<br>
                <span style="font-size:.8rem;">per team</span>
              </p>
              <p style="text-align:center">
                <router-link to="contact">
                  <button style="padding:10px 20px;"> Contact Sales </button>
                </router-link>
              </p>
            </div>
          </div>
          <div class="column">
            <div style="padding:0 40px;background:#f0f8ff;border:4px solid #ccc;flex:1;">
              <h3 style="border-bottom:1px solid #ccc;text-align:center">Consultants</h3>
              <ul>
                <li>Consultant Account</li>
                <li>Template Library</li>
                <li>AI Assistant</li>
                <li>Client Projects</li>
                <li>Invoicing</li>
                <li>Consultant Tools</li>
              </ul>
              <p style="font-size:2rem;text-align:center">
                Free<br>
                <span style="font-size:.8rem;">with 2+ paying teams</span>
              </p>
              <p style="text-align:center">
                <router-link to="contact">
                  <button style="padding:10px 20px;"> Contact Sales </button>
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="basehero" style="padding:0 40px;text-align: center;">
        <h2>Not ready to sign up?</h2>
        <p>
            <a href="/contact">
                <button style="width:250px;background: #000;;"> Free Consultation </button>
            </a>
        </p>
      </div>
    </div>    
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";

export default {
  title: 'Pricing | Foclarity',
  name: "Pricing",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
    checkForMetaMask: function () {
      //Have to check the ethereum binding on the window object to see if it's installed
      const { ethereum } = window;
      var isinstalled = Boolean(ethereum && ethereum.isMetaMask);
      console.log("Metamask is connected: " + isinstalled)

      try {
        // Will open the MetaMask UI
        // You should disable this button while the request is pending!
        ethereum.request({ method: "eth_requestAccounts" });
      } catch (error) {
        console.error(error);
      }

    },
  },
  components: {},
  data () {
      return {
      }
  },
  created () {
    // this.checkForMetaMask() ;
  }
};
</script>

<style lang="scss" scoped>

.heroblue {
  background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
  & a {
    text-decoration: none;
    color: #fff;
    & .signupbox {
      text-align: center;
      padding: 8px;
      font-size: 24px;
      font-weight: bold;
      background: #ff7f24;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: auto;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 10px;
  min-width: 200px;
  & .homecard {
    text-align: center;
    padding: 20px;
    border-radius: 4px;
    background: rgba(0, 178, 238, 0.1);
    height: 100%;
    & .cardicon {
      width: 100px;
      height: 100px;
      margin: auto;
      & img {
        width: 80px;
      }
    }
    & .h3 {
      font-size: 23px;
      line-height: 32px;
      color: #CC0099;
      text-align: left;
      @media (max-width: 400px) {
        font-size: 20px;
      }
    }
  }
}

.homequote {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-style: italic;
  color: #666;
  max-width: 600px;
  margin: 20px auto;
}
</style>

