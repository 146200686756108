import { authHeader } from '../_helpers';

export const itemService = {
    createItem,
    readItem,
    updateItem,
    deleteItem,
    readClientItems
};

function createItem(client_id, invoice_id, item_name, item_description, item_price, item_quantity) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
            item_name,
            item_description,
            item_price,
            item_quantity
        })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/clients/" + client_id 
        + "/invoices/" + invoice_id
        + "/items"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            return data
        })
        .catch(error => {
            //console.log(error)
            return error
        });
}

function readItem(client_id, invoice_id, item_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" 
        + uid 
        + "/accounts/" 
        + aid 
        + "/clients/" 
        + client_id 
        + "/invoices/" 
        + invoice_id
        + "/items/" 
        + item_id

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            return data;
        })
        .catch(error => {
            //console.log(error)
            return error
        });
}

function updateItem(client_id, invoice_id, item_id, item_name, item_description, item_price, item_quantity) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ item_name, item_description, item_price, item_quantity })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid 
    + "/accounts/" + aid 
    + "/clients/" + client_id 
    + "/invoices/" + invoice_id
    + "/items/" + item_id

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            return data;
        })
        .catch(error => {
            console.log(error)
            return error ;
        });
}

function deleteItem(client_id, invoice_id, item_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" 
        + uid 
        + "/accounts/" 
        + aid 
        + "/clients/" 
        + client_id
        + "/invoices/" 
        + invoice_id
        + "/items/"
        + item_id

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            return data;
        })
        .catch(error => {
            //console.log(error)
            return error;
        });
}

function readClientItems(client_id, invoice_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" 
        + uid 
        + "/accounts/" 
        + aid 
        + "/clients/" 
        + client_id
        + "/invoices/"
        + invoice_id
        + "/items"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            return data ;
        })
        .catch(error => {
            console.log(error.status)
            return error ;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
