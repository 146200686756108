<template>
  <div class="content bg8">
    <div class="middled" style="padding:0 10px;">
      <h1>
        New Board
      </h1>
      <p>
        Once you create a board you can add categories and tiles.
      </p>
      <div>
        <div class="register">
          <form @submit.prevent="handleSubmit">
              <div class="form-grp">
                <label for="scorecard_name">Name</label>
                <input type="text" v-model="scorecard_name" name="scorecard_name" maxlength="144" />
              </div>
              <div class="form-grp">
                  <label for="scorecard_description">Description</label>
                  <textarea v-model="scorecard_description" name="scorecard_description" placeholder="Optional" />
              </div>
              <div class="form-grp">
                  <label for="scorecard_type">Visibility: Private</label>
              </div>
              <div class="form-group">
                  <button class="btn btn-primary" style="padding:10px 20px;" :disabled="loading">Next</button> | <a href="/dashboards">Cancel</a>
                  <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              </div>
              <div v-if="error" class="alert">{{error}}</div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";
import { scorecardService } from '../_services';

export default {
  title: 'New Board | Foclarity',
  name: "New-Scorecard",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
    // eslint-disable-next-line no-unused-vars
    handleSubmit (e) {
        this.submitted = true;
        const { scorecard_name, scorecard_description, scorecard_type_id } = this;

        this.loading = true;
        //console.debug("starting")
        scorecardService.createScorecard(scorecard_name, scorecard_description, scorecard_type_id)
            .then(
                data => {
                    this.error = 'Saved...';
                    this.loading = false
                    // console.log(data.scorecard_id)
                    this.$router.push("/scorecards/" + data.scorecard_id)
                },
                error => {
                    this.error = error;
                    this.loading = false;
                }
            );
    }    
  },
  components: {},
  data () {
      return {
        scorecard_name: '',
        scorecard_description: '',
        scorecard_type_id: 2,
        submitted: false,
        loading: false,
        returnUrl: 'dashboards',
        error: ''
      }
  },
  created () {
  }
};
</script>

<style lang="scss" scoped>
</style>

