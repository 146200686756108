<template>
  <div class="content homebg">
    <div class="row" style="max-width:1200px;margin:auto;">
      <div class="homehero column">
        <div class="marketline">
          <router-link :to="{ name: 'register', params: { r: 'r8jw934uHJnr' }}">Sign Up</router-link>
        </div>
        <h1>
          Reach your most audacious goals by addressing top-level challenges with focus, clarity, and alignment.
        </h1>

        <p style="max-width:600px;margin-bottom:30px;"> 
          Foclarity helps high-performing leaders address top-level challenges using strategic frameworks, 
          which provide focus, clarity, and alignment for the business.
        </p>

        <div style="max-width:600px;margin-bottom:10px;">
          <p>
            <router-link :to="{ name: 'register', params: { r: 'r8jw934uHJnr' }}">
              <button style="padding:10px 20px;"> Get Started </button>
            </router-link>
          </p>
        </div>
        
        <!-- use the modal component, pass in the prop -->
        <modal v-if="showModal" @close="showModal = false">
          <template v-slot:header>
            <h3>Foclarity for Change Makers</h3>
          </template>
          <template v-slot:body>
            <iframe style="margin-top:10px;" width="100%" height="315" src="https://www.youtube.com/embed/GjWmmbh5sPQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        
          </template>
          <template v-slot:footer>
            <p>Foclarity helps you see the big picture... try it free!</p>
          </template>
        </modal>
      </div>
      <div class="column">
        <div class="quotebubble">
          <div><img src="~@/assets/simmitri-logo.png" alt="Foclarity Customer Simmitri" style="width:200px;"></div>
          <div>
            “We leaned into Foclarity to help Simmitri Solar identify, track, and reach our most audacious scaling and revenue goals.
            The results came much faster than expected, not to mention our team communication across all departments improved greatly as a result”
          </div>
          <div style="margin-top:10px;">
            <p class="tiny" style="line-height:.8rem;">
              - Dan Jenkin<br>
              Executive VP
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row middled">
      <div class="column" style="padding:20px;text-align:center;">
        <h2 style="font-size:2rem;">
          <span class="highlightyellow">Turn big challenges into achievements.</span>
        </h2>
        <div class="row">
          <div class="column">
            <router-link :to="{ name: 'register', params: { r: 'r8jw934uHJnr' }}">
              <button style="background:#000;padding:10px 20px;"> Sign Up </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";
import modal from "@/components/includes/InfoModal"

export default {
  title: 'Executive Management Dashboards | Foclarity',
  name: "business-transformation",
  mounted: function() {
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
    register: function () {
      this.$router.push("register");
    }
  },
  components: {
    modal
  },
  data () {
      return {
        showModal: false
      }
  },
  created () {
  }
};
</script>

<style lang="scss" scoped>
.homebg {
  background: #fff url("~@/assets/homebg-gray@2x-80.jpg")no-repeat top right ; 
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-size: contain ;
  @media only screen and (max-width: 440px) {
    background: #ffffff url("~@/assets/homebg-gray-80.jpg") top right no-repeat;
    background-position-x: 0px;
  }
}
.homehero {
  padding: 20px ;
  max-width: 1200px ;
  min-width: 300px;
  margin-left:auto;
  margin-right:auto;
  & h1 {
    margin:40px 0 70px 0;
    max-width: 600px ;
  }
  & a {
    text-decoration: none;
    & .signupbox {
      text-align: center;
      padding: 8px;
      background: #0066CC ;
      // background: #41495B;
      width: 250px;
      border-radius: 4px;
      color: #fff;
    }
  }
  @media only screen and (max-width: 440px) {
    & h1 {
      margin:20px 0 20px 0;
      font-size: 2rem ;
    }
    padding: 0px 8px ;
  }
}
.heroblue {
  background: #ffffff; //linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  min-height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #333;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
}
.marketline {
  font-size: 1rem;
  text-transform: uppercase;
  color:#333;
  font-weight: 700;
}

.inlinebubble {
  color:#fff;
  border:15px solid #f3bc45;
  background:#fff url("~@/assets/dylan-web.jpg") no-repeat top left;
  background-size:contain;
  padding:40px;
  border-radius:50%;
  width:400px;
  height:400px;
  text-align:center;
  margin: 10px auto;
  @media (max-width: 415px) {
    border:0px solid #0066CC;
    padding:20px 10px;
    // border-radius:0%;
    width:300px;
    height:300px;
  }
}

.quotebubble {
  color:#fff;
  border:10px solid #eeeeee;
  background:#333;
  padding:40px;
  border-radius:50%;
  width:400px;
  height:400px;
  font-size:19px;
  text-align:center;
  margin: 10px auto;
  @media (max-width: 415px) {
    border:0px solid #0066CC;
    background:#333;
    padding:20px 10px;
    border-radius:0%;
    width:100%;
    height:auto;
  }
}
</style>

