import { authHeader, router } from '../_helpers';

export const projectService = {
    createProject,
    readProjects,
    readProject,
    updateProject,
    deleteProject,
    getActiveProject,
    readClientProjects
};

function deleteProject(client_id, project_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" 
        + uid 
        + "/accounts/" 
        + aid 
        + "/clients/" 
        + client_id
        + "/projects/" 
        + project_id

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            // store project details in local storage 
            // localStorage.removeItem('project');
            return data;
        })
        .catch(error => {
            //console.log(error)
            router.push('/projects?error=' + error)
        });
}

function createProject(client_id, project_name, project_description) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ project_name, project_description })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/clients/" + client_id 
        + "/projects" 

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // console.log(data)
            // project creation is successful if there's a project in the response
            if (data.project) {
                // add project to local storage
                localStorage.removeItem('project');
                localStorage.setItem('project', JSON.stringify(data.project));
            }
            return data.project;
        });
}

function updateProject(client_id, project_id, project_name, project_description) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ project_id, project_name, project_description })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid 
    + "/accounts/" + aid 
    + "/clients/" + client_id 
    + "/projects/" + project_id 

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // console.log(data)
            // project creation is successful if there's a project in the response
            if (data.project) {
                // replace project in local storage
                localStorage.removeItem('project');
                localStorage.setItem('project', JSON.stringify(data.project));
            }
            return data;
        });
}

function readProject(client_id, project_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" 
        + uid 
        + "/accounts/" 
        + aid 
        + "/clients/" 
        + client_id 
        + "/projects/" 
        + project_id

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            // replace project in local storage
            localStorage.removeItem('project');
            localStorage.setItem('project', JSON.stringify(data.project));
            return data;
        })
        .catch(error => {
            //console.log(error)
            localStorage.removeItem('project')
            router.push('/dashboards?error=' + error)
        });
}

function readProjects(client_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid 
    + "/accounts/" + aid 
    + "/clients/" + client_id 
    + "/projects" 

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // projects array in the response
            if (data.projects) {
                // store projects in local storage 
                localStorage.setItem('projects', JSON.stringify(data.projects));
            }

            return data.projects;
        });

}

function readClientProjects(client_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" 
        + uid 
        + "/accounts/" 
        + aid 
        + "/clients/" 
        + client_id
        + "/projects"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // projects array in the response
            if (data.projects) {
                // store projects in local storage 
                localStorage.setItem('projects', JSON.stringify(data.projects));
            }

            return data;
        });

}

function getActiveProject() {
    //console.log('getting active project')
    let project = JSON.parse(localStorage.getItem('project'))
    return  project
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

