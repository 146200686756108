<template>
  <div class="content">
    <div class="middled">
      <div class="bodyblock" v-show="loading" style="text-align:center;">Loading...</div>

      <div v-show="!loading" style="border-bottom:2px solid #333;padding:6px 0 0 0;margin:0 6px;">
        <div style="padding:6px 0;">
          <h2 style="margin-top: 0;">Start with a Template</h2>
          <p class="tiny">
          Click through these templates to get some ideas.
          Clone and customize them for your organization.
        </p>
        </div>  
      </div>      
      
      <div class="column" v-show="!loading">
        <div v-if="templates.length > 0">
          <div v-for="(scp, index) in templates" :key="index">
            <div class="row spacer">
              <div class="categorycard" v-on:click="gotoScorecard(scp.scorecard_id)">
                <div class="catname">
                  {{scp.scorecard_name}}
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div v-else>
          <p style="text-align:center;">No Templates.</p>
        </div>
      </div>      
        
        <div v-show="!loading" style="border-bottom:2px solid #333;padding:6px 0 0 0;margin:0 6px;">
          <div style="padding:6px 0;">
            <h2 style="margin-top: 0;">Create a Custom Board</h2>
            <p class="tiny">
              Create your own board and share it with your team.
            </p>
          </div>  
        </div>
        
        <div class="column">
          <a href="/new-scorecard">
            <div class="row spacer">
              <div class="categorycard">
                <div class="catname">
                  New Custom Board...
                </div>
              </div>
            </div>
          </a>
        </div>
  
        <div v-show="!loading" style="border-bottom:2px solid #333;padding:6px 0 0 0;margin:0 6px;">
          <div style="padding:6px 0;">
            <h2 v-show="showArchived" style="margin-top: 0;">
              Archived Boards
            </h2>
            <h2 v-show="!showArchived" style="margin-top: 0;">
              Active Boards
            </h2>
          </div>  
        </div>

        <div class="column" v-show="!loading" v-if="scorecards.length > 0">
          <div v-if="scorecards.length > 0">
            <div v-for="(scp, index) in scorecards" :key="index">
            <div class="row spacer" v-show="(showArchived && scp.deleted) || (!showArchived && !scp.deleted)">
              <div class="categorycard" v-on:click="gotoScorecard(scp.scorecard_id)">
                <div class="catname">
                  {{scp.scorecard_name}} <span class="tiny" v-show="scp.deleted">(Reactivate)</span>
                </div>
                <div class="nextarrow"><span v-show="scp.deleted">Archived</span></div>
              </div>
            </div>
          </div>

          <p style="text-align: right;">
            <a href="#" @click.prevent="showArchived = true" v-show="!showArchived">View Archived Boards</a>
            <a href="#" @click.prevent="showArchived = false" v-show="showArchived">Show Active Boards</a>
          </p>

        </div>

        <div v-else>
          <p style="text-align:center;">A Board is a smart, agile, living matrix of ideas, learning, and action items that helps you get focus, clarity and alignment.</p>
          <div class="action-panel">
            <a href="/new-scorecard">
              <button style="padding:10px 20px;"> Create a new Board </button>
            </a>
          </div>
        </div>



      </div>
    </div>
  </div>
</template>

<script>
import { router } from '../_helpers';
import { isLoggedIn } from "../utils/auth";
import { scorecardService } from "../_services/scorecard.service"

export default {
  title: 'Scorecards | Foclarity',
  name: "Scorecards",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  methods: {
    premiumFeature: (msg) => {
      alert(msg + " is a Premium Feature. Contact us to upgrade.")
    },
    isLoggedIn() {
      return isLoggedIn();
    },
    gotoScorecard: (q) => {
      if (q == 'marketing') {
        router.push("scorecards/1")
      } else if (q == 'horizons') {
        router.push("scorecards/248")
      } else if (q == 'sales') {
        router.push("scorecards/10")
      } else if (q == 'ideas') {
        router.push("scorecards/86")
      }
      else {
        // console.debug('going to ' + q)
        router.push("/scorecards/" + q)
      }
    },
    switchScorecard: (scorecard_id) => {
      scorecardService.switchScorecard(scorecard_id).then(router.push("scorecards/"+scorecard_id))
    },
    // eslint-disable-next-line no-unused-vars
    handleSubmit (e) {
        this.submitted = true;
        const { name } = this;

        // stop here if form is invalid
        if (!(name)) {
            return;
        }

        this.loading = true;
        scorecardService.createScorecard(name)
            .then(
                // eslint-disable-next-line no-unused-vars
                scorecard => router.push("dashboards"),
                error => {
                    this.error = error ;
                    this.loading = false ;
                    this.submitted = false ;
                }
            );
    }
  },
  components: {},
  data () {
      return {
        scorecards: [],
        templates: [],
        name: '',
        submitted: false,
        loading: true,
        returnUrl: '',
        error: '',
        lastlogin: '',
        showArchived: true
      }
  },
  created () {
    scorecardService.readPublicScorecards().then(data => {
      this.templates = data
      this.loading = false
    },
    error => {
      console.log(error)
    });
    scorecardService.readScorecards().then(data => {
      this.scorecards = data
      this.loading = false
    },
    error => {
      console.log(error)
    }
    );

    //userService.getMe()

  }
};
</script>

<style lang="scss" scoped>
.welcomeback {
  padding: 40px;
  text-align: center;
  border: 1px solid #ccc;
}

.heroblue {
  background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
  & a {
    text-decoration: none;
    color: #fff;
    & .signupbox {
      text-align: center;
      padding: 8px;
      font-size: 24px;
      font-weight: bold;
      background: #ff7f24;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: auto;
    }
  }
}

</style>

