<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
              Add a Task
              <a href="#" v-on:click.prevent="$emit('close')" style="float:right;">Cancel</a>
          </div>

          <div class="modal-body">
            <div>
                <div class="row" v-if="show_category_ddl">
                    <div class="column">
                        <div class="custom-select">
                            <select @change='setCategory($event)' v-model='category_id'>
                                <option value="0" disabled selected>Select...</option>
                                <option
                                v-for='(category, index) in categories'
                                :value='category.category_id'
                                :key='index'>
                                {{ category.category_name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="show_measure_ddl">
                    <div class="column">
                        <div class="custom-select">
                            <select @change='setMeasure($event)' v-model='measure_id'>
                                <option value="0" disabled selected>Select...</option>
                                <option
                                v-for='(measure, index) in measures'
                                :value='measure.measure_id'
                                :key='index'>
                                {{ measure.measure_name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row" v-show="!disable_input_field">
                  <div class="column">
                    <div class="custom-select">
                      <select v-model="assigned_user_id">
                        <option 
                          v-for="member in members" 
                          :key="member.member_id" 
                          :value="member.member_id"
                          :selected="member.member_id == uid"
                          >
                          {{member.member_first_name}} {{member.member_last_name}}
                        </option>
                      </select>  
                    </div>
                  </div>
                </div>
                <div class="row">
                    <div class="column" style="flex-grow:4;">
                    <input class="tdes" :disabled="disable_input_field" type="text" v-model="task_description" v-on:keyup.enter="maketask">
                    </div>
                    <div class="column" style="min-width:100px;padding-top:15px;">
                    <button class="btn-add" style="width:100px;" :disabled="disable_input_field" v-on:click="maketask">Add</button>
                    </div>
                </div>
                <div class="row tiny" v-show="false">
                    Strategy: {{ scorecard_id }}, Category: {{ category_id }}, Subcategory: {{ measure_id }}
                </div>
                <div class="row">
                    {{ error }}
                </div>
                </div>
          </div>

          <div class="modal-footer">
          </div>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>
import { accountService, scorecardService, taskService } from '../../_services' ;

export default {
  props: [
    'scid',
    'cid',
    'mid',
    'uid',
    'tasks'
  ],
  data () {
      return {
        scorecard_id: 0,
        category_id: 0,
        measure_id: 0,
        members: [],
        task_description: "",
        assigned_user_id: this.uid,
        show_category_ddl: false,
        show_measure_ddl: false,
        disable_input_field: false,
        error: '',
        categories: [],
        measures: []
      }
  },
  methods: {
    getScorecard: function () {
      this.show_category_ddl = false ;
      this.show_measure_ddl = false ;
      this.disable_input_field = true ;
      scorecardService.readScorecard(this.scid).then(
          data => {
            this.scorecard_id = data.scorecard.scorecard_id
            if (data.categories.length > 0) {
                this.categories = data.categories
                if (this.cid > 0) {
                  this.setCategory({target: {value: this.cid}})
                } else {
                    this.category_id = 0
                }
                this.show_category_ddl = true ;
            } else {
                this.category_id = 0
                alert("Add 3-5 Categories First")
            }
            this.show_measure_ddl = false ;
            this.disable_input_field = true ;
          }
      )
    },
    setCategory: function(event) {
        this.category_id = event.target.value
        scorecardService.switchCategory(this.scorecard_id,this.category_id).then(
            data => {
                this.category_id = data.category.category_id
                if (data.measures.length > 0) {
                    this.measures = data.measures
                    this.measure_id = 0
                    this.show_measure_ddl = true ;
                    //this.disable_input_field = false ;
                } else {
                    this.show_measure_ddl = false ;
                    this.disable_input_field = true ;
                    this.measure_id = 0
                    alert("You must have something to measure before you can add a task in this category.")
                }
            }
        )
    },
    setMeasure: function(event) {
        this.measure_id = event.target.value
        this.disable_input_field = false ;
    },
    getMembers: function () {
      accountService.readAccountMembers().then(
        data => {
          this.members = data.members
        }
      )
    },
    maketask: function () {
      taskService.createMeasureTask(this.scorecard_id, this.category_id, this.measure_id, this.task_description, false, this.assigned_user_id).then(
        data => {
          this.$emit('newTask')
          this.$emit('close')
          this.task_description = ""
          console.log('new task added ' + data.task.task_id)
        //   this.show_category_ddl = false ;
        //   this.show_measure_ddl = false ;
        //   this.disable_input_field = true ;
        }
      )
    },
  },
  components: {
  },
  created () {
      this.getScorecard()
      this.getMembers()
  }
};
</script>

<style>
.tdes:disabled {
  background: #eee;
}
.task {
  padding: 10px ;
}
.newtask {
  text-align: center ;
  padding: 40px 4px ;
  cursor: pointer ;
}

/* wrapper-div for including arrow */
.custom-select {
background-color: #eee;
float: left;
margin-right: 10px;
position: relative;
border:1px solid #ccc;
}

.custom-select select {
-moz-appearance: none;
-webkit-appearance: none;
appearance: none; /* remove default styling */
background-color: inherit;
border: none;
color: #333;
display: block;
font-size: 16px;
height: 32px;
width: 100%;
margin: 0;
outline: none;
}

/* drop arrow */
.custom-select:after {
content: '\25bc'; 
color: #AAA;
font-size: 12px;
position: absolute;
right: 8px;
top: 10px;
}

</style>