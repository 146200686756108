<template>
  <div class="content">
    <div class="middled">
      <div class="breadcrumbs">
          <router-link :to="{ name: 'dashboards'}">Dashboards</router-link>
      </div>
      <div class="row">
        <div class="column">
          <h1>Recieve Payments:</h1>
          <p style="border-bottom:1px solid #ccc;padding-bottom:20px;margin-bottom:20px;">
            How do you want to receive payments from clients? 
            These buttons will be added to your invoice so clients can pay you directly.
            When you use a third-party service like this you keep 100% of your payments.
          </p>
          
          <div v-if="account.wallets" style="border-bottom:1px solid #ccc;">
          <h2>Your Payment Methods:</h2>
            <div v-for="wallet in account.wallets" :key="wallet.wallet_type_id" class="row" style="align-items: center;">
              <div class="column">
                <p style="font-size:.8rem;">
                  <img v-if="wallet.wallet_type_id === 1" :venmo="gotWallet('venmo')" src="@/assets/venmo-icon.svg" alt="Venmo" width="30" style="vertical-align:middle;"> 
                  <img v-if="wallet.wallet_type_id === 2" :bitcoin="gotWallet('bitcoin')" src="@/assets/bitcoin-icon.svg" alt="Bitcoin" width="30" style="vertical-align:middle;"> 
                  <img v-if="wallet.wallet_type_id === 3" :cashapp="gotWallet('cashapp')" src="@/assets/cashapp-icon.svg" alt="Cash App" width="30" style="vertical-align:middle;"> 
                  {{ wallet.wallet_address }}
                </p>
              </div>
            </div>
          </div>

          <h2>Available Payment Methods:</h2>
          <div class="row" style="align-items: center;">
            <div class="column">
              <p v-show="!venmo" v-on:click="premiumFeature('Venmo Payment')" style="cursor:pointer;font-size:.8rem;">
                <img src="@/assets/venmo-icon.svg" alt="Venmo" width="30" style="vertical-align:middle;"> Venmo
              </p>
            </div>
          </div>

          <div class="row" style="align-items: center;">
            <div class="column">
              <p v-show="!bitcoin" v-on:click="premiumFeature('Bitcoin Payment')" style="cursor:pointer;font-size:.8rem;">
                <img src="@/assets/bitcoin-icon.svg" alt="Bitcoin" width="30" style="vertical-align:middle;"> Bitcoin address
              </p>
            </div>
          </div>

          <div class="row" style="border-bottom:1px solid #ccc;align-items: center;">
            <div class="column">
              <p v-show="!cashapp" v-on:click="premiumFeature('Cash App Payment')" style="cursor:pointer;font-size:.8rem;">
                <img src="@/assets/cashapp-icon.svg" alt="Cash App" width="30" style="vertical-align:middle;"> Cash App 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";
import { accountService } from '../_services';

export default {
  title: 'Payments | Foclarity',
  name: "Payments",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  data () {
      return {
        name: '',
        loading: true,
        account: '',
        returnUrl: '',
        error: '',
        lastlogin: '',
        venmo: false,
        bitcoin: false,
        cashapp: false
      }
  },
  methods: {
    premiumFeature: (msg) => {
      alert(msg + " is a Premium Feature. Contact us to upgrade.")
    },
    loadAccount: function () {
      accountService.switchAccount(this.$route.params.account_id).then(
        data => {
          // console.log(data)
          this.account = data
        }
      )
    },
    gotWallet: function (wallet) {
      if (wallet == 'venmo') { this.venmo = true }
      if (wallet == 'bitcoin') { this.bitcoin = true }
      if (wallet == 'cashapp') { this.cashapp = true }
    },
    isLoggedIn() {
      return isLoggedIn();
    }
  },
  components: {},
  created () {
    this.loadAccount()
  }
};
</script>

<style lang="scss" scoped>
.welcomeback {
  padding: 40px;
  text-align: center;
  border: 1px solid #ccc;
}

.heroblue {
  background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
  & a {
    text-decoration: none;
    color: #fff;
    & .signupbox {
      text-align: center;
      padding: 8px;
      font-size: 24px;
      font-weight: bold;
      background: #ff7f24;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: auto;
    }
  }
}

</style>

