<template>
  <div class="content bg8">
    <div class="middled">
      <div class="basehero">
        <h1>Privacy Statement</h1>
        <p>
          This privacy policy governs your use of the SaaS application Foclarity
          (“Application”) that was created by Custany LLC. The Application is
          technology to assist in understanding, measuring and increasing the
          impact of your activities on your business.
        </p>

        <h3>
          What information does the Application obtain and how is it used?
        </h3>
        <p><strong>User Provided Information</strong></p>

        <p>
          The Application obtains the information you provide when you register
          for the Application. Registration with us is optional. However, please
          keep in mind that you may not be able to use some of the features
          offered by the Application unless you register with us.
        </p>

        <p>
          When you register with us and use the Application, you generally
          provide (a) your name, email address, password and other registration
          information; (b) transaction-related information, such as when you
          make purchases, respond to any offers, or download or use applications
          from us; (c) information you provide us when you contact us for help;
          (d) credit card information for purchase and use of the Application,
          and; (e) information you enter into our system when using the
          Application, such as contact information and company information.
        </p>

        <p>
          We may also use the information you provided us to contact you from
          time to time to provide you with important information, required
          notices and marketing promotions.
        </p>

        <p><strong>Automatically Collected Information</strong></p>

        <p>
          In addition, the Application may collect certain information
          automatically, including, but not limited to, the type of mobile
          device you use, your mobile devices unique device ID, the IP address
          of your mobile device, your mobile operating system, the type of
          mobile Internet browsers you use, and information about the way you
          use the Application.
        </p>

        <h3>
          Does the Application collect precise real time location information of
          the device?
        </h3>
        <p>
          This Application does not collect precise information about the
          location of your mobile device.
        </p>

        <h3>
          Do third parties see and/or have access to information obtained by the
          Application?
        </h3>
        <p>
          Only aggregated, anonymized data is periodically transmitted to
          external services to help us improve the Application and our service.
          We will share your information with third parties only in the ways
          that are described in this privacy statement.
        </p>

        <p>
          We may disclose User Provided and Automatically Collected Information:
        </p>
        <p>
          as required by law, such as to comply with a subpoena, or similar
          legal process; when we believe in good faith that disclosure is
          necessary to protect our rights, protect your safety or the safety of
          others, investigate fraud, or respond to a government request;
        </p>
        <p>
          with our trusted services providers who work on our behalf, do not
          have an independent use of the information we disclose to them, and
          have agreed to adhere to the rules set forth in this privacy
          statement.
        </p>
        <p>
          if Custany LLC is involved in a merger, acquisition, or sale of all or
          a portion of its assets, you will be notified via email and/or a
          prominent notice on our Web site of any change in ownership or uses of
          this information, as well as any choices you may have regarding this
          information.
        </p>

        <h3>What are my opt-out rights?</h3>
        <p>
          You can stop all collection of information by the Application easily
          by uninstalling the Application. You may use the standard uninstall
          processes as may be available as part of your mobile device or via the
          mobile application marketplace or network. You can also request to
          opt-out via email, at info@foclarity.com.
        </p>

        <h3>Data Retention Policy, Managing Your Information</h3>

        <p>
          We will retain User Provided data for as long as you use the
          Application and for a reasonable time thereafter. We will retain
          Automatically Collected information for up to 24 months and thereafter
          may store it in aggregate. If you'd like us to delete User Provided
          Data that you have provided via the Application, please contact us at
          info@foclarity.com and we will respond in a reasonable time. Please note
          that some or all of the User Provided Data may be required in order
          for the Application to function properly.
        </p>

        <h3>Children</h3>

        <p>
          We do not use the Application to knowingly solicit data from or market
          to children under the age of 13. If a parent or guardian becomes aware
          that his or her child has provided us with information without their
          consent, he or she should contact us at info@foclarity.com. We will delete
          such information from our files within a reasonable time.
        </p>

        <h3>Security</h3>

        <p>
          We are concerned about safeguarding the confidentiality of your
          information. We provide physical, electronic, and procedural
          safeguards to protect information we process and maintain. For
          example, we limit access to this information to authorized employees
          and contractors who need to know that information in order to operate,
          develop or improve our Application. Please be aware that, although we
          endeavor provide reasonable security for information we process and
          maintain, no security system can prevent all potential security
          breaches.
        </p>

        <h3>Changes</h3>

        <p>
          This Privacy Policy may be updated from time to time for any reason.
          We will notify you of any changes to our Privacy Policy by posting the
          new Privacy Policy here and informing you via email or text message.
          You are advised to consult this Privacy Policy regularly for any
          changes, as continued use is deemed approval of all changes.
        </p>

        <h3>Your Consent</h3>

        <p>
          By using the Application, you are consenting to our processing of your
          information as set forth in this Privacy Policy now and as amended by
          us. "Processing,” means using cookies on a computer, hand held device
          or using or touching information in any way, including, but not
          limited to, collecting, storing, deleting, using, combining and
          disclosing information, all of which activities will take place in the
          United States. If you reside outside the United States your
          information will be transferred, processed and stored there under
          United States privacy standards.
        </p>

        <h3>Contact us</h3>

        <p>
          If you have any questions regarding privacy while using the
          Application, or have questions about our practices, please contact us
          via email at info@foclarity.com.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";

export default {
  title: "Privacy Statement | Foclarity",
  name: "Privacy",
  mounted: function () {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
  },
  components: {},
  data() {
    return {};
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.welcomeback {
  padding: 40px;
  text-align: center;
  border: 1px solid #ccc;
}

.heroblue {
  background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
  & a {
    text-decoration: none;
    color: #fff;
    & .signupbox {
      text-align: center;
      padding: 8px;
      font-size: 24px;
      font-weight: bold;
      background: #ff7f24;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: auto;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 10px;
  min-width: 200px;
  & .homecard {
    text-align: center;
    padding: 20px;
    border-radius: 4px;
    background: rgba(0, 178, 238, 0.1);
    height: 100%;
    & .cardicon {
      width: 100px;
      height: 100px;
      margin: auto;
      & img {
        width: 80px;
      }
    }
    & .h3 {
      font-size: 23px;
      line-height: 32px;
      color: #007cee;
      text-align: left;
      @media (max-width: 400px) {
        font-size: 20px;
      }
    }
  }
}

.homequote {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-style: italic;
  color: #666;
  max-width: 600px;
  margin: 20px auto;
}
</style>

