<template>
  <div class="content homebg">
    <div class="row" style="max-width:1200px;margin:auto;min-height: 810px;">
      <div class="homehero column" style="margin:auto;">
        <div class="marketline" style="margin:10px 0 0 5px;">
          <router-link to="/who-we-serve" style="color: #990099;">
            AI-Enhanced Training
          </router-link>
        </div>
        <h1 id="h1home" style="margin:50px 0 50px 0;">
          Get new hires up to speed—faster.
        </h1>
        <p style="font-weight: bold;margin-right:40px;">
          AI-enhanced employee training frameworks are the key to unlocking your team's potential.
          Whether you're managing interns, training new hires, or upskilling your team,
          Foclarity helps everyone learn critical skills necessary to achieve key outcomes.
        </p>
        <div class="signupfree" style="margin-top:40px;">
          <p>
            <router-link to="/contact">
              <button id="btn-home-executive">Schedule a Demo</button>
            </router-link>
            <router-link to="/who-we-serve">
              <button id="btn-home-consultant">Learn More</button>
            </router-link>
          </p>
        </div>
        <!-- use the modal component, pass in the prop -->
        <modal v-if="showModal" @close="showModal = false">
          <template v-slot:header>
            <h3>Empower your teams to achieve key outcomes—faster.</h3>
          </template>
          <template v-slot:body>
            <p>
              <strong>Focus</strong> on the most important challenges and opportunities.
            </p>
            <p>
              <strong>Agility</strong> across teams and functions.
            </p>
            <p>
              <strong>Clarity</strong> on the best path forward.
            </p>
            <p>
              <strong>Execution</strong> with speed and alignment.
            </p>
          </template>
          <template v-slot:footer>
            <p>FACE the future with AI-powered workforce agility software for teams.</p>
          </template>
        </modal>                
      </div>

      <div class="column" @click="showModal = true" style="margin:auto;">
        <div class="quotebubble">
          <img src="@/assets/marketers-working@2x.png" alt="Foclarity" width="100%">
        </div>
      </div>
      
    </div>

    <div class="panel max" style="background:#ddeeff;" v-show="false">
      <div class="row" style="margin:auto;">
        <div class="column" style="text-align:center;">
          <p>
            <img src="@/assets/foclarity-logo-black.png" width="300" alt="Foclarity">
          </p>
          <h2 style="color:#333;margin-top:0;">
            Focus &bull; Clarity &bull; Agility
          </h2>
        </div>
      </div>
    </div>

    <div style="text-align:center;background:#0099FF;margin:0px -20px;padding:20px 20px 2px;vertical-align:middle;color:#fff;">
      <p style="text-transform: uppercase; font-weight: bold;">
        Focus &bull; Clarity &bull; Alignment
      </p>
    </div>

    <div class="panel max fullbluelt">
      <div class="row middled" style="max-width: 1200px;">
        <div class="column" style="justify-content:center;max-width:800px;min-width:300px;margin:auto;">
          <div style="padding:20px;">
            <div class="marketline">
              <router-link to="/who-we-serve">
                Teams ❤️ Foclarity
              </router-link>
            </div>
            <h2 style="line-height:1.5em;">
              &ldquo;We leaned into Foclarity to help Simmitri Solar identify, track, and reach our most audacious scaling and revenue goals.
            The results came much faster than expected, not to mention our team communication across all departments improved greatly as a result.&rdquo;
            </h2>
            <p>
              Dan Jenkin<br>
              Executive VP, Simmitri Solar
            </p>
          </div>
        </div>
        <div class="column">
          <div class="inlinebubble2">
          </div>
        </div>
      </div>
    </div>

    <div style="text-align:center;background:#990099;margin:0px -20px;padding:20px 20px 4px;vertical-align:middle;color:#fff;">
      <p style="text-transform: uppercase; font-weight: bold;">
        ATTN: Incubators and Accelerators
      </p>
    </div>

    <div class="row middled" style="margin-top:60px;margin-bottom:30px;max-width: 1200px;">
      <div class="column" style="flex: 1 1 1;">
        <div class="inlinebubble1">
        </div>
      </div>
      <div class="column" style="flex: 2 1 1;text-align:left;padding:10px;vertical-align: top;position: relative;height: auto;">
        <div class="marketline">
          <router-link to="/incubators" style="color: #990099;">Special programs for startups!</router-link>
        </div>
        <p>
          Every startup passes through 4 stages when they launch, and even more as they scale.
          Foclarity helps you navigate each stage with AI-powered learning and frameworks.
        </p>
          Foclarity helps with:
        <ul class="bullets bene">
          <li><strong>Ideation:</strong> Customer discovery, market research, solution testing.</li>
          <li><strong>Prototyping:</strong> Building a minimum viable product (MVP), user interviews and testing, collecting feedback.</li>
          <li><strong>Planning:</strong> Business model development, go-to-market strategy, financial planning.</li>
          <li><strong>Fundraising:</strong> Pitch preparation, investor outreach, understanding and securing funding.</li>
        </ul>
        <div class="marketline" style="margin-top:20px;">
          <router-link to="/who-we-serve" style="color: #990099;">Scaling and Beyond</router-link>
        </div>
        <p>
          Pass through the 4 stages and you're ready to scale.
          That includes building a team, developing a culture, and growing your customer base.
          Foclarity helps you navigate each stage with AI-powered learning and frameworks for Marketing, Sales, Strategy, and more.
        </p>
        <p>
          <router-link to="/incubators" style="color: #990099;">
            Learn more about Foclarity for Incubators and Accelerators
          </router-link>
        </p>
      </div>
      <div v-show="false" class="column" style="flex: 2 1 1;text-align:left;padding:10px;vertical-align: top;position: relative;height: 200px;">
        <div class="marketline" style="text-align: center;">
          <router-link to="who-we-are">Challenges we hear from leaders:</router-link>
        </div>
        <transition name="q-fade">
          <div style="padding:20px;font-size: 28px;text-align: left;position: absolute;top:30%;border:1px solid #ccc;border-radius: 0 10px 10px 10px;" :key="pullquote">
            {{ pullquote }}
          </div>
        </transition>
      </div>
    </div>

    <div class="row middled" style="margin-bottom:10px;max-width: 1200px;">
      <div class="column">
        <div class="homecard">
          <div class="cardicon">
            <img :src="require('@/assets/ic_assets.png')" alt="Capture ideas" />
          </div>
          <div class="h3">
            IDEATION
          </div>
          <p class="gray">Learn how to develop ideas, do market research, and find first customers</p>
        </div>
      </div>
      <div class="column">
        <div class="homecard">
          <div class="cardicon">
            <img :src="require('@/assets/ic_goals.png')" alt="Score efforts" />
          </div>
          <div class="h3">
            PROTOTYPING
          </div>
          <p class="gray">Build a minimum viable product (MVP), conduct user testing and get feedback</p>
        </div>
      </div>
      <div class="column">
        <div class="homecard">
          <div class="cardicon">
            <img :src="require('@/assets/ic_measure.png')" alt="Create tasks" />
          </div>
          <div class="h3">
            PLANNING
          </div>
          <p class="gray">Develop a business model, go-to-market strategy, and financial plan</p>
        </div>
      </div>
      <div class="column">
        <div class="homecard">
          <div class="cardicon">
            <img :src="require('@/assets/ic_polls.png')" alt="Visualize progress" />
          </div>
          <div class="h3">
            FUNDRAISING
          </div>
          <p class="gray">Learn how to pitch, find the right investors, and securing funding</p>
        </div>
      </div>
    </div>

    <div class="panel max">
      <div class="row middled">
        <div class="column" style="padding:20px;text-align:center;">
          <h2 style="font-size:2rem;">
            Schedule a Demo
          </h2>
          <div class="row">
            <div class="column">
              <p>
                <span class="highlightyellow">See what Foclarity can do for you!</span>
              </p>
              <a href="/contact">
                  <button class="wide"> Contact Us </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";
import modal from "@/components/includes/InfoModal"
import { userService } from '../_services';

export default {
  title: 'AI-enhanced Training | Foclarity',
  name: "Home",
  mounted: function() {
    if (isLoggedIn()) {
      this.$router.push("dashboards");
    } else {
      // this.pollPerson() ;
      this.pollquote() ;
    }
    // window.setInterval(()=>{
    //   this.pollPerson();
    // }, 5000);
  },
  data () {
      return {
        showModal: false,
        submitted: false,
        email: '',
        error: '',
        showthis: false,
        personas: ['...',
        'lack of focus?',
        'lack of clarity?',
        'lack of alignment?',
        'focus and clarity.'],
        personas3: ['...',
        'Drive growth.',
        'Streamline operations.',
        'Improve alignment.',
        'Manage change.',
        'Get focus & clarity.'
        ],
        pullquote: '',
        personas2: ['...', 'Building a startup?', 'Raising money?', 'Finding customers?', 'Growing your business?', '...', 'Accelerate through the unknowns...', 'Get focus, clarity, and alignment.'],
        persona: 'Executives',
        head1: 'Slowed down by',
        looper: 0,
        qloop: 0,
        equotes: ['I’m falling short of my growth targets, what can I do?',
                  'Can I make better investment tradeoffs, within and across functions and products?',
                  'How do I get my executive team more focused on the biggest challenges and opportunities?',
                  'How do we better manage the growing set of demands, needs, and wants?',
                  'How do we refine our strategy and direction without undermining the foundation we have?',
                  'How can we get sales, marketing and engineering on the same page and on the same team?',
                  'How can we better segment our customer base, get better product-market fit and accelerate market traction?',
                  'How do I make sure we are spending time and resources on the right potential customers?',
                  'How can we overcome the many barriers to gaining the right champions and closing deals?',
                  'Is there a better way to position the company and ‘go to market’?',
                  'Is there a better business model to monetize the value we provide customers and partners?',
                  'How can we better scale our business, and better utilize scarce field sales, support and marketing resources?',
                  'Is there a way to go ‘deeper’ and ‘broader’, with fewer bigger deals and many more smaller deals?',
                  'How can we better communicate our strategy, solutions and plans to employees and key stakeholders?',
                  'Are we doing too much?']
      }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
    pollquote() {
      this.qloop ++ ;
      // first set pullquote to empty
      const first = this.equotes.shift();
      this.equotes = this.equotes.concat(first);
      this.pullquote = this.equotes[0];
      // set a timer to run this function again in 5 seconds
      setTimeout(this.pollquote, 5000);
    },
    pollPerson(){
      // count to 5 then stop
      this.looper ++ ;
      // first set persona to empty
      const first = this.personas.shift();
      this.personas = this.personas.concat(first);
      this.persona = ''

      // untype
      const typeEffectDown = () => {
        if (this.persona.length == 1) {
          this.persona = ""
          if (this.looper == 3) {
            this.head1 = 'Accelerate with'
          }
          setTimeout(this.pollPerson, 200);
        } else {
          this.persona = this.persona.slice(0, -1);
          setTimeout(typeEffectDown, 50);
        }
      }

      // type
      const typeEffect = () => {
        if (this.persona.length === this.personas[0].length) {
          if (this.looper == 4) {
            return ;
          }
          setTimeout(typeEffectDown, 2000);
        } else {
          if (this.persona.length < this.personas[0].length) {
          this.persona += this.personas[0].charAt(this.persona.length);
          setTimeout(typeEffect, 50);
          } 
        }
      }

      typeEffect();
    },
    analyzeWebsite: function() {
      this.submitted = true;
      this.error = 'Processing...'
      const { email } = this;

      // stop here if form is invalid
      if (!(email)) {
        this.submitted = false ;
        this.error = 'Please enter a work email'
        return;
      }

      userService.checksite(email)
          .then(
              // eslint-disable-next-line no-unused-vars
              message => {
                this.error = 'Check your email for details.' ;
                this.email = '' ;
              },
              error => {
                this.error = error ;
                this.submitted = false ;
                this.email = '' ;
              }
          );
    }

  },
  components: {
    modal
  },
  created () {
  }
};
</script>

<style lang="scss" scoped>
.homebg {
  // background: #fff url("~@/assets/homebg-gray@2x-80.jpg")no-repeat top right ; 
  background: #fff url("~@/assets/bg_lines.jpg")no-repeat top right ; 
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  @media only screen and (max-width: 440px) {
    background: #ffffff url("~@/assets/bg_lines.jpg") no-repeat top right;
    // background: #ffffff url("~@/assets/homebg-gray-80.jpg") top right no-repeat;
    background-position-y: 580px;
    background-position-x: -360px;
    background-size: 230%;
  }
}
.homehero {
  padding: 0 0 0 27px ;
  max-width: 1200px ;
  min-width: 300px;
  margin-left:auto;
  margin-right:auto;
  & h1 {
    margin:40px 0 70px 0;
    max-width: 600px ;
    //color:#CC0099;
  }
  & h2 {
    margin: 10px 0;
    font-family: 'Lato', Arial, Helvetica, sans-serif ;
    font-size: 1.8rem;
  }
  & a {
    text-decoration: none;
    & .signupbox {
      text-align: center;
      padding: 8px;
      background: #0066CC ;
      // background: #41495B;
      width: 250px;
      border-radius: 4px;
      color: #fff;
    }
  }
  @media only screen and (max-width: 440px) {
    & h1 {
      margin:20px 0 20px 0;
      font-size: 2rem ;
    }
    & h2 {
      margin: 20px 0;
      font-size: 1.5rem;
    }
    padding: 0px 8px ;
  }
}
.heroblue {
  background: #ffffff; //linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  min-height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #333;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
}
.marketline {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  // color:#990099;
  color: #0066CC;
  font-weight: 700;
}

.quotebubble {
  color:#fff;
  // border:10px solid #990099;
  // border:15px solid #f3bc45;
  // background:#fff url("~@/assets/home-screenshot.jpg") no-repeat top left;
  // background:#fff url("~@/assets/dylan-web.jpg") no-repeat top left;
  // background:#fff url("~@/assets/radar-web.jpg") no-repeat top left;
  // background:#000 url("~@/assets/dall-e-nyt-execs.png") no-repeat top left;
  // background:#000 url("~@/assets/circle-image-friends.jpg") no-repeat top left;
  // background:#fff url("~@/assets/foclarity-sample-dashboard2.jpg") no-repeat top center;
  // background:#fff url("~@/assets/dashboards-sample.png") no-repeat top center;
  // background-size:cover;
  padding:0px;
  border-radius:50%;
  // width:400px;
  // height:400px;
  text-align:center;
  margin: auto;
  @media (max-width: 415px) {
    // border:4px solid #990099;
    padding:20px 0px;
    margin: 0 auto 20px ;
    // border-radius:0%;
    // width:300px;
    // height:300px;
  }
}

.inlinebubble1 {
  color:#fff;
  border:10px solid #990099;
  // background:#000 url("~@/assets/girl-coached.jpg") no-repeat top left;
  background:#000 url("~@/assets/circle-image-friends.jpg") no-repeat top left;
  // background:#fff url("~@/assets/radar-web.jpg") no-repeat top left;
  // background:#000 url("~@/assets/dall-e-nyt-execs.png") no-repeat top left;
  background-size:cover;
  padding:40px;
  border-radius:50%;
  width:370px;
  height:370px;
  text-align:center;
  margin: 10px auto;
  @media (max-width: 415px) {
    border:4px solid #990099;
    padding:20px 10px;
    // border-radius:0%;
    width:300px;
    height:300px;
  }
}

.inlinebubble2 {
  color:#fff;
  border:10px solid #f3bc45;
  background:#000 url("~@/assets/simmitri-logo.png") no-repeat 50% 50%;
  background-size:80%;
  padding:40px;
  border-radius:50%;
  width:370px;
  height:370px;
  text-align:center;
  margin: 10px auto;
  @media (max-width: 415px) {
    border:4px solid #f3bc45;
    padding:20px 10px;
    // border-radius:0%;
    width:300px;
    height:300px;
  }
}

.announcement {
  background: #1da4e6;
  text-align: center;
  color: #fff;
  margin:-20px -20px 0 -20px;
  padding: 10px; 
}

.signupfree {
  max-width:600px;
  margin-bottom:10px;
  line-height:1.2em;
  text-align: left;
  & #btn-home-consultant {
    background: #fff;
    border:1px solid #990099;
    color:#990099;
    margin: 0 0 20px 0;
    padding:10px 20px;
  }
  & #btn-home-executive {
    background: #990099;
    border:1px solid #990099;
    margin: 0 20px 20px 0;
    padding:10px 20px;
  }
  @media (max-width: 415px) {
    margin-bottom:0px;
    text-align: center;
    & #btn-home-consultant {
      margin: 10px ;
      width:90%;
    }
    & #btn-home-demo {
      margin: 10px ;
      width:90%;
    }
    & #btn-home-executive {
      margin: 10px;
      width:90%;    
    }
  }

}

.subtype {
  padding-right: 5px;
  white-space: nowrap;
  color:#0066CC;
}

</style>

