<template>
  <div class="content bg8">
    <div class="middled">
      <div>{{ account.account_name }}</div>
      <div class="breadcrumbs">
          <router-link :to="{ name: 'dashboards'}">Dashboards</router-link>
          >
          <router-link :to="{ name: 'todos'}">Todos</router-link>
      </div>
      <div class="dashboard-content" v-show="!loading" v-if="todo">
        <div class="row">
          <div class="column">
            <h1>
                {{ todo.todo_description }}
            </h1>
            <div>
              <div style="font-size:.8rem;margin:8px 0;" >
                Updated: {{ todo.updated_at | moment("MMM DD, YYYY") }} | 
                Subtasks: {{ todos.length }} | <span v-on:click="markCompleted" style="cursor: pointer;">Mark Completed</span>
              </div>
            </div>
          </div>
        </div>
        <div>
            <h3>Subtasks:</h3>
            <div style="display: flex;">
              <input style="flex-grow:2" type="text" v-model="new_todo_description" placeholder="Enter a Subtask" v-on:keyup.enter="makechildtodo">
              <button v-on:click="makechildtodo" style="flex-grow:1;margin:4px;min-width:100px;">Save</button>
            </div>
            <div v-if="error">{{ error }}</div>

            <div v-show="todos.length" style="display: flex ; flex-direction: row ; flex-wrap: nowrap ; justify-content: space-between ; cursor:pointer;  margin:2px 0 0 0;padding:8px;background:#ccc;">
              <div style="flex: 1;">
                Urgent?
              </div>
              <div style="flex: 3;">
                To do
              </div>
              <div style="flex: 1; color:#676767;">
                Due by
              </div>
            </div>

            <div style="display: flex ; flex-direction: row ; flex-wrap: nowrap ; justify-content: space-between ; cursor:pointer;  margin:2px 0 0 0;padding:8px;background:#eee;" v-on:click="gotoTodo(td.todo_id)" v-for="td in todos" :key="td.todo_id">
                <div style="flex: 1;">
                  <img v-if="todo.urgent" style="width:20px;height:20px;cursor:pointer;" :src="require('@/assets/ic_star_on.png')" alt="star on" />
                  <img v-else style="width:20px;height:20px;cursor:pointer;" :src="require('@/assets/ic_star_off.png')" alt="star off" />
                </div>
                <div style="flex: 3;">
                  {{ td.todo_description }}
                </div>
                <div style="flex: 1; color:#676767;">
                  <span>{{ todo.due_by | moment("MMM DD, YYYY") }}</span>
                </div>
              </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";
import { router } from '../_helpers';
import { accountService, todoService, userService } from '../_services';

export default {
  title: "Todo",
  name: "Todo",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  data () {
    return {
        account: '',
        todo: '',
        todo_id: this.$route.params.todo_id,
        todos: [],
        submitted: false,
        loading: true,
        msg: '',
        error: '',
        new_parent_id: '',
        new_urgent: '',
        new_todo_description: '',
        new_important: '',
        new_estimated_hours: '',
        new_define_done: '',
        new_metrics: '',
        new_deliverables: '',
        new_due_by: '',
        new_done: ''
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
    construction: (d) => {
      alert(d)
    },
    activeAccount() {
      this.account = accountService.getActiveAccount() ;
      this.user = userService.getActiveUser() ;
    },
      getTodo: function () {
        this.loading = true ;
        todoService.readTodo(this.todo_id).then(
        data => {
          //console.log(data)
          this.todo = data.todo ;
          this.loading = false;
        }
        )
        .catch(error => {
            //console.debug(error)
            this.error = error ;
            this.loading = false ;
        });      
    },
      getTodos: function () {
        this.loading = true ;
        todoService.readTodosByParent(this.todo_id).then(
        data => {
          //console.log(data)
          this.todos = data.todos ;
          this.loading = false;
        }
        )
        .catch(error => {
            //console.debug(error)
            this.error = error ;
            this.loading = false ;
        });      
    },
      markCompleted: function() {
        if (this.todos.length > 0) {
          alert('This task has subtasks that must be completed first')
        } else {
          alert('You clicked to mark completed.')
        }
    },
    makechildtodo: function() {
        var parent_id = this.todo.todo_id
        var urgent = this.new_urgent
        var todo_description = this.new_todo_description
        var important = this.new_important
        var estimated_hours = this.new_estimated_hours
        var define_done = this.new_define_done
        var metrics = this.new_metrics
        var deliverables = this.new_deliverables
        var due_by = this.new_due_by
        var done = this.new_done
        console.log(todo_description)

        todoService.createTodo(parent_id, urgent, todo_description, important, estimated_hours, define_done, metrics, deliverables, due_by, done).then(
        data => {
          // console.log(data)
          this.todos.push(data.todo)
          this.new_todo_description = ""
        }
        )
        .catch(error => {
            //console.debug(error)
            this.error = error ;
            this.loading = false ;
        });      
      },
      gotoTodo: function (tid) {
        router.push({name: "todo", params: {todo_id: tid}})
        todoService.readTodo(tid).then(
        data => {
          //console.log(data)
          this.todo = data.todo ;
          this.loading = false;
        }
        )
        .catch(error => {
            //console.debug(error)
            this.error = error ;
            this.loading = false ;
        });      

        todoService.readTodosByParent(tid).then(
        data => {
          //console.log(data)
          this.todos = data.todos ;
          this.loading = false;
        }
        )
        .catch(error => {
            //console.debug(error)
            this.error = error ;
            this.loading = false ;
        });      
    }
  },
  components: {
  },
  created () {
    this.getTodo();
    this.getTodos();
    this.activeAccount() ;
  }
};
</script>

<style lang="scss" scoped>
</style>