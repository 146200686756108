<template>
  <div class="content">
    <div class="middled">
      <div>{{ account.account_name }}</div>
      <div class="breadcrumbs">
          <router-link class="small-hider" :to="{ name: 'dashboards'}">Dashboards</router-link>
          > <router-link class="small-hider" :to="{ name: 'scorecard', params: { scorecard_id: $route.params.scorecard_id }}">Categories</router-link>
          > <router-link :to="{ name: 'category', params: { scorecard_id: $route.params.scorecard_id, category_id: $route.params.category_id }}">Measures</router-link>
          > <router-link :to="{ name: 'measure', params: { scorecard_id: $route.params.scorecard_id, category_id: $route.params.category_id, measure_id: $route.params.measure_id }}">Benchmarks</router-link>
      </div>
      <div v-show="loading" style="text-align:center;">Loading...</div>
      <div v-show="!loading">
        <div class="row">
          <div class="column scorebox" style="border:0px;">
            <h1>
                {{ benchmark.measure_name }}
            </h1>
            <div class="benchmark">
                <div class="rank">
                  {{ benchmark.score }}
                </div>
                <div class="desc">
                  {{ benchmark.benchmark_description }}
                </div>
            </div>
          </div>
          <div class="column scorebox">
            <p>Save this score and any additional notes about your current status for reference.</p>
            <div class="register">
              <form @submit.prevent="handleSubmit">
                  <div class="form-grp">
                      <label for="snapshot_notes">Notes:</label>
                      <textarea v-model="snapshot_notes" name="snapshot_notes" />
                  </div>
                  <div class="form-group">
                      <button class="btn btn-primary" :disabled="loading">Save this Score</button>
                      <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  </div>
                  <div v-if="error" class="alert alert-danger">{{error}}</div>
              </form>        
            </div>
            <div style="text-align:right;">
              <router-link :to="{ name: 'measure', params: { scorecard_id: $route.params.scorecard_id, category_id: $route.params.category_id, measure_id: $route.params.measure_id }}">Cancel</router-link>
            </div>
          </div>
        </div>

        <div v-if="snapshots.length > 0">
          <h2>Past Scores:</h2>
          <div class="row" v-for="snapshot in snapshots" :key="snapshot.snapshot_id">
            <div class="column" style="border-top:1px solid #fff;background:#eefae9;">
                <div class="snapshot">
                  <div class="desc">
                      <div style="color:#6AA84F;font-weight:700;">{{ snapshot.created_at | moment("MMM DD, YYYY") }} score: {{ snapshot.rank_score }}</div> 
                      <div v-if="snapshot.snapshot_notes">
                        {{ snapshot.snapshot_notes }}
                      </div>
                      <div v-else>
                          {{ snapshot.benchmark_description }}
                      </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="error" class="alert alert-danger">{{error}}</div>
    </div>
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";
import { router } from '../_helpers';
import { snapshotService, scorecardService, accountService } from '../_services';
export default {
  title: 'Measure | Foclarity',
  name: "Measure",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  data () {
      return {
        scid: this.$route.params.scorecard_id,
        cid: this.$route.params.category_id,
        mid: this.$route.params.measure_id,
        bmid: this.$route.params.benchmark_id,
        benchmark: '',
        snapshot_notes: '',
        snapshots: [],
        submitted: false,
        loading: true,
        returnUrl: '',
        error: ''
      }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
    activeAccount() {
      this.account = accountService.getActiveAccount() ;
    },
    construction: () => {
      alert('You are still ahead of me... We are working on this feature. Stay tuned!')
    },
    getBenchmark: function () {
      scorecardService.getBenchmark(this.scid, this.cid, this.mid, this.bmid).then(
          data => {
            //console.debug(data)
            this.benchmark = data.benchmark
            this.loading = false
        },
        error => {
          this.error = error
          this.loading = false
        }
        )
    },
    getMeasureSnapshots: function () {
      snapshotService.readMeasureSnapshots(this.scid, this.cid, this.mid).then(
        data => {
          this.snapshots = data.snapshots
          this.loading = false
        },
        error => {
          //console.debug(error)
          this.error = error
          this.loading = false
        }
      )
    },
    saveSnapshot: function () {
    },
    // eslint-disable-next-line no-unused-vars
    handleSubmit (e) {
        this.submitted = true;
        this.loading = true;

        snapshotService.createSnapshot(this.scid, this.cid, this.mid, this.bmid, this.snapshot_notes).then(
        data => {
            if (data.status == 'failed') {
                this.loading = false ;
                this.error = 'failed to save snapshot' ;
            } else {
                // console.debug(data)
                this.loading = false
                router.push({ name: 'measure', params: {scorecard_id: this.scid, category_id: this.cid, measure_id: this.mid}})
            }
        },
        error => {
          this.error = error
          this.loading = false
        });
    }
  },
  components: {},
  created () {
    this.getBenchmark();
    this.getMeasureSnapshots();
    this.activeAccount() ;
  }
};
</script>

<style lang="scss" scoped>
.snapshot {
  font-weight: 400 ;
  font-size: 1.2rem;
  line-height: 1.75rem;
  letter-spacing: .5px;
}
.benchmark {
    text-align: left ;
    display: flex ;
    .rank {
      flex: none ;
      margin-right: 10px ;
      text-align: center ;
      font-size: 24px ;
      line-height: 46px ;
      width: 50px ;
      height: 50px ;
      border-radius: 50px ;
      border: 2px solid #ccc ;
    }
    .active {
      background: #0066CC ;
      color: #fff;
      border: 2px solid #0066CC ;
    }
    .desc {
        padding: 10px 0 ;
    }
}

.heroblue {
  background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
  & a {
    text-decoration: none;
    color: #fff;
    & .signupbox {
      text-align: center;
      padding: 8px;
      font-size: 24px;
      font-weight: bold;
      background: #ff7f24;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: auto;
    }
  }
}

</style>

