<template>
  <div class="content bg8">
    <div class="middled">
      <div class="basehero">
        <h1>Terms and Conditions</h1>
        <p>
          These terms and conditions govern your use of the SaaS application
          Foclarity (“Application”) that was created by CUSTANY LLC. The Application
          is technology to assist in managing, understanding, measuring and
          increasing the impact of your activities on your business. Review
          these Terms and Conditions ("Agreement") thoroughly. This Agreement is
          a legal agreement between you and CUSTANY, LLC. By clicking “I Agree,”
          indicating acceptance electronically, or by, installing, accessing or
          using the Services, you agree to this Agreement. If you do not agree
          to this Agreement, then you may not use Foclarity.
        </p>

        <h3>1. General Terms and Conditions</h3>
        <p>
          This Agreement describes the terms governing your use of the Foclarity
          Services. It includes Foclarity's
          <router-link to="/privacy">Privacy Statement</router-link>. You must
          be at least 18 years of age to use our Foclarity. By accessing or using
          Foclarity you agree that:
        </p>
        <ul>
          <li><p>You can form a binding contract with Foclarity;</p></li>
          <li>
            <p>
              You are not a person who is prohibited from receiving the Services
              under the laws of the United States or any other applicable
              jurisdiction; and
            </p>
          </li>
          <li>
            <p>
              You will comply with this Agreement and all applicable local,
              state, national, and international laws, rules, and regulations,
              including import and export regulations.
            </p>
          </li>
        </ul>

        <h3>2. YOUR RIGHTS TO USE THE SERVICES</h3>
        <p>
          <strong>2.1</strong> The Services are protected by copyright, trade
          secret, and other intellectual property laws. You are only granted the
          right to use the Services and only for the purposes described by
          CUSTANY. CUSTANY reserves all other rights in the Services. Until
          termination of this Agreement and as long as you meet any applicable
          payment obligations and comply with this Agreement, CUSTANY grants to
          you a personal, limited, nonexclusive, nontransferable right and
          license to use the Services.
        </p>
        <p>
          <strong>2.2</strong> You agree not to use, nor permit any third party
          to use, the Services in a manner that violates any applicable law,
          regulation or this Agreement. You agree you will not:
        </p>
        <ul>
          <li>
            <p>
              Provide access to or give any part of the Services to any third
              party.
            </p>
          </li>
          <li>
            <p>
              Reproduce, modify, copy, sell, trade, lease, rent or resell the
              Services.
            </p>
          </li>
          <li>
            <p>Decompile, disassemble, or reverse engineer the Services.</p>
          </li>
          <li>
            <p>
              Make the Services available on any file-sharing or application
              hosting service.
            </p>
          </li>
        </ul>

        <h3>3. PAYMENT</h3>
        <p>
          For Services offered on a payment or subscription basis, the following
          terms apply, unless CUSTANY notifies you otherwise in writing. This
          Agreement also incorporates payment terms provided to you on the
          website for the Services:
        </p>
        <ol>
          <li>
            <p>
              Payments will be billed to you in U.S. dollars, or other
              currencies which may be made available (plus any and all
              applicable taxes, including without limitation VAT, GST and SST),
              as shown in the product ordering and subscription terms, and your
              account will be debited when you subscribe and provide your
              payment information, unless stated otherwise in the program
              ordering or payment terms on the website for the Services.
            </p>
          </li>
          <li>
            <p>You must pay with one of the following:</p>
            <ul>
              <li><p>A valid credit card acceptable to CUSTANY;</p></li>
              <li><p>A valid debit card acceptable to CUSTANY;</p></li>
              <li>
                <p>
                  Sufficient funds in a checking or savings account to cover an
                  electronic debit of the payment due; or
                </p>
              </li>
              <li>
                <p>
                  By another payment option CUSTANY provides to you in writing.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              If your payment and registration information is not accurate,
              current, and complete and you do not notify us promptly when such
              information changes, we may suspend or terminate your account and
              refuse any use of the Services.
            </p>
          </li>
          <li>
            <p>
              If you do not notify us of updates to your payment method (e.g.,
              credit card expiration date), to avoid interruption of the
              Services, we may participate in programs supported by your card
              provider (e.g., updater services, recurring billing programs,
              etc.) to try to update your payment information, and you authorize
              us to continue billing your account with the updated information
              that we obtain.
            </p>
          </li>
          <li>
            <p>
              CUSTANY will automatically renew your monthly, quarterly, or
              annual Services at the then-current rates, unless the Services
              subscription is cancelled or terminated under this Agreement.
            </p>
          </li>
          <li>
            <p>
              Additional cancellation or renewal terms may be provided to you on
              the website for the Services.
            </p>
          </li>
        </ol>

        <h3>4. USE WITH YOUR MOBILE DEVICE</h3>
        <p>
          Use of these Services may be available through a compatible mobile
          device with Internet access. You agree that you are solely responsible
          for these requirements, including any applicable changes, updates and
          fees as well as the terms of your agreement with your mobile device
          and telecommunications provider.
        </p>

        <p>
          CUSTANY MAKES NO WARRANTIES OR REPRESENTATIONS OF ANY KIND, EXPRESS,
          STATUTORY OR IMPLIED AS TO:
        </p>
        <ol>
          <li>
            <p>
              THE AVAILABILITY OF TELECOMMUNICATION SERVICES FROM YOUR PROVIDER
              AND ACCESS TO THE SERVICES AT ANY TIME OR FROM ANY LOCATION;
            </p>
          </li>
          <li>
            <p>
              ANY LOSS, DAMAGE OR SECURITY INTRUSION OF THE TELECOMMUNICATION
              SERVICES; AND
            </p>
          </li>
          <li>
            <p>
              ANY DISCLOSURE OF INFORMATION TO THIRD PARTIES OR FAILURE TO
              TRANSMIT ANY DATA, COMMUNICATIONS OR SETTINGS CONNECTED WITH THE
              SERVICES.
            </p>
          </li>
        </ol>

        <h3>5. DATA PROCESSING AND PRIVACY</h3>
        <p>
          Data Processing and Privacy. (a) CUSTANY is the controller of the
          personal information it processes about you when you use the Services
          and (b) You acknowledge that CUSTANY will process your personal
          information as described in our
          <a href="https://www.foclarity.com/privacy">Privacy Statement</a>
          when you use our Services.
        </p>

        <h3>6. CONTENT AND USE OF THE SERVICES</h3>
        <p>
          <strong
            >6.1 Responsibility for Content and Use of the Services.</strong
          >
        </p>
        <ol>
          <li>
            <p>
              Content includes any data, information, materials, text, graphics,
              images, music, software, audio, video, works of authorship of any
              kind, that are uploaded, transmitted, posted, generated, stored or
              otherwise made available through the Services ("Content"), which
              will include without limitation any Content that account holders
              (including you) provide through your use of the Services. By
              making your Content available through your use of the Services,
              you grant CUSTANY a worldwide, royalty-free, non-exclusive license
              to host and use your Content. Archive your Content frequently. You
              are responsible for any lost or unrecoverable Content. You must
              provide all required and appropriate warnings, information and
              disclosures. CUSTANY is not responsible for any of your Content
              that you submit through the Services.
            </p>
          </li>
          <li>
            <p>
              You agree not to use, nor permit any third party to use, the
              Services to upload, post, distribute, link to, publish, reproduce,
              engage in, promote or transmit any of the following:
            </p>
          </li>
          <li>
            <p>
              Illegal, fraudulent, defamatory, obscene, pornographic, profane,
              threatening, abusive, hateful, harassing, offensive, inappropriate
              or objectionable information or communications of any kind,
              including without limitation conduct that is excessively violent,
              incites or threatens violence, encourages "flaming" others or
              criminal or civil liability under any local, state, federal or
              foreign law;
            </p>
          </li>
          <li>
            <p>
              Content that would impersonate someone else or falsely represent
              your identity or qualifications, or that may constitute a breach
              of any individual's privacy; is illegally unfair or deceptive, or
              creates a safety or health risk to an individual or the public;
            </p>
          </li>
          <li>
            <p>
              Except as permitted by CUSTANY in writing, investment
              opportunities, solicitations, chain letters, pyramid schemes,
              other unsolicited commercial communication or spamming or
              flooding;
            </p>
          </li>
          <li>
            <p>
              Virus, Trojan horse, worm or other disruptive or harmful software
              or data; and
            </p>
          </li>
          <li>
            <p>
              Any Content that you do not own or have the right to use without
              permission from the intellectual property rights owners thereof.
            </p>
          </li>
        </ol>

        <p>
          <strong>6.2 Restricted Use of the Services.</strong>
          You shall not, and shall not permit any users of the Services or any
          other party to, engage in, solicit, or promote any activity that is
          objectionable or may be illegal, violates the rights of others, is
          likely to cause notoriety, harm or damage to the reputation of CUSTANY
          or could subject CUSTANY to liability to third parties, including: (i)
          unauthorized access, monitoring, interference with, or use of the
          Services or third party accounts, data, computers, systems or
          networks; (ii) interference with others' use of the Services or any
          system or network, including mail bombing, broadcast or denial of
          service attacks; (iii) unauthorized collection or use of personal or
          confidential information, including phishing, pharming, spidering, and
          harvesting; (iv) viewing or other use of any Content that, in
          CUSTANY's opinion, is prohibited under this Agreement; (v) any other
          activity that places CUSTANY in the position of fostering, or having
          potential or actual liability for, illegal activity in any
          jurisdiction; or (vi) attempting to probe, scan, penetrate or test the
          vulnerability of an CUSTANY system or network or to breach CUSTANY's
          security or authentication measures, whether by passive or intrusive
          techniques. CUSTANY reserves the right to not authorize and may
          terminate your use of the Services based on reasonable suspicion of
          your activities, business, products or services that are objectionable
          or promote, support or engage in any of the restricted uses described
          above.
        </p>
        <p>
          <strong>6.3 Community forums.</strong> The Services may include a
          community forum or other social features to exchange Content and
          information with other users of the Services and the public. CUSTANY
          does not support and is not responsible for the Content in these
          community forums. Please use respect when you interact with other
          users. Do not reveal information that you do not want to make public.
          Users may post hypertext links to content of third parties for which
          CUSTANY is not responsible.
        </p>

        <p>
          <strong>6.4 CUSTANY may freely use feedback you provide.</strong> You
          agree that CUSTANY may use your feedback, suggestions, or ideas in any
          way, including in future modifications of the Services, other products
          or services, advertising or marketing materials. You grant CUSTANY a
          perpetual, worldwide, fully transferable, sublicensable,
          non-revocable, fully paid-up, royalty free license to use the feedback
          you provide to CUSTANY in any way.
        </p>

        <p>
          <strong>6.5 CUSTANY may monitor Content. </strong>CUSTANY may, but has
          no obligation to, monitor access to or use of the Services or Content
          or to review or edit any Content for the purpose of operating the
          Services, to ensure compliance with this Agreement, and to comply with
          applicable law or other legal requirements. We may disclose any
          information necessary to satisfy our legal obligations, protect
          CUSTANY or its customers, or operate the Services properly. CUSTANY,
          in its sole discretion, may refuse to post, remove, or refuse to
          remove, or disable any Content, in whole or in part, that is alleged
          to be, or that we consider to be unacceptable, undesirable,
          inappropriate, or in violation of this Agreement.
        </p>

        <h3>7. ADDITIONAL TERMS</h3>

        <p>
          <strong>7.1 CUSTANY does not give professional advice.</strong> Unless
          specifically included with the Services, CUSTANY is not in the
          business of providing legal, financial, accounting, tax, health care,
          real estate or other professional services or advice. Consult the
          services of a competent professional when you need this type of
          assistance.
        </p>

        <p>
          <strong>7.2 We may tell you about other CUSTANY Services.</strong> You
          may be offered other services, products, or promotions by CUSTANY
          ("CUSTANY Services"). Additional terms and conditions and fees may
          apply. With some CUSTANY Services you may upload or enter data from
          your account(s) such as names, addresses and phone numbers, purchases,
          etc., to the Internet.
        </p>
        <p>
          <strong>7.3 Communications.</strong> CUSTANY may be required by law to
          send you communications about the Services or third party products.
          You agree that CUSTANY may send these communications to you via email
          or by posting them on our websites
        </p>

        <p>
          <strong
            >7.4 You will manage your passwords and accept updates.</strong
          >
          You are responsible for securely managing your password(s) for the
          Services and to contact CUSTANY if you become aware of any
          unauthorized access to your account. The Services may periodically be
          updated with tools, utilities, improvements, third party applications,
          or general updates to improve the Services. You agree to receive these
          updates.
        </p>

        <h3>8. DISCLAIMER OF WARRANTIES</h3>

        <p>
          <strong>8.1</strong> YOUR USE OF THE SERVICES, SOFTWARE, AND CONTENT
          IS ENTIRELY AT YOUR OWN RISK. EXCEPT AS DESCRIBED IN THIS AGREEMENT,
          THE SERVICES ARE PROVIDED "AS IS." TO THE MAXIMUM EXTENT PERMITTED BY
          APPLICABLE LAW, CUSTANY, ITS AFFILIATES, AND ITS AND THEIR THIRD PARTY
          PROVIDERS, LICENSORS, DISTRIBUTORS OR SUPPLIERS (COLLECTIVELY,
          "SUPPLIERS") DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING
          ANY WARRANTY THAT THE SERVICES ARE FIT FOR A PARTICULAR PURPOSE,
          TITLE, MERCHANTABILITY, DATA LOSS, NON-INTERFERENCE WITH OR
          NON-INFRINGEMENT OF ANY INTELLECTUAL PROPERTY RIGHTS, OR THE ACCURACY,
          RELIABILITY, QUALITY OF CONTENT IN OR LINKED TO THE SERVICES. CUSTANY
          AND ITS AFFILIATES AND SUPPLIERS DO NOT WARRANT THAT THE SERVICES ARE
          SECURE, FREE FROM BUGS, VIRUSES, INTERRUPTION, ERRORS, THEFT OR
          DESTRUCTION. IF THE EXCLUSIONS FOR IMPLIED WARRANTIES DO NOT APPLY TO
          YOU, ANY IMPLIED WARRANTIES ARE LIMITED TO 60 DAYS FROM THE DATE OF
          PURCHASE OR DELIVERY OF THE SERVICES, WHICHEVER IS SOONER.
        </p>

        <p>
          <strong>8.2</strong> CUSTANY, ITS AFFILIATES AND SUPPLIERS DISCLAIM
          ANY REPRESENTATIONS OR WARRANTIES THAT YOUR USE OF THE SERVICES WILL
          SATISFY OR ENSURE COMPLIANCE WITH ANY LEGAL OBLIGATIONS OR LAWS OR
          REGULATIONS.
        </p>

        <h3>9. LIMITATION OF LIABILITY AND INDEMNITY.</h3>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE ENTIRE
          LIABILITY OF CUSTANY, ITS AFFILIATES AND SUPPLIERS FOR ALL CLAIMS
          RELATING TO THIS AGREEMENT SHALL BE LIMITED TO THE AMOUNT YOU PAID FOR
          THE SERVICES DURING THE TWELVE (12) MONTHS PRIOR TO SUCH CLAIM.
          SUBJECT TO APPLICABLE LAW, CUSTANY, ITS AFFILIATES AND SUPPLIERS ARE
          NOT LIABLE FOR ANY OF THE FOLLOWING: (A) INDIRECT, SPECIAL,
          INCIDENTAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES; (B) DAMAGES
          RELATING TO FAILURES OF TELECOMMUNICATIONS, THE INTERNET, ELECTRONIC
          COMMUNICATIONS, CORRUPTION, SECURITY, LOSS OR THEFT OF DATA, VIRUSES,
          SPYWARE, LOSS OF BUSINESS, REVENUE, PROFITS OR INVESTMENT, OR USE OF
          SOFTWARE OR HARDWARE THAT DOES NOT MEET CUSTANY SYSTEMS REQUIREMENTS.
          THE ABOVE LIMITATIONS APPLY EVEN IF CUSTANY AND ITS AFFILIATES AND
          SUPPLIERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS
          AGREEMENT SETS FORTH THE ENTIRE LIABILITY OF CUSTANY, ITS AFFILIATES
          AND YOUR EXCLUSIVE REMEDY WITH RESPECT TO THE SERVICES AND ITS USE.
        </p>

        <p>
          You agree to indemnify and hold CUSTANY and its Affiliates and
          Suppliers harmless from any and all claims, liability and expenses,
          including reasonable attorneys' fees and costs, arising out of your
          use of the Services or breach of this Agreement (collectively referred
          to as "Claims"). CUSTANY reserves the right, in its sole discretion
          and at its own expense, to assume the exclusive defense and control of
          any Claims. You agree to reasonably cooperate as requested by CUSTANY
          in the defense of any Claims.
        </p>

        <h3>10. CHANGES.</h3>
        <p>
          We reserve the right to modify this Agreement, in our sole discretion,
          at any time, and the modifications will be effective when posted
          through the Services or on our website for the Services or when we
          notify you by other means. We may also change or discontinue the
          Services, in whole or in part. It is important that you review this
          Agreement whenever we modify it because your continued use of the
          Services indicates your agreement to the modifications.
        </p>

        <h3>11. TERMINATION.</h3>
        <p>
          CUSTANY may, in its sole discretion and without notice, restrict,
          deny, terminate this Agreement or suspend the Services, related or
          other Services, effective immediately, in whole or in part, if we
          determine that your use of the Services violates the Agreement, is
          improper or substantially exceeds or differs from normal use by other
          users, raises suspicion of fraud, misuse, security concern, illegal
          activity or unauthorized access issues, to protect the integrity or
          availability of the Services or systems and comply with applicable
          CUSTANY policy, if you no longer agree to receive electronic
          communications, or if your use of the Services conflicts with
          CUSTANY's interests or those of another user of the Services. Upon
          CUSTANY notice that your use of the Services has been terminated you
          must immediately stop using the Services and any outstanding payments
          will become due. Any termination of this Agreement shall not affect
          CUSTANY's rights to any payments due to it. CUSTANY may terminate a
          free account at any time. Sections 2.2, 3 through 15 will survive and
          remain in effect even if the Agreement is terminated.
        </p>

        <h3>12. EXPORT AND TRADE RESTRICTIONS.</h3>
        <p>
          You acknowledge that the Services and the underlying software may
          include U.S. technical data subject to restrictions under export
          control laws and regulations administered by the United States
          government. You agree that you and your personnel who use the Services
          are not prohibited from receiving the Services under the laws of the
          United States, England, Wales, or other applicable jurisdiction. For
          example, you are not on the U.S. Treasury Department's list of
          Specially Designated Nationals or the United Kingdom HM Treasury
          Financial Sanctions List, or are subject to any other similar
          prohibition. You will not export or re-export any part of the
          Services, in violation of these laws and regulations, directly or
          indirectly.
        </p>

        <h3>13. GOVERNING LAW AND JURISDICTION.</h3>
        <p>
          This Agreement will be governed by the laws of the state of Delaware
          in the United States. Notwithstanding the foregoing, you acknowledge
          that your breach (or an apprehension of breach) of any provisions of
          this Agreement, or any infringement (or apprehension of infringement)
          of CUSTANY's or its Suppliers' intellectual property rights may cause
          CUSTANY irreparable damage for which recovery of money damages would
          be inadequate. Accordingly, you agree that CUSTANY shall be entitled,
          in addition to any other remedies available to it, to seek (in any
          court of competent jurisdiction, notwithstanding the previous
          sentence) any relief (whether equitable or otherwise) to prevent or
          restrain any such breach or apprehended breach by you or otherwise to
          protect CUSTANY's rights under this Agreement. The parties hereby
          submit to the exclusive jurisdiction of the courts of the United
          States and you agree that you will procure that any third party making
          a claim against CUSTANY arising out of this Agreement shall bring such
          claim exclusively in the United States courts and subject to the
          limitations and exclusions of liabilities provided for in this
          Agreement.
        </p>
        <p>
          CUSTANY does not represent that the Services and/or content within the
          Services is appropriate or available for use in all jurisdictions or
          countries. CUSTANY prohibits accessing content from within countries
          or states where such content is illegal. You are responsible for
          compliance with all applicable laws pertaining to your use and access
          to the Services in your jurisdiction.
        </p>

        <h3>14. LANGUAGE.</h3>
        <p>
          Any translation of this Agreement is done for local requirements and
          in the event of a dispute between the English and any non-English
          version, the English version of this Agreement shall govern. In the
          event of a dispute the parties confirm that they have requested that
          this Agreement and all related documents be drafted in English.
        </p>

        <h3>15. GENERAL.</h3>
        <p>
          This Agreement, including the Additional Terms below, is the entire
          agreement between you and CUSTANY regarding its subject matter and
          replaces all prior understandings, communications and agreements, oral
          or written, regarding its subject matter. If any court of law, having
          the jurisdiction, rules that any part of this Agreement is invalid,
          that section will be removed without affecting the remainder of the
          Agreement. The remaining terms will be valid and enforceable. The
          United Nations Convention on Contracts for the International Sale of
          Goods does not apply to this Agreement. You may not assign or transfer
          this Agreement to anyone without written approval of CUSTANY. However,
          CUSTANY may assign or transfer this Agreement without your consent to
          (a) an affiliate, (b) a company through a sale of assets by CUSTANY or
          (c) a successor by merger. Any assignment in violation of this Section
          shall be void. If you want to request a transfer of this Agreement,
          contact CUSTANY via an email to: info@custany.com.
        </p>

        <h3>ADDITIONAL TERMS AND CONDITIONS FOR THE SERVICES</h3>
        <p>
          Your use of the Services provided by CUSTANY are subject to the
          general Terms of Service above including these Additional Terms and
          Conditions which govern your use of the Services indicated below. To
          the extent of any conflict or inconsistency with the general Terms of
          Service above, these Additional Terms and Conditions shall prevail.
        </p>

        <h3>Foclarity Supplemental Agreement and Terms of Service</h3>
        <p>
          Thank you for selecting Foclarity. This licence agreement in addition to
          the CUSTANY Terms of Service (the “Agreement”) is a legal agreement
          between the User, a single legal entity identified in the registration
          process provided as part of the start-up process (“User”, “you”), and
          CUSTANY Inc. and/or its subsidiaries and affiliates (“CUSTANY,” “we”,
          “our” or “us”).
        </p>
        <p>
          By selecting “ACCEPT,” You indicate that you have read and understood,
          and assent to be bound by, the terms of this Agreement. If the person
          selecting “ACCEPT” is an individual working for you (“Agent”), such
          person is agreeing to the terms and conditions of this Agreement on
          behalf of you and represents and warrants to CUSTANY that he/she has
          full power and authority to enter into this Agreement on your behalf.
        </p>
        <p>
          If you do not agree to the terms of the Agreement, you are not granted
          any rights whatsoever in the Services. If you are not willing to be
          bound by these terms and conditions, you should not select “ACCEPT,”
          and may not access or otherwise use the Foclarity Services.
        </p>

        <h3>1. SERVICES.</h3>
        <p>
          Each of the following products and services are referred to in this
          Agreement as a “Service” and together as the “Services”:
        </p>
        <p>
          <strong>1.1.</strong> Foclarity is an online solution for freelancers,
          consultants, contractors, professional service providers and other
          small businesses to assist in managing, understanding, measuring and
          increasing the impact of your activities on your business through an
          online account. Each Foclarity Account may only be used to support one
          business.
        </p>
        <p>
          <strong>1.2.</strong> Modification to Services. We have the right, in
          our sole discretion, to revise, update, or otherwise modify the
          Services or alter your access to the Services; and for material
          changes, to the extent reasonably possible, we will provide you with
          reasonable notice either posted on the website hosting the Services or
          to the Administrator's (as defined below) email address. Note that we
          may modify the amount of storage space you have through the Services
          and the number of times (and the maximum duration for which) you may
          access the Services in a given period of time. In the event we need to
          maintain the security of the system or comply with any laws or
          regulations, we reserve the right to modify the Services immediately,
          and to provide the Administrator with electronic or written notice
          within thirty (30) days after any material modifications. You may
          reject any changes to the Services by discontinuing use of the
          Services to which such changes relate. Your continued use of the
          Services will constitute your acceptance of and agreement to such
          changes.
        </p>

        <h3>2. SUBSCRIPTIONS AND USERS</h3>
        <p>
          The Foclarity Service is licenced on a monthly or yearly subscription
          basis, as selected by you or your agent as the user.
        </p>

        <h3>Additional Payment Terms.</h3>
        <p>
          Under this Agreement, the payment processing services for goods and/or
          services purchased on this website are provided by CUSTANY, LLC.
        </p>

        <h3>3. TRIAL VERSION AND BETA FEATURES</h3>
        <p>
          If you registered for a trial use of the Foclarity Service ("Trial
          Period"), you must decide to purchase a license to the Foclarity Service,
          at the current rate, within the Trial Period in order to retain any
          Content that you have entered through the Foclarity Services, created
          within the data file, posted or uploaded during the Trial Period. If
          you do not purchase a license to the Foclarity Services by the end of the
          Trial Period, your Content will no longer be available to you. To be
          very clear, after using the Foclarity Service during the trial period, if
          you decide not to purchase the license to the full version of the
          Foclarity Service, you will not be able to access or retrieve any of the
          data you added or created with the Services during the trial.
        </p>
        <p>
          From time to time, CUSTANY may, at its sole discretion, include new
          and/or updated beta features (“Beta Features”) in the Foclarity Service
          for your use and which permit you to provide feedback (fees may
          apply). You understand and agree that your use of the Beta Features is
          voluntary and CUSTANY is not obligated to provide you with any Beta
          Features. You understand that once you use the Beta Features, you may
          be unable to revert back to the earlier non-beta version of the same
          or similar feature. Additionally, if such reversion is possible, you
          may not be able to return or restore data created within the Beta
          Feature back to the earlier non-beta version. The Beta Features are
          provided on an “as is” basis and may contain errors or inaccuracies
          that could cause failures, corruption or loss of data and/or
          information from any connected device. You acknowledge and agree that
          all use of the Beta Features is at your sole risk.
        </p>

        <h3>4.PERMITTED DISCLOSURES AND USE OF DATA.</h3>
        <p>
          You acknowledge and agree that in order to provide you with access to
          and use of the Foclarity Services, CUSTANY may provide your access
          information and account data to (i) your employee or agent who is
          identified in the registration data as the current system
          administrator for your account (the “Current Administrator”), and (ii)
          such other employee or agent who may be designated by you as a
          replacement administrator for the your account by following the
          procedures required by CUSTANY to effectuate such replacement. Any
          other person You identified as an authorized user of the Foclarity
          Services will have access to the account data subject to the access
          permissions you or the system administrator assigned to them.
        </p>

        <h3>5. PERSONAL INFORMATION.</h3>
        <p>
          <strong>5.1</strong> The terms "Controller," "Processor," "data
          subject," "personal data (also referred to as Personal Information in
          the Agreement)" and "processing" (and "process") shall have the
          meanings given in the Regulation 2016/679 of the European Parliament
          and of the Council on the protection of natural persons with regard to
          the Processing of Personal Data and on the free movement of such data
          (General Data Protection Regulation).
        </p>

        <p><strong>5.2 Our Role.</strong> For Foclarity users accessing the Services from
              the European Economic Area (“EEA”), we are a Controller of the
              Personal Information you provide through the Services.
            </p>

        <p><strong>5.3 Personal Information.</strong></p>
        <p>You represent and warrant to us that:</p>
        <ul>
          <li>
            <p>
              <strong>5.3.1</strong> You will provide appropriate notice and
              have obtained (or will obtain) all consents and rights necessary
              for us to Process the Personal Information in accordance with this
              Agreement and our
              <a href="https://www.foclarity.com/privacy">Privacy Statement</a>;
            </p>
          </li>
          <li>
            <p>
              <strong>5.3.2</strong> If you are providing Personal Information
              to us that is not personal to you, you agree that you have either
              provided the data subject notice or received permission from the
              data subject and have the appropriate legal basis, as required by
              applicable law, for us to: (a) use, and/or disclose the personal
              information in accordance with our
              <a href="https://www.foclarity.com/privacy">Privacy Statement</a>, (b)
              move the data outside of the country of residence of such owner of
              the personal data, if applicable, pursuant to our Privacy
              Statement, (c) provide the Personal Information to Third Party
              Products that you approve, and (d) otherwise use and disclose the
              personal information in accordance with this Agreement; and
            </p>
          </li>
          <li>
            <p>
              <strong>5.3.3</strong> If there is any discrepancy between this
              Agreement and the CUSTANY Privacy Statement with respect the
              collection, use, and/or disclosure of the personal information,
              the Privacy Statement will control. You further acknowledge and
              agree that CUSTANY may provide data in your account to any
              Additional Users to which that data is applicable or personal to.
            </p>
          </li>
        </ul>

        <p><strong>5.4 Public Content.</strong></p>
        <p>
          As a User you may have the opportunity to share your data, Content, or
          ways in which you aggregate data (“<strong>Account Content</strong>”)
          with other Users, other CUSTANY customers, and other third parties.
          When sharing any Account Content, you agree not to share any
          confidential information. If you have the option of accessing another
          User's Account Content, you understand and agree that the Account
          Content is being provided by the User, and not CUSTANY, for
          information and guidance purposes only, and CUSTANY and such User are
          not responsible in any way for your use the Account Content.
        </p>
        <p><strong>5.5 Telephone numbers.</strong></p>
        <p>
          You may provide us with your telephone number as part of your customer
          record or registration or via other methods. You understand and agree
          that CUSTANY may use your telephone number for "multi-factor
          authentication” (“MFA"), to confirm your identity and help protect the
          security of your account. Part of the MFA identity verification
          process may involve CUSTANY sending text messages containing security
          codes to your telephone number. You agree to receive these texts from
          CUSTANY containing security codes as part of the MFA process. In
          addition, you agree that CUSTANY may send automated text messages and
          pre-recorded voice messages to the telephone number you provide for
          other limited purposes, including: providing you with important
          critical notices regarding your use of the Services, or fulfilling a
          request made by you through the Services. Additionally, CUSTANY may
          use your telephone number to contact you about special offers or other
          CUSTANY or third party products or services unless you opt out of such
          marketing. With MFA, you also agree and consent to us obtaining and
          using information from your mobile phone service provider, solely for
          the purposes of verifying your identity and to compare information you
          have provided to CUSTANY (such information may include from your
          mobile phone service provider account record: your name, address,
          email, customer type, customer role, billing type, mobile device
          identifiers (IMSI and IMEI) and other mobile phone subscriber
          details).
        </p>

        <h3>6. ACCESS TO YOUR FINANCIAL INSTITUTION SERVICES DATA.</h3>
        <p>
          <strong>6.1. General.</strong> In connection with your use of the Foclarity Service and as
          part of the functionality of certain versions of the Foclarity Services,
          you may wish to have access to your online account(s) and financial
          information, including your account access number(s), password(s),
          security question(s) and answer(s), account number(s), login
          information, and any other security or access information used to
          access your financial institution(s) (collectively, your “FI Login
          Data”) and the data made available by your financial institutions(s)
          with such data, which may include bank balances, transaction activity,
          credit card charges, debits and deposits, and any messages or notices
          between you and the financial institution(s) (“FI Account Data”). The
          Foclarity Services are designed to allow you to access and download your
          FI Account Data through the Foclarity Services, to allow CUSTANY to access
          your financial institution account(s) using your FI Login Data, to
          allow CUSTANY to download and use your FI Account Data, and to allow
          CUSTANY to aggregate and combine your FI Account Data with other data.
          If you lose or forget your user name or password, it will be necessary
          for you to return to the appropriate financial institution if you have
          any problems with respect to that user name or password.
        </p>
        <p>
          You acknowledge and agree that except as set forth this Agreement,
          CUSTANY has no control over your FI Login Data and no control over the
          access to your FI Account Data, does not guarantee that you will be
          able to use the Foclarity Service with your financial institution(s), and
          will have no liability whatsoever for any actions or inactions on the
          part of the financial institution(s) resulting in your inability to
          use the Foclarity Service to access your accounts, obtain data, download
          transactions, or otherwise use or access your FI Account Data.
        </p>

        <p>
          <strong>6.2 Collection of FI Login Data and FI Account Data.</strong> By agreeing to
          these terms and conditions, you:
        </p>
        <ul>
          <li>
            <p>
              6.2.1 acknowledge that in accessing your financial institution
              account(s) through the Foclarity Service, your FI Login Data and FI
              Account Data may be collected, converted, stored in encrypted form
              and used by CUSTANY in the United States for the purpose of
              providing the Foclarity Service;
            </p>
          </li>
          <li>
            <p>
              6.2.2 authorize CUSTANY to (i) collect and store in encrypted form
              your FI Login Data, (ii) access the financial institution(s)'
              websites using your FI Login Data, from time to time; (iii)
              download and store your FI Account Data; (iv) reformat and
              manipulate your FI Account Data; (v) create and provide hypertext
              links to your financial institution(s) FI Account Data; (vi)
              enhance the type of data and services we can provide to you in the
              future, and (vii) take such other actions as are reasonably
              necessary to perform the actions described in this Agreement as it
              relates to your use of the Foclarity Service;
            </p>
          </li>
          <li>
            <p>
              6.2.3 hereby represent that the financial institution(s)'
              account(s) and FI Login Data belong to you, you have the right to
              use the FI Login Data and FI Account Data as set out above and
              that you have the authority to appoint, and hereby expressly do
              appoint, CUSTANY as your agent with all necessary power and
              authority to use your FI Login Data and to access and retrieve
              your FI Account Data, as described above, on your behalf;
            </p>
          </li>
          <li>
            <p>
              6.2.4 acknowledge that CUSTANY does not review your FI Account
              Data and agree that CUSTANY is not responsible for its
              completeness or accuracy;
            </p>
          </li>
          <li>
            <p>
              6.2.5 acknowledge that any transactions or informational
              activities performed at any financial institution(s)' website are
              not made through the Foclarity Service and CUSTANY assumes no
              responsibility for any such transactions or activities; and
            </p>
          </li>
          <li>
            <p>
              6.2.6 acknowledge that you are solely responsible for any charges,
              fees or costs associated with your financial institution
              account(s) when accessed through the Foclarity Service by you or by
              CUSTANY.
            </p>
          </li>
        </ul>
        <p>
          <strong>6.3. Information from Financial Institutions' Websites.</strong> You
          acknowledge that (i) some financial institution(s) may not permit
          CUSTANY or other third parties to have access to FI Login Data or to
          allow the Foclarity Service to access your FI Account Data; (ii) financial
          institution(s) may make changes to their websites, with or without
          notice to you or CUSTANY, that may affect the overall performance of
          the Foclarity Service and prevent or delay aggregation of data from such
          websites; and (iii) the Foclarity Service refreshes your Foclarity Service
          account data by collecting the FI Account Data automatically or
          manually (depending on your financial institution(s) or any changes by
          you that may require an update), so your most recent transactions may
          not always be reflected in the account balances or other account
          information presented to you by CUSTANY through the Foclarity Services. If
          you see a discrepancy in your Foclarity Service account data as compared
          to your FI Account Data, and in any case before making any
          transactions or decisions based on such account data presented in the
          Foclarity Services, you should check the last refresh date for your
          financial institution account(s) and confirm the accuracy of the Foclarity
          Service account data against your FI Account Data and manually update
          such data as necessary.
        </p>

        <h3>7. ONLINE DATA TRANSFER (WHERE APPLICABLE).</h3>

        <p>
          You may have the option for CUSTANY to transfer your data files from
          the Foclarity Service in order to facilitate certain interoperability,
          data integration, and data access between the Foclarity Service and
          certain supported ancillary services (the “Ancillary Services”) you
          may sign up for and use in connection with the Foclarity Service (the
          “Online Data Transfer”). In order for you to select the Online Data
          Transfer option, you must (i) have registered select versions of the
          Foclarity Service, (ii) have Internet access, (iii) have an active
          subscription to the Foclarity Service; and (iv) and may need to be an
          active subscriber to the Ancillary Services. If you select the Online
          Data Transfer option, a copy of all or part of your company data files
          will be transferred via the Internet to CUSTANY's servers; where you
          grant CUSTANY the right and license to (i) host and maintain your
          data, (ii) use and transfer your data to the Ancillary Services and
          (iii) reformat and manipulate your data as reasonably necessary for
          the data to function with the Ancillary Services. Your original data
          files will remain in the Foclarity Service. If you sign up for any
          Ancillary Services that support Online Data Transfer, you will have
          the option to request that CUSTANY send your data to any of those
          supported Ancillary Services. If a supported Ancillary Service is
          provided by a third party, you authorize CUSTANY to transfer your data
          to and from the third party provider to enable provision of the
          Ancillary Services to you. CUSTANY will support and maintain the data
          transfer service as part of the Foclarity Service. You agree that the
          third party provider may transfer your data from the Ancillary Service
          to CUSTANY, and that, CUSTANY may use such data subject to the terms
          of this Agreement. You agree and acknowledge that CUSTANY has no
          control over any third party provider or any third party Ancillary
          Services. Your use of the Ancillary Service is subject to additional
          third party terms and conditions. Please carefully review their terms
          and conditions, including privacy policy. If you do not agree with
          their terms and policies, you should not use or access the third party
          Ancillary Service and you should not authorize the Online Data
          Transfer to the third party Ancillary Service. .
        </p>

        <h3>8. Foclarity SERVICE USE, STORAGE AND ACCESS.</h3>
        <p>
          CUSTANY shall have the right, in its sole discretion and with
          reasonable notice posted on the Foclarity Service site and/or sent to you
          at the Current Administrator's email address provided in the
          Registration Data, to revise, update, or otherwise modify the Foclarity
          Service and establish or change limits concerning use of the Foclarity
          Service, temporarily or permanently, including but not limited to (i)
          the amount of storage space you have on the Foclarity Service at any time,
          and (ii) the number of times (and the maximum duration for which) you
          may access the Foclarity Service in a given period of time. CUSTANY
          reserves the right to make any such changes effective immediately to
          maintain the security of the system or User Access Information or to
          comply with any laws or regulations, and to provide you with
          electronic or written notice within thirty (30) days after such
          change. You may reject changes by discontinuing use of the Foclarity
          Service to which such changes relate. Your continued use of the Foclarity
          Service will constitute your acceptance of and agreement to such
          changes. CUSTANY may, from time to time, perform maintenance upon the
          Foclarity Service resulting in interrupted service, delays or errors in
          the Foclarity Service. CUSTANY will attempt to provide prior notice of
          scheduled maintenance but cannot guarantee that such notice will be
          provided.
        </p>

        <h3>9. CANCELLATION.</h3>
        <p>
          Upon cancellation you will be able to access the Foclarity Service only
          through the end of the subscription term, as specified in the product
          or product program pages. After the subscription term ends, you will
          not have any access to the Foclarity Online Service. There are no refunds
          upon cancellation. Please follow product instructions to cancel your
          account.
        </p>

        <h3>10. SOCIAL MEDIA SITES.</h3>
        <p>
          CUSTANY may provide experiences on social media platforms such as
          Facebook®, Twitter® and LinkedIn® that enable online sharing and
          collaboration among anyone who has registered to use them. Any content
          you post, such as pictures, information, opinions, or any Personal
          Information that you make available to other participants on these
          social platforms, is subject to the Terms of Use and Privacy Policies
          of those platforms. Please refer to those social media platforms to
          better understand your rights and obligations with regard to such
          content.
        </p>

        <h3>January 2022</h3>

        <h3>Contact us</h3>

        <p>
          If you have any questions regarding these terms and conditions, please
          contact us via email at info@foclarity.com.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";

export default {
  title: "Terms and Conditions | Foclarity",
  name: "Terms",
  mounted: function () {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
  },
  components: {},
  data() {
    return {};
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
.welcomeback {
  padding: 40px;
  text-align: center;
  border: 1px solid #ccc;
}

.heroblue {
  background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
  & a {
    text-decoration: none;
    color: #fff;
    & .signupbox {
      text-align: center;
      padding: 8px;
      font-size: 24px;
      font-weight: bold;
      background: #ff7f24;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: auto;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 10px;
  min-width: 200px;
  & .homecard {
    text-align: center;
    padding: 20px;
    border-radius: 4px;
    background: rgba(0, 178, 238, 0.1);
    height: 100%;
    & .cardicon {
      width: 100px;
      height: 100px;
      margin: auto;
      & img {
        width: 80px;
      }
    }
    & .h3 {
      font-size: 23px;
      line-height: 32px;
      color: #007cee;
      text-align: left;
      @media (max-width: 400px) {
        font-size: 20px;
      }
    }
  }
}

.homequote {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-style: italic;
  color: #666;
  max-width: 600px;
  margin: 20px auto;
}
</style>

