<template>
    <div id="app">
        <Brow :key="$route.name" v-show="false" />
        <Header :key="$route.path" />
		<div style="display: flex;flex-direction: row;flex-wrap: nowrap;">
			<LeftNav :key="$route.path"/>
			<router-view/>
		</div>
		<Footer />
    </div>
</template>

<script>
import Brow from '@/components/includes/Brow.vue'
import Header from '@/components/includes/Header.vue'
import Footer from '@/components/includes/Footer.vue'
import LeftNav from '@/components/includes/LeftNav.vue'

export default {
	name      : 'Foclarity',
	data () {
		return {}
	},
	components: {
		Brow,
		Header,
		Footer,
		LeftNav
	}
}
</script>

<style lang="scss">
	@import '@/scss/main.scss';
</style>
