<template>
  <div>
    <div v-if="category_id > 0">
      <div class="summary-header" style="text-align:center;">
        <span v-if="tasks_header === 'All Tasks'" ><strong>All Tasks</strong></span>
        <span v-else><a href="#" @click.prevent="loadAllTasks()">All Tasks</a></span>
        |
        <span v-if="tasks_header === 'Priority Tasks'" ><strong>Priority</strong></span>
        <span v-else><a href="#" @click.prevent="loadPrioritizedTasks()">Priority</a></span>
        |
        <span v-if="tasks_header === 'My Tasks'" ><strong>Mine</strong></span>
        <span v-else><a href="#" @click.prevent="loadMyTasks()">Mine</a></span>
        |
        <span v-if="tasks_header === 'Completed Tasks'" ><strong>Completed</strong></span>
        <span v-else><a href="#" @click.prevent="loadCompletedTasks()">Completed</a></span>
      </div>
    </div>
    <div v-else>
      <div class="summary-header" style="text-align:center;">
        <span v-if="tasks_header === 'All Tasks'" ><strong>All Tasks</strong></span>
        <span v-else><a href="#" @click.prevent="loadAllTasks()">All Tasks</a></span>
        |
        <span v-if="tasks_header === 'Priority Tasks'" ><strong>Priority</strong></span>
        <span v-else><a href="#" @click.prevent="loadPrioritizedTasks()">Priority</a></span>
        |
        <span v-if="tasks_header === 'My Tasks'" ><strong>Mine</strong></span>
        <span v-else><a href="#" @click.prevent="loadMyTasks()">Mine</a></span>
        |
        <span v-if="tasks_header === 'Completed Tasks'" ><strong>Completed</strong></span>
        <span v-else><a href="#" @click.prevent="loadCompletedTasks()">Completed</a></span>
      </div>
    </div>
    
    <div v-if="Object.keys(tasks).length" style="border-bottom:1px solid #ccc;">
      <div v-for="(value, name) in tasks" v-bind:key="name">
        <div v-for="task in value" :key="task.task_id">
          <div>
            <div v-if="(category_id > 0) && (task.category_id === category_id)" style="border:1px solid #ccc;border-bottom:0px;background: #fff;">
              <div class="row">
                <div class="column" style="flex-grow:0;min-width:30px;" v-if="scid > 0">
                  <div class="round">
                    <input type="checkbox" v-model="task.completed">
                    <label for="checkbox" @click="taskdone(task.category_id, task.measure_id, task.task_id, task.completed)" title="Toggle completed"></label>
                  </div>
                </div>
                <div v-else class="column" style="flex-grow:0;min-width:30px;">
                  <!-- no checkbox -->
                </div>
                <div class="column" style="flex-grow:3;font-size:1rem;overflow:hidden;">
                    <router-link :to="{name: 'measure', params: {scorecard_id: $route.params.scorecard_id, category_id: task.category_id, measure_id: task.measure_id}}">
                    <strong>{{ task.task_description }}</strong>
                    </router-link>
                </div>
                <div class="column" style="flex-grow:0;text-align:right;font-size:.8rem;min-width:30px;">
                  <img v-if="task.prioritized" style="width:20px;height:20px;cursor:pointer;" :src="require('@/assets/ic_star_on.png')" alt="star on" @click="prioritizeTask(task.category_id, task.measure_id, task.task_id, task.prioritized)" />
                  <img v-else style="width:20px;height:20px;cursor:pointer;" :src="require('@/assets/ic_star_off.png')" alt="star off" @click="prioritizeTask(task.category_id, task.measure_id, task.task_id, task.prioritized)" />
                </div>
              </div>
              <div class="row" style="background:#fff;">
                <div class="column" style="flex-grow:0;min-width:30px;">
                </div>
                <div class="column" style="flex-grow:3;font-size:.8rem;color:#000;text-transform:capitalize;" :style="{color: overdue(task.due_by, task.completed)}">
                  <div style="font-size:.8rem;color:#06C;text-transform:capitalize;cursor: pointer;" v-on:click.prevent="assignUser(task)">
                    <span v-if="task.completed">{{ task.updated_at | moment("MMM DD, YYYY") }} &bull; </span>
                    <span v-else :style="{color: overdue(task.due_by, task.completed)}">Due: {{ task.due_by | moment("MMM DD, YYYY") }}</span>
                    &#8680; {{ task.assigned_user_name }} {{ task.assigned_by ? "(assigned by " + task.assigned_by + ")" : "" }} &bull; edit
                  </div>
                </div>
                <div class="column" style="flex-grow:3;text-align:right;font-size:.8rem;">
                  <div style="white-space: nowrap;">
                    <router-link :to="{name: 'category', params: {scorecard_id: $route.params.scorecard_id, category_id: task.category_id}}">
                    {{ task.category_name }} 
                    </router-link> >
                    <router-link :to="{name: 'measure', params: {scorecard_id: $route.params.scorecard_id, category_id: task.category_id, measure_id: task.measure_id}}">
                    {{ name }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="category_id == ''" style="border:1px solid #ccc;border-bottom:0px;background: #fff;">
              <div class="row">
                <div class="column" style="flex-grow:0;min-width:30px;" v-if="scid > 0">
                  <div class="round">
                    <input type="checkbox" v-model="task.completed">
                    <label for="checkbox" @click="taskdone(task.category_id, task.measure_id, task.task_id, task.completed)" title="Toggle completed"></label>
                  </div>
                </div>
                <div class="column" style="flex-grow:3;font-size:1rem;overflow:hidden;">
                    <router-link :to="{name: 'measure', params: {scorecard_id: task.scorecard_id, category_id: task.category_id, measure_id: task.measure_id}}">
                      <strong>{{ task.task_description }}</strong>
                    </router-link>
                </div>
                <div class="column" style="flex-grow:0;text-align:right;font-size:.8rem;min-width:30px;">
                  <img v-if="task.prioritized" style="width:20px;height:20px;cursor:pointer;" :src="require('@/assets/ic_star_on.png')" alt="star on" @click="prioritizeTask(task.category_id, task.measure_id, task.task_id, task.prioritized)" />
                  <img v-else style="width:20px;height:20px;cursor:pointer;" :src="require('@/assets/ic_star_off.png')" alt="star off" @click="prioritizeTask(task.category_id, task.measure_id, task.task_id, task.prioritized)" />
                </div>
              </div>
              <div class="row" style="background:#fff;">
                <div class="column" style="flex-grow:3;font-size:.8rem;color:#000;text-transform:capitalize;" :style="{color: overdue(task.due_by, task.completed)}">
                  Due: {{ task.due_by | moment("MMM DD, YYYY") }}
                  &#8680; {{ task.assigned_user_name }} {{ task.assigned_by ? "(assigned by " + task.assigned_by + ")" : "" }}
                </div>
                <div class="column" style="flex-grow:3;text-align:right;font-size:.8rem;">
                  <div style="white-space: nowrap;">
                    <router-link :to="{name: 'category', params: {scorecard_id: task.scorecard_id, category_id: task.category_id}}">
                    {{ task.category_name }} 
                    </router-link> >
                    <router-link :to="{name: 'measure', params: {scorecard_id: task.scorecard_id, category_id: task.category_id, measure_id: task.measure_id}}">
                    {{ name }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div> 
      </div>
    </div>
    <div v-else>
      <div v-show="notasks"  style="text-align:center;padding:20px;">
        <p>No open tasks</p>
      </div>
    </div>

    <!-- use the modal component, pass in the prop -->
    <modal v-if="showEditTask" @close="cancelEditTask">
      <template v-slot:header>
        <h3>Edit Task</h3>
      </template>
      <template v-slot:body>
        <div class="form-grp">
          <label for="assigned_user_id">Assign to:</label>
          <select style="width:100%;padding:4px;" v-model="edit_ass_id">
              <option 
                v-for="member in members" 
                :key="member.member_id" 
                :value="member.member_id"
                :selected="member.member_id == edit_ass_id"
                >
                  {{member.member_first_name}} {{member.member_last_name}}
              </option>
          </select>
        </div>
        <div class="form-grp">
            <label for="task_description">Task Description:</label>
            <textarea v-model="edit_task_description" />
        </div>
        <div class="form-grp">
          <label for="due_by">Due By:</label>
          <template>
            <div class='date-picker'>
              <div class='custom-select'>
                <select @change='editValue("month", $event)' v-model='month'>
                  <option
                    v-for='(month, index) in months'
                    :key='month'
                    :value='index'>
                    {{ month }}
                  </option>
                </select>
              </div>
              <div class='custom-select'>
                <select @change='editValue("date", $event)' v-model='date'>
                  <option
                    v-for='i in daysInMonth'
                    :key='i'
                    :value='i'>
                    {{ i }}
                  </option>
                </select>
              </div>
              <div class='custom-select'>
                <select @change='editValue("year", $event)' v-model='year'>
                  <option
                    v-for='i in numberofYears'
                    :key='i'
                    :value='startingYear + (i - 1)'>
                    {{ startingYear + (i - 1) }}
                  </option>
                </select>
              </div>
            </div>
          </template>
          <div style="clear:both;"></div>
        </div>
        <div class="form-group">
            <button style="margin-right:auto;padding:10px 20px;" class="btn btn-primary" @click="editTask">Save</button>
            <button style="margin-left:8px;background: #333;" v-on:click="cancelEditTask">Cancel</button>
        </div>
      </template>
      <template v-slot:footer>
        <div v-if="edit_task_msg">{{edit_task_msg}}</div>
        <div v-else>
          Save your changes before closing
          <a href="#" style="float:right;color:#ff0000;font-size: .85em;" @click.prevent="deleteTask(edit_task_id)">Delete Task</a>
        </div>
      </template>
    </modal>


  </div>
</template>

<script>
import { accountService, taskService } from '../../_services' ;
import modal from "@/components/includes/InfoModal"
import moment from 'moment'

export default {
  props: [
    'scid',
    'category_id',
    'user_id',
    'category_name'
  ],
  data () {
      return {
        showCompleted: false,
        showEditTask: false,
        edit_task_id: 0,
        edit_task_category_id: 0,
        edit_task_measure_id: 0,
        edit_ass_id: 0,
        edit_task_description: "",
        edit_due_by: "",
        edit_prioritized: false,
        edit_index: 0,
        edit_task_msg: "",
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        startingYear: 2021,
        numberofYears: 5,
        daysInMonth: 30,
        dateValue: "",
        month: "",
        date: "",
        year: "",
        task_description: "",
        tasks_header: "",
        tasks: [],
        members: [],
        taskview: "mytasks",
        notasks: true,
        loading: true,
        error: '',
        open: true,
        trigger: 0
      }
  },
  methods: {
    editValue: function (unit, evt) {
      this.dateValue.set(unit, evt.target.value)
      this.daysInMonth = this.dateValue.daysInMonth()
      this.edit_due_by = this.dateValue.format()
      // 2021-05-28T12:53:12.247522-07:00
      this.updateElements()
    },
    updateElements: function () {
      // month.value gives us the <select> 
      this.month = this.dateValue.month()
      this.date = this.dateValue.date()
      this.year = this.dateValue.format('YYYY')
    },
    construction: (d) => {
      alert(d)
    },
    overdue(due_by, completed) {
      if (!completed) {
        var today = new Date();
        var due = new Date(due_by);
        if (due < today) {
          return "#f00"
        } else {
          return "#666"
        }
      } else {
        return "#666"
      }
    },
    deleteTask: function (task_id) {
      if (task_id == this.edit_task_id) {
        if(confirm("Really want to delete this task?")){
          taskService.deleteMeasureTask(this.scid, this.edit_task_category_id, this.edit_task_measure_id, task_id).then(
            data => {
              if (data.status == "success") {
                this.reloadTasks() ;
                this.showEditTask = false ;
              } else {
                this.showEditTask = false ;
                alert("Ran into an issue trying to delete this task. Please try again.")
              }
            }
          )
        }
      }
    },
    assignUser: function (task) {      
      this.edit_task_category_id = task.category_id
      this.edit_task_measure_id = task.measure_id
      this.edit_ass_id = task.assigned_user_id
      this.edit_task_description = task.task_description
      this.edit_task_id = task.task_id
      this.edit_due_by = task.due_by
      this.edit_prioritized = task.prioritized
      this.dateValue = moment(task.due_by)
      this.daysInMonth = this.dateValue.daysInMonth()
      this.updateElements()
      this.showEditTask = true
    },
    cancelEditTask: function () {
      this.edit_task_id = 0
      this.edit_ass_id = 0
      this.edit_task_description = ""
      this.edit_due_by = ""
      this.edit_prioritized = false
      this.edit_index= 0
      this.edit_task_msg = ""
      this.showEditTask = false ;
    },
    editTask: function () {      
      taskService.updateMeasureTask(this.scid, this.edit_task_category_id, this.edit_task_measure_id, this.edit_task_id, this.edit_task_description, this.edit_ass_id, this.edit_due_by, this.edit_prioritized).then(
        data => {
          if (data.status == "success") {
            this.edit_task_category_id = 0
            this.edit_task_measure_id = 0
            this.edit_task_id = 0
            this.edit_ass_id = 0
            this.edit_task_description = ""
            this.edit_due_by = ""
            this.edit_prioritized = false
            this.edit_index = 0
            this.edit_task_msg = ""
            this.showEditTask = false ;
            this.reloadTasks() ;
          } else {
            alert("Ran into an issue trying to update this task. Please try again.")
          }
        },
        () => {
          // console.debug(error)
          alert("Ran into an issue trying to update this task. Please try again.")
        }
      )
    },
    getMembers: function () {
      accountService.readAccountMembers().then(
        data => {
          this.members = data.members
        }
      )
    },
    loadPrioritizedTasks: function () {
      this.taskview = "prioritized";
      taskService.readAccountTasks().then(
        data => {
            let prioritizedTasks = [];
            this.tasks_header = "Priority Tasks"
            for (let i = 0; i < data.tasks.length; i++) {
                if (data.tasks[i].prioritized === true) {
                    prioritizedTasks.push(data.tasks[i]);
                }
            }

            if (prioritizedTasks.length === 0) {
              this.loadAllTasks()
              return
              // prioritizedTasks = data.tasks
              // this.tasks_header = "All Tasks"
            }

            const groupByKey = (list, key) => list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {})
            const tasks = groupByKey(prioritizedTasks, 'measure_name') ;
            if (this.category_name != "") {
              var fff = JSON.stringify(prioritizedTasks).search(this.category_name)
              if (fff >= 0) {
                this.notasks = false ;
              } else {
                this.loadAllTasks()
                return
              }
            }
            this.tasks = tasks
            this.loading = false
        },
        error => {
          //console.debug(error)
          this.error = error
          this.loading = false
        }
      )
    },
    loadAllTasks: function () {
      this.taskview = "all";
      taskService.readAccountTasks().then(
        data => {
            let allTasks = [];
            this.tasks_header = "All Tasks"
            for (let i = 0; i < data.tasks.length; i++) {
                if (data.tasks[i].completed === false) {
                    allTasks.push(data.tasks[i]);
                }
            }

            const groupByKey = (list, key) => list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {})
            const tasks = groupByKey(allTasks, 'measure_name') ;
            if (this.category_name != "") {
              var fff = JSON.stringify(allTasks).search(this.category_name)
              if (fff >= 0) {
                this.notasks = false ;
              }
            }
            this.tasks = tasks
            this.loading = false
        },
        error => {
          //console.debug(error)
          this.error = error
          this.loading = false
        }
      )
    },
    loadCompletedTasks: function () {
      this.taskview = "completed";
      taskService.readAccountTasks().then(
        data => {
            let completedTasks = [];
            this.tasks_header = "Completed Tasks"
            for (let i = 0; i < data.tasks.length; i++) {
                if (data.tasks[i].completed === true) {
                    completedTasks.push(data.tasks[i]);
                }
            }

            const groupByKey = (list, key) => list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {})
            const tasks = groupByKey(completedTasks, 'measure_name') ;
            if (this.category_name != "") {
              var fff = JSON.stringify(completedTasks).search(this.category_name)
              if (fff >= 0) {
                this.notasks = false ;
              } else {
                this.loadAllTasks()
                return
              }
            }
            this.tasks = tasks
            this.loading = false
        },
        error => {
          //console.debug(error)
          this.error = error
          this.loading = false
        }
      )
    },
    loadMyTasks: function () {
      this.taskview = "mytasks";
      taskService.readAccountTasks().then(
        data => {
            let myTasks = [];
            this.tasks_header = "My Tasks"
            for (let i = 0; i < data.tasks.length; i++) {
                if (data.tasks[i].assigned_user_id === this.user_id && data.tasks[i].completed === false) {
                    myTasks.push(data.tasks[i]);
                }
            }

            const groupByKey = (list, key) => list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {})
            const tasks = groupByKey(myTasks, 'measure_name') ;
            if (this.category_name != "") {
              var fff = JSON.stringify(myTasks).search(this.category_name)
              if (fff >= 0) {
                this.notasks = false ;
              } else {
                this.loadAllTasks()
                return
              }
            }
            this.tasks = tasks
            this.loading = false
        },
        error => {
          //console.debug(error)
          this.error = error
          this.loading = false
        }
      )
    },
    prioritizeTask: function (task_cid, task_mid, task_id, prioritized) {
      taskService.prioritizeMeasureTask(this.scid, task_cid, task_mid, task_id, prioritized).then(
        data => {
          if (data.status == "success") {
            this.reloadTasks() ;
            // this.loadPrioritizedTasks();
          } else {
            alert("Ran into an issue trying to update this task. Please try again.")
          }
        }
      )
    },
    reloadTasks: function () {
      if (this.taskview == "prioritized") {
        this.loadPrioritizedTasks()
      } else if (this.taskview == "mytasks") {
        this.loadMyTasks()
      } else if (this.taskview == "completed") {
        this.loadCompletedTasks()
      } else if (this.taskview == "all") {
        this.loadAllTasks()
      } else {
        this.loadAllTasks()
      }
    },
    taskdone: function (task_cid, task_mid, task_id, completed) {
      taskService.toggleMeasureTask(this.scid, task_cid, task_mid, task_id, completed).then(
        data => {
          if (data.status == "success") {
            this.reloadTasks() ;
            // this.loadPrioritizedTasks() ;
          } else {
            alert("Ran into an issue trying to update this task. Please try again.")
          }
        }
      )
    }
  },
  components: {
    modal
  },
  created () {
    this.loadMyTasks();
    this.getMembers();
  }
};
</script>

<style>
.task {
  padding: 10px ;
}
.newtask {
  text-align: center ;
  padding: 40px 4px ;
  cursor: pointer ;
}
.summary-header {
  /* background: #eee ;
  border-radius: 4px 4px 0 0 ;
  border-top: 8px solid #ccc;
  border-left: 1px solid #ccc; 
  border-right: 1px solid #ccc;  */
  padding: 4px 10px ;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

</style>