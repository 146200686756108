import { authHeader, router } from '../_helpers';

export const snapshotService = {
    createSnapshot,
    readSnapshot,
    updateSnapshot,
    deleteSnapshot,
    readAccountSnapshots,
    readScorecardSnapshots,
    readCategorySnapshots,
    readMeasureSnapshots,
    readBenchmarkSnapshot,
    readScharts
};
function updateSnapshot() {}
function deleteSnapshot() {}

function createSnapshot(scid, cid, mid, bmid, snapshot_notes) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ snapshot_notes })
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid
        + "/accounts/" + aid
        + "/scorecards/" + scid
        + "/categories/" + cid
        + "/measures/" + mid
        + "/benchmarks/" + bmid
        + "/snapshots" ;

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // snapshot creation is successful if there's a snapshot in the response
            if (data.snapshot) {
                // add snapshot to local storay
                // console.log('saved snapshot' + JSON.stringify(data.snapshot))
                return data.snapshot;
            }
            return data.status
        });
}

function readSnapshot(snapshot_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id ;
    let aid = JSON.parse(localStorage.getItem('account')).account_id ;

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/snapshots/" + snapshot_id ;
    
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // snapshot read successful
            return data.snapshot;
        });
}

function readBenchmarkSnapshot(scid, cid, mid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/scorecards/" + scid
        + "/categories/" + cid
        + "/measures/" + mid
        + "/snapshot"
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function readMeasureSnapshots(scid, cid, mid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/scorecards/" + scid
        + "/categories/" + cid
        + "/measures/" + mid
        + "/snapshots"
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            localStorage.setItem('snapshots', JSON.stringify(data.snapshots));
            return data;
        });
}

function readCategorySnapshots(scid, cid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid
        + "/scorecards/" + scid
        + "/categories/" +cid 
        + "/snapshots"
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            localStorage.setItem('snapshots', JSON.stringify(data.snapshots));
            return data;
        });
}

function readScorecardSnapshots(scid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    const aid = JSON.parse(localStorage.getItem('account')).account_id ;
    const uid = JSON.parse(localStorage.getItem('user')).user_id ;

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid
        + "/scorecards/" + scid
        + "/snapshots"
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            localStorage.setItem('snapshots', JSON.stringify(data.snapshots));
            return data;
        });
}

function readScharts(scid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid
        + "/scorecards/" + scid
        + "/scharts"
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function readAccountSnapshots() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/snapshots"
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // snapshots array in the response
            if (data.snapshots) {
                // store snapshots in local storage 
                localStorage.setItem('snapshots', JSON.stringify(data.snapshots));
            }
            return data;
        });

}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

