<template>
    <div class="content">
      <div class="middled">
        <div class="bodyblock" v-show="loading" style="text-align:center;padding:40px;font-size:2rem;">Loading...</div>
  
        <div class="dashboard-content" v-show="!loading">
            
        <div class="row" style="border-bottom:2px solid #333;padding:6px 0 0 0;margin:0 6px;">
            <div class="column dash" style="padding: 6px 0;">
                <h2 style="margin-top: 0;">{{ account.account_name }} Team</h2>
            </div>
            <div class="column dash2">
            <div style="text-align: right;margin: 0;">
                <a href="#" v-on:click.prevent="toggleTeam = !toggleTeam">
                <img v-if="(account.user_id === user.user_id)" :src="require('@/assets/ic_edit.png')" width="28" height="28" alt="Edit Team" title="Edit Team" />
                </a>
            </div>
            </div>
        </div>

        <div class="row" v-show="showTeam">
            <div class="column">
              <div>
                <div style="padding-left:0px;padding-top:20px;">
                  <div v-if="members">
                    <div class="row" v-for="(member,index) in members" :key="index">
                      <div v-if="member.member_profile_image" :style="'border-radius:50%;width:30px;height:30px;background:url(https://corl8-files.s3.amazonaws.com/' + member.member_profile_image +') no-repeat;background-size:cover;'"></div>                    
                      <div v-else>
                        <img style="width:30px;height:30px;" :src="require('@/assets/ic_person.png')" alt="profile icon" />
                      </div>
                      <div style="line-height:1.8rem;padding-left:4px;margin-bottom:10px;">
                        {{member.member_first_name}} {{member.member_last_name}} 
                        <span v-if="member.member_id === account.user_id">(Owner)</span>
                      </div>
                      <div v-show="toggleTeam" v-if="(member.member_id != account.user_id) && (account.user_id === user.user_id)" style="padding-left:4px;padding-top: 6px;">
                        <img v-on:click="deleteMember(member.member_id)" style="width:20px;height:20px;cursor: pointer;" :src="require('@/assets/ic_delete.png')" title="delete member" />
                      </div>
                      <div style="padding-left:4px;padding-top: 6px;">
                        <a :href="('mailto:' + member.member_email)" target="_blank">
                          <img style="width:20px;height:20px;" :src="require('@/assets/ic_email.png')" :title="member.member_email" />
                        </a>
                      </div>
                      <div v-show="toggleTeam" v-if="(member.member_id != account.user_id) && (account.user_id === user.user_id)" v-on:click="changeOwner(member.member_id)" style="margin-left:auto;cursor: pointer;" title="Make Account Owner" >Change ownership to {{ member.member_first_name }}</div>
                    </div>
                  </div>
                  <div v-else>
                    <p>Nobody here yet... Invite your team.</p>
                  </div>
                </div>
                <div v-if="account.user_id === user.user_id">
                  <div class="row" style="max-width:500px;">
                    <div class="column" style="flex-grow:2;">
                      <input type="text" v-model="invite_email" placeholder="Enter email">
                    </div>
                    <div class="column" style="min-width:100px;padding-top:15px;">
                      <button class="btn-add wide" :disabled="inviting" v-on:click="makeinvite">Invite</button>
                    </div>
                  </div>
                  <transition name="fade">
                    <div v-show="show_invite_message">
                      {{ invite_message }}
                    </div>
                  </transition>
                </div>
                <div v-else>
                  <div style="padding-left:10px;padding-top:10px;">
                    <p class="tiny">
                      The Owner listed above can invite others to the team.
                    </p>
                  </div>
                </div>
                <div v-if="(open_invites.length > 0) && (account.user_id === user.user_id)">
                  <div style="padding:10px 10px 10px 0;">
                    <div style="text-align: right;">
                      <a class="tiny" v-on:click="show_pending = !show_pending">
                        <span v-show="!show_pending">Show invitations</span>
                        <span v-show="show_pending">Hide invitations</span>
                      </a>
                    </div>
                    <div v-show="show_pending" class="row" v-for="invite in open_invites" :key="invite.invitation_id" style="margin-bottom: 8px;border-bottom:1px solid #ccc;">
                      <template v-if="invite.invitation_accepted != true">
                        <div>
                          <img style="width:30px;height:30px;" :src="require('@/assets/ic_person.png')" alt="person icon" />
                        </div>
                        <div style="padding-left:4px;line-height: 1.5em;flex:auto;">
                          {{invite.invitation_email}}
                        </div>
                        <div class="tiny" style="padding-left:34px;line-height: 3em;">
                          Invited {{ invite.created_at | moment("MMM DD, YYYY") }}
                          <a v-if="account.user_id === user.user_id" @click.prevent="deleteInvite(invite.invitation_id)">[ Delete ]</a>
                          <a :href="`mailto:${invite.invitation_email}?subject=Please join me on Foclarity&body=I am using Foclarity to track initiatives we are working on together. Please join at www.foclarity.com and accept my invitation.`" target="_blank">[ Send Reminder]</a>
                        </div>
                      </template>
                      <template v-else>
                        <div>
                          <img style="width:30px;height:30px;" :src="require('@/assets/ic_person.png')" alt="person icon" />
                        </div>
                        <div style="padding-left:4px;line-height: 1.5em;flex:auto;">
                          {{invite.invitation_email}}
                        </div>
                        <div class="tiny" style="padding-left:34px;line-height: 3em;">
                          Accepted {{ invite.updated_at | moment("MMM DD, YYYY") }}
                          <a v-if="account.user_id === user.user_id" @click.prevent="deleteInvite(invite.invitation_id)">[ Delete ]</a>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
        </div>
  
        <!-- use the modal component, pass in the prop -->
        <modal v-if="showVideo" @close="showVideo = false">
          <template v-slot:header>
            <h3>Foclarity Business Management Demo</h3>
          </template>
          <template v-slot:body>
            <iframe style="margin-top:10px;" width="100%" height="315" src="https://www.youtube.com/embed/GjWmmbh5sPQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        
          </template>
          <template v-slot:footer>
            <p>Foclarity helps you see the big picture... try it free!</p>
          </template>
        </modal>                
  
      </div>
    </div>
  </template>
  
  <script>
  import { isLoggedIn, logout } from "../utils/auth";
  import { userService } from "../_services/user.service"
  import { accountService } from "../_services/account.service"
  import { router } from '../_helpers';
  import modal from "@/components/includes/InfoModal"
  
  export default {
    title: 'Team | Foclarity',
    name: "Team",
    mounted: function() {
      if (isLoggedIn()) {
        // this.$router.push("dashboards");
      }
    },
    data () {
        return {
          user: '',
          account: '',
          members: [],
          loading: true,
          active: false,
          message: "",
          invite_email: "",
          invite_message: "",
          show_invite_message: false,
          open_invites: [],
          inviting: false,
          emsg: "",
          error: "",
          submitted: false,
          showVideo: false,
          showall: "",
          show_pending: false,
          showTeam: true,
          toggleTeam: false
        }
    },
    methods: {
      isLoggedIn() {
        return isLoggedIn();
      },
      toggleshow: function () {
        this.showall = !this.showall
        localStorage.setItem('showall', JSON.stringify(this.showall));
      },
      changeOwner: function (mid) {
        if(confirm("Are you sure you want to make them the Account Owner? You can't undo this operation and they will have to make you the owner again if this is a mistake. If you click OK the account owner will be changed and you will need to login again.")){
          if (this.account.user_id === this.user.user_id) {
          // user is account owner
          accountService.changeOwner(mid).then(
            data => {
              this.invite_message = data.message
              this.show_invite_message = true ;
              this.getMembers(); // update the members list
              setTimeout(() => this.show_invite_message = false, 3000);
              setTimeout(() => logout(), 3000);
            },
            error => {
              this.invite_message = error
              this.show_invite_message = true ;
              setTimeout(() => this.show_invite_message = false, 3000);
            }
          )
        } else {
          // user is not account owner
          this.invite_message = "Only Account Owners change ownership."
          this.show_invite_message = true ;
          setTimeout(() => this.show_invite_message = false, 3000);
          }
        }
      },
      deleteMember: function (mid) {
        if(confirm("Delete this team member?")){
          if (this.account.user_id === this.user.user_id) {
          // user is account owner
          accountService.deleteMembership(mid).then(
            data => {
              this.invite_message = data.message
              this.show_invite_message = true ;
              this.getMembers(); // update the members list
              setTimeout(() => this.show_invite_message = false, 3000);
            },
            error => {
              this.invite_message = error
              this.show_invite_message = true ;
              setTimeout(() => this.show_invite_message = false, 3000);
            }
          )
        } else {
          // user is not account owner
          this.invite_message = "Only Account Owners can remove members."
          this.show_invite_message = true ;
          setTimeout(() => this.show_invite_message = false, 3000);
          }
        }
      },
      deleteInvite: function (iid) {
        if(confirm("Delete this invitation?")){
          if (this.account.user_id === this.user.user_id) {
          // user is account owner
          accountService.deleteOpenInvitation(iid).then(
            data => {
              this.invite_message = data.message
              this.show_invite_message = true ;
              this.getOpenInvitations(); // update the invitations list
              setTimeout(() => this.show_invite_message = false, 3000);
            },
            error => {
              this.invite_message = error
              this.show_invite_message = true ;
              setTimeout(() => this.show_invite_message = false, 3000);
            }
          )
        } else {
          // user is not account owner
          this.invite_message = "Ask the Account Owner if you want to add a collaborator."
          this.show_invite_message = true ;
          setTimeout(() => this.show_invite_message = false, 3000);
          }
        }
      },
      makeinvite: function () {
        this.inviting = true
        if (this.account.user_id === this.user.user_id) {
          // user is account owner
          accountService.createInvitation(this.invite_email).then(
            data => {
              this.invite_email = ""
              this.invite_message = data.message
              this.show_invite_message = true ;
              this.getOpenInvitations(); // update the invitations list
              setTimeout(() => this.show_invite_message = false, 3000);
            },
            error => {
              this.invite_email = ""
              this.invite_message = error
              this.show_invite_message = true ;
              setTimeout(() => this.show_invite_message = false, 3000);
            }
          )
        } else {
          // user is not account owner
          this.invite_message = "Ask the Account Owner if you want to add a collaborator."
          this.show_invite_message = true ;
          setTimeout(() => this.show_invite_message = false, 3000);
        }
        this.inviting = false ;
      },
      contactSupport: function () {
        const subj = "Beta Feedback"
        this.submitted = true ;
  
        if (this.message === "") {
          this.emsg = "" ;
          this.error = "Please enter a message" ;
          this.submitted = false ;
          return ;
        } 
  
        accountService.contactSupport(subj, this.message).then(
          data => {
            if (data.status == 'failed') {
              this.submitted = false ;
              this.error = 'failed to send message' ;
            } else {
              this.error = ""
              this.message = ""
              this.submitted = false ;
              this.emsg = "Feedback sent, thank you!"
              this.help = false;
            }
        },
        error => {
          this.error = error
          this.loading = false
        });
      },
      construction: (d) => {
        alert(d)
      },
      getMembers: function () {
        this.loading = true
        accountService.readAccountMembers().then(
          data => {
            this.members = data.members
        }
        )
        this.loading = false
      },
      goto: function (q) {
          router.push(q)
      },
      deleteOpenInvitation: function () {
        // todo
      },
      getOpenInvitations: function () {
        this.loading = true
        accountService.getOpenInvitations().then(data => {
          this.open_invites = []
          for (const invite of data.invitations) {
            if (invite.deleted != true) {
              this.open_invites.push(invite)
            }
          }
        })
        this.loading = false
      },
    },
    components: {
      modal
    },
    created () {
      userService.getMe().then(data => {
        this.user = data
        this.account = accountService.getActiveAccount()
        if (this.account == null) {
          router.push("/accounts")
          return
        }
        if (this.account.account_name) {
          window.document.title = this.account.account_name + " dashboards | Foclarity"
        }
        this.getMembers();
        this.getOpenInvitations();
      });
    },
    computed: {
    },
    filters: {
      capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
      }
    }  
  };
  </script>
  
  <style lang="scss" scoped>
  .categorycard {
    cursor: pointer ;
    & .catname {
      color: #333 !important;
    }
  }
  
  .score {
    font-size: 4rem ;
    color: #000 ;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  
  .mission {
    padding:0 10px 0px 10px;
    margin:0px 0px 0 0;
    border-radius: 0 0 4px 4px;
    border-top: 2px solid #000;
    color:#000;
    background: #fff;
    background-repeat: no-repeat;
    background-size: cover;
  }
  #mission {
    white-space: break-spaces;
  }
  
  .activeButton {
    background: #0066CC;
    color: #fff;
    border: 1px solid #ccc;
  }
  
  h2, h4 {
    color: #333;
  }
  
  .dashcard {
    flex: 0 ;
    background: #fff;
    box-shadow: 0px 2px 3px #ccc;
    border-radius: 4px;
    padding:0px;
    min-width:338px;
    border:1px solid #919191;
    @media only screen and (max-width: 1270px) {
      min-width:340px;
      // flex: 1;
    }
    @media only screen and (max-width: 1130px) {
      min-width:340px;
      flex: 1;
    }
  
  }
  </style>
  
  