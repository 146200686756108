<template>
  <div>
    <div class="row" v-if="measures && measures.length < 1">
      <div class="measure-row">
        <router-link :to="{name: 'category', params: {scorecard_id: scid, category_id: cid}}" title="Within this category, what do you want to track?">
          <span class="highlightyellow">Add Tiles...</span>
        </router-link>
      </div>
    </div>
    <div v-else class="row catrow">
      <div class="column mebox" v-for="measure in measures" v-bind:key="measure.measure_id">
        <div class="rank0" :class="'rank' + measure.snapshot.rank_score">
          <router-link :to="{name: 'measure', params: {scorecard_id: scid, category_id: cid, measure_id: measure.measure_id}}">
            <div class="row" :class="'rank' + measure.snapshot.rank_score">
              <div class="ranknum">{{ measure.snapshot.rank_score ? measure.snapshot.rank_score : '0' }}</div>
              <div class="rankname">{{ measure.snapshot.benchmark_description ? measure.snapshot.benchmark_description : 'No Status' }}</div>
              <div v-if="measure.measure_owners" style="margin-left:auto;padding-right:8px;">
                <span style="color:#fff;" class="tiny" v-for="(owner, index) in measure.measure_owners" :key="index">
                  <strong>{{ owner.owner_first_name }}</strong>
                </span>
              </div>
            </div>
            <div style="background:#fff;margin:0 4px 4px 4px;text-align:center;height: 160px;overflow: hidden;" :title="measure.snapshot.benchmark_description">
              <div style="min-width:200px; margin: 0;position: absolute;top: 45%;left: 50%;transform: translate(-50%, -50%);">
                <strong>{{ measure.measure_name | truncate(50, '...') }}</strong>
                <div v-if="measure.snapshot.benchmark_description" class="tiny" style="color:#333;">
                  <div v-if="measure.snapshot.snapshot_notes && measure.snapshot.snapshot_notes.startsWith(' --')">
                    {{ measure.snapshot.benchmark_description }}
                    {{ measure.snapshot.snapshot_notes }}
                  </div>
                  <div v-else>
                    <span v-if="measure.snapshot.snapshot_notes">
                      {{ measure.snapshot.snapshot_notes | truncate(100, '...') }}
                    </span>
                    <span v-else>
                      {{ measure.snapshot.benchmark_description }}
                    </span>
                  </div>
                </div>
                <div v-else class="tiny" style="color:#333;">
                  <span v-if="measure.measure_description.includes('http')"></span>
                  <span v-else>
                    {{ measure.measure_description | truncate(100, '...') }}
                  </span>
                </div>
              </div>
              <div class="tiny" style="text-align:center;width:100%;position: absolute;bottom: 2px;left: 0;" v-if="measure.snapshot.benchmark_description">
                as of {{ measure.snapshot.created_at | moment("MMM DD, YYYY") }}<span v-if="measure.snapshot.user_name">{{ " - " + measure.snapshot.user_name  }}</span>
              </div>
            </div>
          </router-link>
        </div>
        </div>
    </div>

  </div>
</template>

<script>
import { scorecardService } from '../../_services' ;

export default {
  props: [
    'scid',
    'cid',
  ],
  data () {
      return {
        showCompleted: false,
        measures: [],
        loading: true,
        error: '',
        open: true
      }
  },
  methods: {
    construction: (d) => {
      alert(d)
    },
    loadMeasures: function () {
      // update this to get Ranked Measures for Category joining latest snapshot for the rank number to color code the boxes.
      scorecardService.getRankedMeasuresForCategory(this.scid, this.cid).then(
        data => {
          this.measures = data.measures.reverse()
          this.loading = false
        },
        error => {
          // console.debug(error)
          this.error = error
          this.loading = false
        }
      )
    }
  },
  components: {
  },
  created () {
    this.loadMeasures();
  }
};
</script>

<style lang="scss" scoped>
.measure-row {
  padding: 10px 0 0 20px ;
}
</style>