<template>
  <div id="brow">
    <div class="brownav" :class="{widest : widetoggle}">
      <div class="newslink" v-if="!isLoggedIn()">
        <router-link to="who-we-are">Business Transformation Software & Consulting</router-link>
      </div>
      <div class="navlinks">
          <ul>
              <router-link tag="li" v-if="isLoggedIn()" to="/profile">Edit Profile</router-link>
              <router-link tag="li" v-if="isLoggedIn()" to="/accounts">Switch Team</router-link>
              <router-link tag="li" to="/contact">Contact Us</router-link>
              <li v-if="isLoggedIn()" @click="handleLogout()">Logout</li>
              <router-link tag="li" to="/login" v-if="!isLoggedIn()">Login</router-link>
              <router-link tag="li" :to="{ name: 'register', params: { r: 'r8jw934uHJnr' }}" v-if="!isLoggedIn()">Sign Up</router-link>
          </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { isLoggedIn, login, logout } from "../../utils/auth";
import { accountService, userService } from '../../_services';

export default {
  name: "app-nav",
  data() {
    return {
      toggle: false,
      user: "",
      account: "",
      feedback: false,
      widetoggle: false
    };
  },
  created () {
    if (this.isLoggedIn()) {
      this.widetoggle = false ;
      this.account = accountService.getActiveAccount()
      userService.getMe().then(data => {
        this.user = data
      });
    }
  },
  components: {
  },
  methods: {
    handleLogin() {
      login();
    },
    handleLogout() {
      logout();
    },
    isLoggedIn() {
      return isLoggedIn();
    }    
  }
};
</script>

<style scoped lang="scss">
@import "../../scss/colors";
#brow {
    @media only screen and (max-width: 760px) {
        display: none;
    }
    height: 40px;
    color:#fff;
    background: #3d3d3d;

    .brownav {
        padding: 0 30px 0 3px;
        text-align: right;
        // max-width: 1170px;
        margin:auto;

        .newslink {
          float:left;
          //text-transform: uppercase;
          margin: 0;
          padding: 0 10px;
          font-size: .8rem;
          font-weight: 700;
          line-height: 2.5rem;
          a {
            color:#fff;
            text-decoration: none;
          }
        }
        .navlinks {
            float:right;
            & > ul {
            width: auto;
            padding: 0;
            margin: 0;
                & > li {
                    display: block;
                    float:left;
                    display: block;
                    text-align: left;
                    text-transform: uppercase;
                    cursor: pointer;
                    margin: 0;
                    padding: 0 10px;
                    font-size: .8rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    line-height: 2.5rem;
                }
            }    
        }
    }
    & .widest {
      max-width: 100%;
    }
}
</style>
