<template>
        <div style="padding:20px 0;">
          <div>
            <div class="searchbar">
              <!-- add a search bar so user can search through this accounts boards -->
              <!-- show results in a div under the search bar after performing search for each keystroke -->
              <div class="search">
                <div style="float:right;height: 0;top:5px;">
                  <!-- add the ic_close.png to clear the search Results and the search variables when clicked -->
                  <img :src="require('@/assets/ic_close.png')" @click="cleanup()" width="21" height="21" align="left" style="margin-right:2px;" />
                </div>
                <!-- make a search input field that runs the function searchBoards on key up after 3 letters -->
                <input id="searchinput" type="text" v-model="search" @focus="getScorecards();" @keyup="searchBoards" :placeholder="SearchPlaceholder" />                    
              </div>
              <div v-show="user">
                <a style="font-size: .8em;padding:4px;" href="#" v-on:click.prevent="quicksearch('priorities')">my priorities</a> | 
                <a style="font-size: .8em;padding:4px;" href="#" v-on:click.prevent="quicksearch('tasks')">my tasks</a> | 
                <a style="font-size: .8em;padding:4px;" href="#" v-on:click.prevent="quicksearch('tiles')">my responsibilities</a>
              </div>
              <!-- make a div that shows the results of the search -->
              <div class="search-results" style="overflow-y: auto;max-height: 500px;" v-if="searchScorecards.length > 0 || searchCategories.length > 0 || searchMeasures.length > 0 || searchOwners.length > 0 || searchComments.length > 0 || searchTasks.length > 0">
                <!-- put scorecards here first, the follow with categories, measures -->
                <div v-if="searchScorecards.length > 0">
                  <div class="search-header">{{ searchScorecards.length }} Board<span v-show="searchScorecards.length != 1">s</span></div>
                  <div class="search-result" v-for="scorecard in searchScorecards" :key="'sc' + scorecard.scorecard_id">
                    <router-link :to="{name: 'scorecard', params: { scorecard_id: scorecard.scorecard_id }}" :title="scorecard.scorecard_name" style="text-decoration:none;">
                      <span style="font-weight: 700;color: #000;">{{ scorecard.scorecard_name | truncate(40, '...') }}</span>
                    </router-link>
                  </div>
                </div>
                <div v-if="searchCategories.length > 0">
                  <div class="search-header">{{ searchCategories.length }} <span v-show="searchCategories.length == 1">Category</span><span v-show="searchCategories.length != 1">Categories</span></div>
                  <div class="search-result" v-for="category in searchCategories" :key="'ca' + category.category_id">
                    <router-link :to="{name: 'category', params: { scorecard_id: category.scorecard_id, category_id: category.category_id }}" :title="category.category_name" style="text-decoration:none;">
                      <span class="tiny">{{ category.scorecard_name | truncate(40, '...') }}</span><br />
                      <span style="font-weight: 700;color:#000;">{{ category.category_name | truncate(40, '...') }}</span> >
                    </router-link>
                  </div>
                </div>
                <div v-if="searchMeasures.length > 0" >
                  <div class="search-header">{{ searchMeasures.length }} Topic<span v-show="searchMeasures.length != 1">s</span></div>
                  <div class="search-result" v-for="measure in searchMeasures" :key="'me' + measure.measure_id">
                    <router-link :to="{name: 'measure', params: { scorecard_id: measure.scorecard_id, category_id: measure.category_id, measure_id: measure.measure_id }}" :title="measure.measure_name" style="text-decoration:none;">
                      <span class="tiny">{{ measure.scorecard_name | truncate(40, '...') }}</span><br />
                      <span >{{ measure.category_name | truncate(20, '...') }}</span> >
                      <span style="font-weight: 700;color:#000;">{{ measure.measure_name | truncate(30, '...') }}</span>
                      <span style="float:right;background:#eee;border-radius: 4px;padding:2px 4px;margin: 0 0 0 4px;" class="tiny">{{ measure.snapshot.benchmark_description }}</span>
                      <div v-if="measure.measure_comments.length > 0" class="tiny" style="margin:8px 4px;padding: 8px;border-radius: 4px;background:#eee;">
                        <div style="font-size: smaller;"><strong>{{ measure.measure_comments[0].user_first_name }}</strong> &bull; {{ measure.measure_comments[0].created_at | moment("M/DD/YY") }}</div>
                        <div style="font-size: larger;">{{ measure.measure_comments[0].measure_comment }}</div>
                      </div>
                    </router-link>
                  </div>
                </div>
                <div v-if="searchOwners.length > 0" >
                  <div class="search-header">Ownership: {{ searchOwners.length }} Tile<span v-show="searchOwners.length != 1">s</span></div>
                  <div class="search-result" v-for="measure in searchOwners" :key="'me' + measure.measure_id">
                    <router-link :to="{name: 'measure', params: { scorecard_id: measure.scorecard_id, category_id: measure.category_id, measure_id: measure.measure_id }}" :title="measure.measure_name" style="text-decoration:none;">
                      <span class="tiny">{{ measure.scorecard_name | truncate(40, '...') }} > </span>
                      <span class="tiny">{{ measure.category_name | truncate(20, '...') }}</span> <br />
                      <span style="font-weight: 700;color: #000;">{{ measure.measure_name | truncate(30, '...') }}</span>
                      <span style="float:right;border-radius: 4px;padding:2px 4px;margin: 0 0 0 4px;color:#fff;font-weight: 700;" class="tiny" :class="'rank' + measure.snapshot.rank_score">{{ measure.snapshot.benchmark_description }}</span>
                      <div class="tiny">
                        <div style="font-size: larger;padding:8px;margin:4px 0;border: 1px solid #333;">
                          <div style="font-weight: 700;">
                            Owner: {{ measure.measure_owners[0].owner_first_name }}
                          </div>
                          <div>
                            <strong>
                              <span v-show="measure.snapshot.snapshot_id > 0" :class="{red : old(measure.snapshot)}">Last updated: {{ measure.snapshot.created_at | moment("M/DD/YY") }}</span>
                              <span v-show="measure.snapshot.snapshot_id == 0" class="red">No Status</span>
                            </strong>
                          </div>
                          <div v-if="measure.snapshot.snapshot_notes" style="padding:8px 0;">
                            <span>&OpenCurlyDoubleQuote;{{ measure.snapshot.snapshot_notes | truncate(80, "...") }}&CloseCurlyDoubleQuote;</span>
                          </div>
                        </div>
                      </div>
                      <div v-if="measure.measure_comments.length > 0" class="tiny" style="margin:8px 4px;padding: 8px;border-radius: 4px;background:#eee;">
                        <div style="font-size: smaller;"><strong>{{ measure.measure_comments[0].user_first_name }}</strong> &bull; {{ measure.measure_comments[0].created_at | moment("M/DD/YY") }}</div>
                        <div style="font-size: larger;">{{ measure.measure_comments[0].measure_comment }}</div>
                      </div>
                    </router-link>
                  </div>
                </div>
                <div v-if="searchComments.length > 0" >
                  <div class="search-header">{{ searchComments.length }} Recent Comment<span v-show="searchComments.length != 1">s</span></div>
                  <div class="search-result" v-for="comment in searchComments" :key="'co' + comment.measure_comment_id">
                    <router-link :to="{name: 'measure', params: { scorecard_id: comment.scorecard_id, category_id: comment.category_id, measure_id: comment.measure_id }}" :title="comment.measure_name" style="text-decoration:none;">
                      <span class="tiny">{{ comment.scorecard_name | truncate(40, '...') }}</span><br />
                      <span >{{ comment.category_name | truncate(20, '...') }}</span> >
                      <span style="font-weight: 700;color: #000;">{{ comment.measure_name | truncate(30, '...') }}</span>
                      <div class="tiny" style="margin:8px 4px;padding: 8px;border-radius: 4px;background:#eee;">
                        <div style="font-size: smaller;"><strong>{{ comment.user_first_name }}</strong> &bull; {{ comment.created_at | moment("M/DD/YY") }}</div>
                        <div style="font-size: larger;">{{ comment.measure_comment }}</div>
                      </div>
                    </router-link>
                  </div>
                </div>
                <div v-if="searchTasks.length > 0" >
                  <div class="search-header">{{ searchTasks.length }} Action Item<span v-show="searchTasks.length != 1">s</span></div>
                  <div class="search-result" v-for="task in searchTasks" :key="'me' + task.task_id">
                    <router-link :to="{name: 'measure', params: { scorecard_id: task.scorecard_id, category_id: task.category_id, measure_id: task.measure_id }}" :title="task.task_description" style="text-decoration:none;">
                        <img v-show="task.prioritized" style="float:right;width:20px;height:20px;cursor:pointer;" :src="require('@/assets/ic_star_on.png')" alt="prioritized" />
                        <span class="tiny">{{ task.scorecard_name | truncate(40, '...') }} > {{ task.measure_name | truncate(20, '...') }}</span><br />
                        <span style="font-weight: 700;color: #000;" :class="{lineout : task.completed}">{{ task.task_description | truncate(40, '...') }}</span><br />
                        <span class="tiny">{{ task.assigned_user_name }} • </span>
                        <span class="tiny" v-show="!task.completed">Due: {{ task.due_by | moment("M/DD/YY") }}</span>
                        <span class="tiny" v-show="task.completed">Completed: {{ task.updated_at | moment("M/DD/YY") }}</span>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="search-results" v-if="startsearch && (searchScorecards.length == 0 && searchCategories.length == 0 && searchMeasures.length == 0 && searchOwners.length == 0 && searchComments.length == 0 && searchTasks.length == 0)">
                <div class="search-result" v-show="!error" style="color:#666;">
                  {{ search_description }}
                </div>
                <div class="search-result error" v-show="error">
                    {{ error }}
                </div>
              </div>
            </div>
          </div>
        </div>

</template>

<script>
import { scorecardService } from '../../_services' ;

export default {
  props: [
    'reported',
    'user'
  ],
  data () {
      return {
        search: '',
        report: '',
        SearchPlaceholder: 'Search...',
        searchScorecards: [],
        searchCategories: [],
        searchMeasures: [],
        searchOwners: [],
        searchTasks: [],
        searchComments: [],
        startsearch: false,
        search_description: 'type "@" or "\\" or ">" for shortcuts.',
        error: ''
      }
  },
  methods: {
    cleanup: function () {
        this.SearchPlaceholder = 'Search...'
        this.startsearch = false;
        this.search = '';
        this.searchScorecards = [];
        this.searchCategories = [];
        this.searchMeasures = [];
        this.searchOwners = [];
        this.searchComments = [];
        this.searchTasks = [];
    },
    old: function (snapshot) {
      var today = new Date();
      var created = new Date(snapshot.created_at);
      var diff = today - created;
      var days = Math.floor(diff / (1000 * 60 * 60 * 24));
      if (days > 14 && snapshot.rank_score < 5) {
        return true
      } else {
        return false
      }
    },
    quicksearch: function (type) {
      if (type == 'priorities') {
        this.search = '\\' + this.user.first_name
      } else if (type == 'tasks') {
        this.search = '@' + this.user.first_name
      } else if (type == 'tiles') {
        this.search = '>' + this.user.first_name
      } else {
        return ; // do nothing
      }
      this.getScorecards() ;
      this.$nextTick(() => {
        document.getElementById('searchinput').focus()
        this.searchBoards();
      })
    },
    // write a function that takes this.search and looks for scorecard categories that includes this.search
    // then return the results into the correct array
    searchBoards: function () {
      if (this.search.length == 0) {
        this.search_description = 'or try the "@" or "\\" or ">" shortcuts.'
      }
      // don't perform the search until this.search has at least 1 character
      if (this.startsearch && this.search.length > 0) {
        // loop through scorecards and if anything matched, add them to the searchResults
        this.searchScorecards = []
        this.searchCategories = []
        this.searchMeasures = []
        this.searchOwners = []
        this.searchTasks = []
        this.searchComments = []
        this.report.scorecards.forEach(scorecard => {
          // if the scorecard name matches the search, add it to the searchScorecards array
          if (!scorecard.deleted && scorecard.scorecard_name.toLowerCase().includes(this.search.toLowerCase())) {
            this.searchScorecards.push(scorecard)
          }
          // if the board has categories, loop through them and see if they have measures that match the search
          if (scorecard.categories && !scorecard.deleted) {
            scorecard.categories.forEach(category => {
              if (category.category_name.toLowerCase().includes(this.search.toLowerCase())) {
                category.scorecard_name = scorecard.scorecard_name
                category.scorecard_id = scorecard.scorecard_id
                    this.searchCategories.push(category)
              }
              if (category.measures && !category.deleted) {
                category.measures.forEach(measure => {
                  if (measure.measure_name.toLowerCase().includes(this.search.toLowerCase())) {
                    measure.scorecard_name = scorecard.scorecard_name
                    measure.scorecard_id = scorecard.scorecard_id
                    measure.category_name = category.category_name
                    measure.category_id = category.category_id
                    this.searchMeasures.push(measure)
                  }
                  if (measure.measure_owners && !measure.deleted) {
                    if ((this.search.startsWith('>'))) {
                      this.search_description = 'Type a name to view their tiles.'
                      if (this.search.length > 3) {
                        let owner_search = this.search.substring(1)
                        owner_search = owner_search.trim()
                        measure.measure_owners.forEach(owner => {
                          if (owner.owner_first_name.toLowerCase().includes(owner_search.toLowerCase())) {
                            measure.scorecard_name = scorecard.scorecard_name
                            measure.scorecard_id = scorecard.scorecard_id
                            measure.category_name = category.category_name
                            measure.category_id = category.category_id
                            this.searchOwners.push(measure)
                          }
                        })
                      }
                    }
                  }
                  if (measure.measure_comments && !measure.deleted) {
                    if ((this.search.startsWith('#'))) {
                      this.search_description = 'Type a name to view recent comments.'
                      if (this.search.length > 3) {
                        let comment_search = this.search.substring(1)
                        // trim white space off the ends of comment_search
                        comment_search = comment_search.trim()
                        if (measure.measure_comments.length > 0) {
                          measure.measure_comments.forEach(comment => {
                            if (!comment.deleted)  {
                              if (comment.user_first_name.toLowerCase().includes(comment_search.toLowerCase())) {
                                comment.scorecard_name = scorecard.scorecard_name
                                comment.scorecard_id = scorecard.scorecard_id
                                comment.category_name = category.category_name
                                comment.category_id = category.category_id
                                comment.measure_name = measure.measure_name
                                comment.measure_id = measure.measure_id
                                // if measure.measure_id is already in searchComments, don't add it again
                                if (this.searchComments.length > 0 && this.searchComments[this.searchComments.length -1].measure_id == measure.measure_id) {
                                  // do nothing
                                } else {
                                  this.searchComments.push(comment)
                                }
                              }
                            }
                          })
                        }                                                      
                      }
                    }
                  }
                  if (measure.tasks && !measure.deleted) {
                    if ((this.search.startsWith('\\'))) {
                      this.search_description = 'Type a name to view their priority tasks.'
                      if (this.search.length > 3) {
                        let task_search = this.search.substring(1)
                        task_search = task_search.trim()
                        measure.tasks.forEach(task => {
                          if (!task.completed && task.prioritized)  {
                              if (task.assigned_user_name.toLowerCase().includes(task_search.toLowerCase())) {
                                  task.scorecard_name = scorecard.scorecard_name
                                  task.scorecard_id = scorecard.scorecard_id
                                  task.category_name = category.category_name
                                  task.category_id = category.category_id
                                  task.measure_name = measure.measure_name
                                  task.measure_id = measure.measure_id
                                  this.searchTasks.push(task)
                              }
                          }
                        })
                      }
                    } else if ((this.search.startsWith('@'))) {
                      this.search_description = 'Type a name to see all their tasks.'
                      if (this.search.length > 3) {
                        let task_search = this.search.substring(1)
                        task_search = task_search.trim()
                        measure.tasks.forEach(task => {
                        // let tomorrow = new Date();
                        // tomorrow.setDate(tomorrow.getDate() + 1);
                        // let due = new Date(task.due_by);
                        // if (!task.completed && (due < tomorrow))  {
                          if (!task.completed)  {
                            if (task.assigned_user_name.toLowerCase().includes(task_search.toLowerCase())) {
                                task.scorecard_name = scorecard.scorecard_name
                                task.scorecard_id = scorecard.scorecard_id
                                task.category_name = category.category_name
                                task.category_id = category.category_id
                                task.measure_name = measure.measure_name
                                task.measure_id = measure.measure_id
                                this.searchTasks.push(task)
                            }
                          }
                        })
                      }
                    } else {
                        measure.tasks.forEach(task => {
                          if (!task.completed && task.task_description.toLowerCase().includes(this.search.toLowerCase())) {
                              task.scorecard_name = scorecard.scorecard_name
                              task.scorecard_id = scorecard.scorecard_id
                              task.category_name = category.category_name
                              task.category_id = category.category_id
                              task.measure_name = measure.measure_name
                              task.measure_id = measure.measure_id
                              this.searchTasks.push(task)
                          }
                      })
                    }
                  }
                })
              }
            })
          }
        })
      } else {
        this.searchScorecards = []
        this.searchCategories = []
        this.searchMeasures = []
        this.searchOwners = []
        this.searchTasks = []
        this.searchComments = []
      }
    },
    construction: (d) => {
      alert(d)
    },
    overdue(due_by, completed) {
      if (!completed) {
        var today = new Date();
        var due = new Date(due_by);
        if (due < today) {
          return "#f00"
        } else {
          return "#666"
        }
      } else {
        return "#666"
      }
    },
    getScorecards: function () {
      this.SearchPlaceholder = 'Loading...'
      if (this.reported) {
        // console.log('already have what I need...')
        this.report = this.reported
        this.startsearch = true;
        this.SearchPlaceholder = 'Start typing...'
        // this.search = '';
        return
      } else if (this.report.length > 0) {
        // already got the report from another page, so just use it
        this.startsearch = true;
        this.SearchPlaceholder = 'Start typing...'
        // this.search = '';
      } else {
        scorecardService.readDashboards().then(
        data => {
            // console.log('had to run the report')
            this.report = data.report
            this.startsearch = true;
            this.SearchPlaceholder = 'Start typing...'
            // this.search = '';
        } 
        )
        .catch(error => {
            this.error = error
        });      
      }
    },
  },
  components: {
  },
  watch: {
    $route(to, from) {
        if (to.params !== from.params) {
            this.cleanup();
        }
    }
  },
  created () {
    // this.getScorecards();
  }
};
</script>

<style>
.lineout {
    text-decoration: line-through;
}
</style>