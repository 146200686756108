<template>
  <div class="content" style="flex-grow: 1;">
    <div class="middled">
      <div class="login" style="margin:auto;">
        <h1 style="margin: 20px 0 ;">Login</h1>
        <form @submit.prevent="handleSubmit">
            <div class="form-grp">
                <label for="email">Email</label>
                <input type="email" v-model="email" name="email" class="form-control" :class="{ 'is-invalid': submitted && !email }" autocomplete="email" />
                <div v-show="submitted && !email" class="invalid-feedback">Email is required</div>
            </div>
            <div class="form-grp">
                <label htmlFor="password">Password</label>
                <input type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" autocomplete="current-password" />
                <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
            </div>
            <div class="form-grp">
                <button class="btn btn-primary wide" :disabled="loading">Login</button>&nbsp;
                <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
            <div v-if="error" class="alert alert-danger">{{error}}</div>
            <div v-show="getverifiedlink">
                <p>
                    Can't find your verification id?<br />
                    <router-link to="/resend-verification-id">Resend Verification ID</router-link>
                </p>
            </div>
            <div v-show="!getverifiedlink">
                <p>
                    Don't have an account?<br>
                    <router-link :to="{ name: 'register', params: { r: 'r8jw934uHJnr' }}">Register</router-link>
                </p>
                <p>
                    Forgot your password?<br>
                    <router-link to="/forgot-password">Reset password</router-link>
                </p>
            </div>
        </form>
        </div>
    </div>
  </div>
</template>

<script>
import { router } from '../_helpers';
import { userService } from '../_services';
import { isLoggedIn } from "../utils/auth";

export default {
  title: 'Login | Foclarity',
  data () {
      return {
          email: '',
          password: '',
          getverifiedlink: false,
          submitted: false,
          loading: false,
          returnUrl: '',
          error: ''
      }
  },
  created () {
    if (isLoggedIn()) {
      this.$router.push("contact");
      return
    }

    // reset login status
    userService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = '/'; //this.$route.query.returnUrl
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
    // eslint-disable-next-line no-unused-vars
    handleSubmit (e) {
        this.submitted = true;
        const { email, password } = this;

        // stop here if form is invalid
        if (!(email && password)) {
            return;
        }

        this.loading = true;
        userService.login(email, password)
            .then(
                // eslint-disable-next-line no-unused-vars
                user => router.push(this.returnUrl),
                error => {
                    this.error = error ;
                    if (this.error == "Account not verified") {
                      this.getverifiedlink = true ;
                    }
                    this.loading = false ;
                }
            );
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  max-width: 400px;
  padding: 20px;
  & input {
    background: #eee ;
  }
}
</style>
