import { authHeader, router } from '../_helpers';

export const scorecardService = {
    createScorecard,
    readScorecards,
    readReport,
    readDashboards,
    readPublicScorecards,
    readScorecard,
    updateScorecard,
    cloneScorecard,
    transferScorecard,
    deleteScorecard,
    switchScorecard,
    createCategory,
    updateCategory,
    createMeasure,
    updateMeasure,
    switchCategory,
    switchMeasure,
    getMeasuresForCategory,
    getRankedMeasuresForCategory,
    getBenchmark,
    getActiveScorecard,
    getActiveCategory,
    createMeasureComment,
    updateMeasureComment,
    deleteMeasureComment,
    getMeasureComments
};
function deleteScorecard(scorecard_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({ scorecard_id })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/scorecards/" + scorecard_id 

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // console.log(data)
            // scorecard deletion is successful if there's a scorecard in the response with deleted = true
            if (data.scorecard) {
                // remove scorecard in local storage and redirect to dashboards
                localStorage.removeItem('scorecard');
                localStorage.setItem('scorecard', JSON.stringify(data.scorecard));
                return data.scorecard;
            } else {
                return data;
            }
        });
}
function createScorecard(scorecard_name, scorecard_description, scorecard_type_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ scorecard_name, scorecard_description, scorecard_type_id })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/scorecards" 

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // console.log(data)
            // scorecard creation is successful if there's a scorecard in the response
            if (data.scorecard) {
                // add scorecard to local storage
                localStorage.removeItem('scorecard');
                localStorage.setItem('scorecard', JSON.stringify(data.scorecard));
            }
            return data.scorecard;
        });
}

function cloneScorecard(scid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/scorecards/" + scid + "/copy"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function transferScorecard(scid, new_aid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/scorecards/" + scid + "/transfer/" + new_aid

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function updateScorecard(scorecard_id, scorecard_name, scorecard_description) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ scorecard_id, scorecard_name, scorecard_description })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/scorecards/" + scorecard_id 

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // console.log(data)
            // scorecard creation is successful if there's a scorecard in the response
            if (data.scorecard) {
                // replace scorecard in local storage
                localStorage.removeItem('scorecard');
                localStorage.setItem('scorecard', JSON.stringify(data.scorecard));
            }
            return data.scorecard;
        });
}

function updateCategory(scorecard_id, category_id, category_name, category_description) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ scorecard_id, category_id, category_name, category_description })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/scorecards/" + scorecard_id
        + "/categories/" + category_id

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            if (data.category) {
                // replace category in local storage
                localStorage.removeItem('category');
                localStorage.setItem('category', JSON.stringify(data.category));
            }
            return data.category;
        });
}

function updateMeasure(scorecard_id, category_id, measure_id, measure_name, measure_description) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ measure_name, measure_description })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/scorecards/" + scorecard_id
        + "/categories/" + category_id
        + "/measures/" + measure_id

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data.measure;
        });
}

function readScorecard(scorecard_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" 
        + uid 
        + "/accounts/" 
        + aid 
        + "/scorecards/" 
        + scorecard_id

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            // store scorecard details in local storage 
            localStorage.setItem('scorecard', JSON.stringify(data.scorecard));
            return data;
        })
        .catch(error => {
            console.log(error)
            router.push('/dashboards')
        });
}

function readScorecards() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/scorecards" 

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // scorecards array in the response
            if (data.scorecards) {
                // store scorecards in local storage 
                localStorage.setItem('scorecards', JSON.stringify(data.scorecards));
            }

            return data.scorecards;
        });

}

function readReport() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/report" 

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // report array in the response
            if (data.report.scorecards) {
                // store scorecards in local storage 
                localStorage.setItem('scorecards', JSON.stringify(data.report.scorecards));
            }

            return data.report;
        });

}
function readDashboards() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/dashboards" 

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // dashboards array in the response
            if (data.dashboards) {
                // store dashboards in local storage 
                localStorage.setItem('scorecards', JSON.stringify(data.dashboards.scorecards));
            }

            return data;
        });

}

function readPublicScorecards() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/scorecards" 

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // scorecards array in the response
            if (data.scorecards) {
                // store scorecards in local storage 
                localStorage.setItem('templates', JSON.stringify(data.scorecards));
            }

            return data.scorecards;
        });

}


function switchScorecard(scorecard_id) {
    // remove active scorecard from local storage
    localStorage.removeItem('scorecard');
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/scorecards/" + scorecard_id

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // login successful if there's an scorecard in the response
            if (data.scorecard) {
                // store scorecard details in local storage 
                localStorage.setItem('scorecard', JSON.stringify(data.scorecard));
            }

            return data.scorecard;
        });
}

function createCategory(category_name, category_description, scorecard_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ category_name, category_description, scorecard_id })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" 
        + uid 
        + "/accounts/" 
        + aid 
        + "/scorecards/"
        + scorecard_id
        + "/categories" 

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // console.log(data)
            // category creation is successful if there's a category in the response
            if (data.category) {
                //console.log(data.category)
            }
            return data.category;
        });
}

function createMeasure(measure_name, measure_description, scorecard_id, category_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ measure_name, measure_description, scorecard_id, category_id })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id    
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" 
        + uid 
        + "/accounts/" 
        + aid 
        + "/scorecards/"
        + scorecard_id
        + "/categories/"
        + category_id
        + "/measures"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            // measure creation is successful if there's a measure in the response
            if (data.measure) {
                //console.log(data.measure)
            }
            return data.measure;
        });
}

function switchCategory(scorecard_id, category_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/scorecards/" + scorecard_id + "/categories/" + category_id

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            localStorage.removeItem('category');
            localStorage.removeItem('measures');
            localStorage.setItem('category', JSON.stringify(data.category));
            localStorage.setItem('measures', JSON.stringify(data.measures));
            return data;
        });
}

function switchMeasure(scorecard_id, category_id, measure_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/scorecards/" 
                + scorecard_id 
                + "/categories/" 
                + category_id
                + "/measures/"
                + measure_id
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch(error => {
            console.log(error)
            router.push('/dashboards')
        });
}

function getRankedMeasuresForCategory(scorecard_id, category_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/scorecards/" + scorecard_id 
        + "/categories/" + category_id
        + "/measures/ranked"

        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getMeasuresForCategory(scorecard_id, category_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/scorecards/" + scorecard_id 
        + "/categories/" + category_id
        + "/measures"

        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function createMeasureComment(scorecard_id, category_id, measure_id, measure_comment) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ measure_comment })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid
                + "/accounts/" + aid 
                + "/scorecards/" + scorecard_id 
                + "/categories/" + category_id
                + "/measures/" + measure_id
                + "/measure_comments"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function updateMeasureComment(scorecard_id, category_id, measure_id, measure_comment_id, measure_comment) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ measure_comment })
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid
                + "/accounts/" + aid
                + "/scorecards/" + scorecard_id
                + "/categories/" + category_id
                + "/measures/" + measure_id
                + "/measure_comments/" + measure_comment_id

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function deleteMeasureComment(scorecard_id, category_id, measure_id, measure_comment_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid
                + "/accounts/" + aid
                + "/scorecards/" + scorecard_id
                + "/categories/" + category_id
                + "/measures/" + measure_id
                + "/measure_comments/" + measure_comment_id

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getMeasureComments(scorecard_id, category_id, measure_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid
                + "/accounts/" + aid 
                + "/scorecards/" + scorecard_id 
                + "/categories/" + category_id
                + "/measures/" + measure_id
                + "/measure_comments"

    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getBenchmark(scorecard_id, category_id, measure_id, benchmark_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id    
    let aid = JSON.parse(localStorage.getItem('account')).account_id
    var endpoint = process.env.VUE_APP_API_URL + "/api/v1/users/" + uid + "/accounts/" + aid + "/scorecards/" 
                + scorecard_id 
                + "/categories/" 
                + category_id
                + "/measures/"
                + measure_id
                + "/benchmarks/"
                + benchmark_id ;
    return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getActiveScorecard() {
    let scorecard = JSON.parse(localStorage.getItem('scorecard'))
    return  scorecard
}

function getActiveCategory() {
    let category = JSON.parse(localStorage.getItem('category'))
    return category
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

