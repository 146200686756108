<template>
  <div class="content bg8">
    <div class="middled">
      <div class="breadcrumbs">
          <router-link :to="{ name: 'dashboards'}">Dashboards</router-link>
      </div>
      <h1>
        Send Task Email Report
      </h1>
      <p>
        Send the overdue and due-today tasks to team members with tasks
      </p>
      <div>
        <div class="mission-form">
          <form @submit.prevent="handleSubmit">
              <div class="form-group">
                  <button class="btn btn-primary" :disabled="loading">Send Report</button> | <a href="/dashboards">Cancel</a>
                  <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              </div>
              <div v-if="error" class="alert alert-danger">{{error}}</div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";
import { accountService } from '../_services';

export default {
  title: 'Weekly Report | Foclarity',
  name: "Report",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboard");
    }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
    // eslint-disable-next-line no-unused-vars
    handleSubmit (e) {
        this.submitted = true;
        this.loading = true;
        accountService.emRpt()
            .then(
                // eslint-disable-next-line no-unused-vars
                user => {
                    this.error = 'Report sent. Return to Dashboards.';
                    this.loading = false
                    window.location.href= `/dashboards`
                },
                error => {
                    this.error = error;
                    this.loading = false;
                }
            );
    }    
  },
  components: {},
  data () {
      return {
        submitted: false,
        loading: false,
        returnUrl: 'dashboards',
        error: ''
      }
  },
  created () {
  }
};
</script>

<style lang="scss" scoped>
.mission-form {
  max-width: 800px;
  padding: 20px;
  & input {
    background: #eee ;
  }
}
</style>

