import { authHeader, router } from '../_helpers';

export const taskService = {
    createMeasureTask,
    readMeasureTasks,
    readAccountTasks,
    readScorecardTasks,
    updateMeasureTask,
    toggleMeasureTask,
    prioritizeMeasureTask,
    deleteMeasureTask
};

function createMeasureTask(scorecard_id, category_id, measure_id, task_description, prioritized, assigned_user_id, due_by) {
    /*
		assigned_user_id, // default blank for now
		task_description,
		prioritized // default 0 for now
    */
    if (task_description == "") {
        return Promise.reject("Task must include a description");
    }
    if (prioritized == "") {
        prioritized = false
    }
    if (assigned_user_id == "") {
        assigned_user_id = null
    }
    // if due_by is blank, set it to one week from now, formatted as 2006-01-02T15:04:05Z07:00
    if (due_by == null || due_by == "") {
        let today = new Date()
        let nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate()+7)
        due_by = nextWeek.toISOString()
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ 
            task_description, 
            prioritized, 
            assigned_user_id,
            due_by
        })
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/scorecards/" + scorecard_id
        + "/categories/" + category_id
        + "/measures/" + measure_id
        + "/tasks"
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function readMeasureTasks(scid, cid, mid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/scorecards/" + scid
        + "/categories/" + cid
        + "/measures/" + mid
        + "/tasks"
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            //console.log(data)
            return data;
        });
}

function readAccountTasks() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/tasks"
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // localStorage.setItem('snapshots', JSON.stringify(data.snapshots));
            return data;
        });
}

function readScorecardTasks(scid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid
        + "/scorecards/" + scid
        + "/tasks"
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data
        });
}

function updateMeasureTask(scorecard_id, category_id, measure_id, task_id, task_description, assigned_user_id, due_by, prioritized ) {
    if (assigned_user_id == "") {
        assigned_user_id = null
    }

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ 
            assigned_user_id,
            task_description,
            due_by,
            prioritized
        })
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/scorecards/" + scorecard_id
        + "/categories/" + category_id
        + "/measures/" + measure_id
        + "/tasks/" + task_id
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function toggleMeasureTask(scorecard_id, category_id, measure_id, task_id, completed ) {
    if (completed == "") {
        completed = false
    }

    completed = !completed
    
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ 
            completed
        })
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/scorecards/" + scorecard_id
        + "/categories/" + category_id
        + "/measures/" + measure_id
        + "/tasks/" + task_id
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function prioritizeMeasureTask(scorecard_id, category_id, measure_id, task_id, prioritized ) {
    if (prioritized == "") {
        prioritized = false
    }

    prioritized = !prioritized
    
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ 
            prioritized
        })
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/scorecards/" + scorecard_id
        + "/categories/" + category_id
        + "/measures/" + measure_id
        + "/tasks/" + task_id
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function deleteMeasureTask(scorecard_id, category_id, measure_id, task_id) {
    const deleted = true ;
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({ 
            deleted
        })
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/scorecards/" + scorecard_id
        + "/categories/" + category_id
        + "/measures/" + measure_id
        + "/tasks/" + task_id
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}



function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

