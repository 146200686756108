<template>
    <div class="content">
      <div class="middled">
        <div class="bodyblock" v-show="loading" style="text-align:center;padding:40px;font-size:2rem;">Loading...</div>
        <div class="dashboard-content" v-show="!loading">
          
          <div class="row searchrow">
            <div class="column">
              <div class="breadcrumbs">
                {{ account.account_name }}
              </div>
            </div>
            <div class="column">
              <searchbar v-bind:reported="report" v-bind:user="user" />
            </div>
          </div>
          
          <div class="row" v-if="user && user.first_name" style="margin-left:6px;font-size: .8em;">
            <div class="column">
              <div class="greeting">
                <strong>{{ greeting_message }}, {{ user.first_name }}! </strong>
              </div>
            </div>
          </div>

          <div style="margin-top:10px;"  v-show="user">
            <div>
              <MyTasks v-bind:user_id="user.user_id" :key="taskrender" />
            </div>
          </div>
          
          <div class="row" v-show="!loading && showInitiatives">
            <div class="column" >            
              <!-- Foclarity scorecards -->
              <div style="text-align:center;margin-top:20px;" v-if="!scorecards">
                <p>
                  <a href="/scorecards"><button class="wide">Create your first board</button></a>
                </p>
                <p>
                  or <a href="#" v-on:click.prevent="showVideo = true">watch a demo</a>
                </p>
              </div>
              
              <div v-show="scploading" style="padding:40px;margin:40px auto;text-align: center;background:#eee;border-radius: 10px;">Loading active boards...</div>

              <div class="column dash" v-show="false">
                <h2>
                  Progress Boards
                </h2>
              </div>
  
              <div class="row dashrow" style="margin-top:20px;justify-content:space-between;" v-if="scorecards">                
                <div class="column dashcard" v-for="(scp, index) in scorecards" :key="index" v-show="scp.deleted === false" style="margin-bottom:20px;">
                  <div class="row" v-on:click="gotoScorecard(scp.scorecard_id)" style="cursor: pointer;position:relative;">
                    <div class="categorycard" style="background: #d7e5f0; border-radius: 4px 4px 0 0;" :style="{background: getStat(scp.avg_score,20)}">
                      <div class="catname" :title="scp.scorecard_description">
                        {{scp.scorecard_name}}
                      </div>
                      <div class="nextarrow" style="margin-left:10px;" v-show="false">
                        <div style="width:auto; height:22px; border-radius: 11px;padding:1px 10px;font-size: 16px;color:#fff;" :style="{background: getStat(scp.avg_score)}">
                        {{ (scp.avg_score * 100).toFixed(0)}}<sup style="font-size: 10px;">%</sup>
                        </div>
                        <!-- <img :src="require('@/assets/ic_arrow_go.png')" alt="View More" /> -->
                      </div>
                    </div>
                    <div style="width:100%;background: #ccc;">
                      <div :style="{width: (scp.avg_score * 100) + '%', background: getStat(scp.avg_score)}" style="height: 2px;" ></div>
                    </div>
  
                    <!-- <div v-if="scp.summary.summary" style="padding:10px;font-size:small;min-height: 70px;white-space: break-spaces;">{{ scp.summary.summary | truncate(200, '... read more') }}</div> -->
                    <!-- <div v-if="scp.scorecard_description.length > 0" style="padding:10px;font-size:small;min-height: 70px;">{{ scp.scorecard_description | truncate(140, '...') }}</div>
                    <div v-else style="padding:10px;font-size:small;min-height: 70px;">No description</div> -->
  
  
                    <div style="width:100%;color:#666;padding:20px 20px 10px 20px;font-size: .8em;" v-if="scp.avg_score > .3">
                      <span v-if="scp.avg_score < .4">Falling behind.</span>
                      <span v-if="scp.avg_score > .3 && scp.avg_score < .6"><strong>Top Level Priorities</strong> are on track.</span>
                      <span v-if="scp.avg_score >= .6 && scp.avg_score < .8"><strong>Currently Active Priorities</strong> are almost complete.</span>
                      <span v-if="scp.avg_score >= .8">All <strong>Active Priorities</strong> in {{ scp.scorecard_name }} have been achieved! <em>Other priorities may still be on hold or have no status</em></span>
                    </div>
                    <div v-else style="width:100%;color:#666;padding:20px 20px 10px 20px;font-size: .8em;">
                      Click here to start working on {{ scp.scorecard_name }}.
                    </div>
  
                    <div :class="{blur: blurry}" v-on:mouseover="blurry = false" v-on:mouseout="blurry = true" style="width: 100%; min-height: 60px; text-align:center; font-size: 13px; font-weight: 500;padding: 0 0 12px 0;margin-bottom:28px;margin-top:10px;">
                      <div>
                        <div v-if="scp.recent_snapshot.snapshot_id" style="text-align: left;margin: 4px 30px;">
                          <strong>
                            {{ scp.recent_snapshot.category_name ? scp.recent_snapshot.category_name + ' > ' : '' }} 
                            {{ scp.recent_snapshot.measure_name ? scp.recent_snapshot.measure_name : '' }}
                          </strong>
                        </div>
                        <div v-if="scp.recent_snapshot.snapshot_id" style="text-align: left;margin:0 30px;padding:10px;border:1px solid #333;border-radius: 0px 10px 0 10px;">
                          <div v-if="scp.recent_snapshot.snapshot_id" style="text-align: left;margin:0;padding:0 0 10px 0;">
                            {{ scp.recent_snapshot.benchmark_description }} -- {{ scp.recent_snapshot.user_name ? scp.recent_snapshot.user_name : 'Team' }}
                          </div>
                          <div v-if="scp.recent_snapshot.snapshot_notes" style="overflow: hidden;">
                            &OpenCurlyDoubleQuote;{{ scp.recent_snapshot.snapshot_notes | truncate(140, '...')}}&CloseCurlyDoubleQuote;
                          </div>
                        </div>
                        <div v-if="scp.recent_snapshot.snapshot_id" style="text-align: right;margin:8px 30px;">
                          <strong>
                            {{ scp.recent_snapshot.created_at | moment("MMM DD, YYYY") }}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div :class="{blur: blurry}" v-on:click="gotoScorecard(scp.scorecard_id)" style="cursor: pointer;position:absolute;bottom:0;width:100%;border-top:2px solid #ccc;height:35px;" >
                    <div class="row" style="justify-content: space-evenly; width:100%;padding: 8px 0;font-size: small;">
                      <div v-if="scp.num_tasks_overdue > 0 && scp.num_tasks_overdue < 100" style="color:#ff0000;"><strong>{{ scp.num_tasks_overdue }} task<span v-show="scp.num_tasks_overdue != 1">s</span> overdue</strong></div>
                      <div v-if="scp.num_tasks_due_today > 0"><strong>{{ scp.num_tasks_due_today }}</strong> due today</div>
                      <div v-if="scp.num_tasks_overdue > 99">100+ tasks due!</div>
                      <div v-show="scp.num_tasks_overdue == 0 && scp.num_tasks_due_today == 0 && scp.num_tasks_due_this_week == 0" >No tasks</div>
                      <div v-show="scp.num_tasks_due_this_week > 0" >{{ scp.num_tasks_due_this_week }} this week</div>
                    </div>
                  </div>
                </div>
                <div v-show="false" class="column dashcard" style="margin-bottom:20px;" v-if="scorecards && (account.user_id === user.user_id)">
                  <a href="/scorecards">
                    <div style="text-align:center;margin:30%;">
                      <img :src="require('@/assets/ic_add.png')" width="28" height="28" alt="New Board" title="New Board" style="cursor:pointer;" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
  
          <div class="row" v-show="false">
            <div class="column" style="text-align:center;">
              <h2>Need Help?</h2>
              <p>
                <button @click.prevent="help = true" class="wide"> Work with an Advisor </button>
              </p>
            </div>
          </div>
  
          <div v-show="help">
            <div class="page-message" v-show="!loading">
              <div>
                <h4>Tell us what you need:</h4>
                <div class="form-grp">
                  <textarea v-model="message" name="message" />
                </div>
                <div class="form-grp">
                  <button class="btn btn-primary wide" :disabled="submitted" v-on:click="contactSupport">Send</button>&nbsp;
                  <a href="#" v-on:click.prevent="help = false"> Cancel </a>
                  <img v-show="submitted" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  <div v-if="emsg" class="emsg">{{emsg}}</div>
                  <div v-if="error" class="alert alert-danger">{{error}}</div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="row" v-show="false">
            <div class="column" style="cursor: pointer;" @click="showMission = !showMission; showTeam = false; showAccount = false;">
              <div :class="{activeButton : showMission}" style="height: 50px;border: 1px solid #ccc;text-align: center;border-radius:10px;line-height: 2.5em;">
                Mission &amp; Vision
              </div>
            </div>
            <div class="column" style="cursor: pointer;" @click="showTeam = !showTeam; showMission = false; showAccount = false;">
              <div :class="{activeButton : showTeam}" style="height: 50px;border: 1px solid #ccc;text-align: center;border-radius:10px;line-height: 2.5em;">
                Team
              </div>
            </div>
            <div v-if="(account.account_id) && (account.user_id === user.user_id)" class="column" style="cursor: pointer;" @click="showAccount = !showAccount; showTeam = false; showMission = false;">
              <div :class="{activeButton : showAccount}" style="height: 50px;border: 1px solid #ccc;text-align: center;border-radius:10px;line-height: 2.5em;">
                Account
              </div>
            </div>
          </div>
          
          <div class="row" v-show="false">
            <div class="column">
              <div class="row">
                <div class="column dash">
                  <h2>{{ account.account_name }} Team</h2>
                </div>
                <div class="column dash2">
                  <div style="text-align: right;margin:20px 0 0 0;">
                    <a href="#" v-on:click.prevent="toggleTeam = !toggleTeam">
                      <img v-if="(account.user_id === user.user_id)" :src="require('@/assets/ic_edit.png')" width="28" height="28" alt="Edit Team" title="Edit Team" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="mission">
                <div style="padding-left:0px;padding-top:20px;">
                  <div v-if="members">
                    <div class="row" v-for="(member,index) in members" :key="index">
                      <div v-if="member.member_profile_image" :style="'border-radius:50%;width:30px;height:30px;background:url(https://corl8-files.s3.amazonaws.com/' + member.member_profile_image +') no-repeat;background-size:cover;'"></div>                    
                      <div v-else>
                        <img style="width:30px;height:30px;" :src="require('@/assets/ic_person.png')" alt="profile icon" />
                      </div>
                      <div style="line-height:1.8rem;padding-left:4px;margin-bottom:10px;">
                        {{member.member_first_name}} {{member.member_last_name}} 
                        <span v-if="member.member_id === account.user_id">(Owner)</span>
                      </div>
                      <div v-show="toggleTeam" v-if="(member.member_id != account.user_id) && (account.user_id === user.user_id)" style="padding-left:4px;padding-top: 6px;">
                        <img v-on:click="deleteMember(member.member_id)" style="width:20px;height:20px;cursor: pointer;" :src="require('@/assets/ic_delete.png')" title="delete member" />
                      </div>
                      <div style="padding-left:4px;padding-top: 6px;">
                        <a :href="('mailto:' + member.member_email)" target="_blank">
                          <img style="width:20px;height:20px;" :src="require('@/assets/ic_email.png')" :title="member.member_email" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <p>Nobody here yet... Invite your team.</p>
                  </div>
                </div>
                <div v-if="account.user_id === user.user_id">
                  <div class="row" style="max-width:500px;">
                    <div class="column" style="flex-grow:2;">
                      <input type="text" v-model="invite_email" placeholder="Enter email">
                    </div>
                    <div class="column" style="min-width:100px;padding-top:15px;">
                      <button class="btn-add wide" :disabled="inviting" v-on:click="makeinvite">Invite</button>
                    </div>
                  </div>
                  <transition name="fade">
                    <div v-show="show_invite_message">
                      {{ invite_message }}
                    </div>
                  </transition>
                </div>
                <div v-else>
                  <div style="padding-left:10px;padding-top:10px;">
                    <p class="tiny">
                      The Owner listed above can invite others to the team.
                    </p>
                  </div>
                </div>
                <div v-if="(open_invites.length > 0) && (account.user_id === user.user_id)">
                  <div style="padding:10px 10px 10px 0;">
                    <div style="text-align: right;">
                      <a class="tiny" v-on:click="show_pending = !show_pending">
                        <span v-show="!show_pending">Show invitations</span>
                        <span v-show="show_pending">Hide invitations</span>
                      </a>
                    </div>
                    <div v-show="show_pending" class="row" v-for="invite in open_invites" :key="invite.invitation_id" style="margin-bottom: 8px;border-bottom:1px solid #ccc;">
                      <template v-if="invite.invitation_accepted != true">
                        <div>
                          <img style="width:30px;height:30px;" :src="require('@/assets/ic_person.png')" alt="person icon" />
                        </div>
                        <div style="padding-left:4px;line-height: 1.5em;flex:auto;">
                          {{invite.invitation_email}}
                        </div>
                        <div class="tiny" style="padding-left:34px;line-height: 3em;">
                          Invited {{ invite.created_at | moment("MMM DD, YYYY") }}
                          <a v-if="account.user_id === user.user_id" @click.prevent="deleteInvite(invite.invitation_id)">[ Delete ]</a>
                          <a :href="`mailto:${invite.invitation_email}?subject=Please join me on Foclarity&body=I am using Foclarity to track initiatives we are working on together. Please join at www.foclarity.com and accept my invitation.`" target="_blank">[ Send Reminder]</a>
                        </div>
                      </template>
                      <template v-else>
                        <div>
                          <img style="width:30px;height:30px;" :src="require('@/assets/ic_person.png')" alt="person icon" />
                        </div>
                        <div style="padding-left:4px;line-height: 1.5em;flex:auto;">
                          {{invite.invitation_email}}
                        </div>
                        <div class="tiny" style="padding-left:34px;line-height: 3em;">
                          Accepted {{ invite.updated_at | moment("MMM DD, YYYY") }}
                          <a v-if="account.user_id === user.user_id" @click.prevent="deleteInvite(invite.invitation_id)">[ Delete ]</a>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div id="mission" class="row" v-if="mission" >
            <div class="column" v-show="!loading">
              <div class="row">
                <div class="column dash">
                  <h2>
                    Our Mission
                    <span class="tiny"><a href="/mission">read all...</a></span>
                  </h2>
                </div>
                <div class="column dash2">
                  <div style="text-align: right;margin:20px 0 0 0;">
                    <a href="/mission">
                      <img v-if="(account.user_id === user.user_id)" :src="require('@/assets/ic_edit.png')" width="28" height="28" alt="Edit Mission" title="Edit Mission" />
                    </a>
                  </div>
                </div>
              </div>
              <div v-if="mission" class="mission">
                <div v-if="mission.account_summary">
                  <p style="padding:10px 0;">{{mission.account_summary}}</p>
                </div>
                <div v-else>
                  <p v-if="mission.account_mission" style="padding:10px 0;">{{mission.account_mission}}</p>
                  <p v-else style="padding:10px 0;"><a href="/mission">Go to Vision, Mission, Strategy...</a></p>
                </div>
              </div>
              <div v-else style="padding:0px;">
                <div v-if="account.user_id === user.user_id" >
                  <div class="mission">
                    <h4>Vision, Mission, and Strategy</h4>
                    <p>
                      <a href="/mission">Click here to get started...</a>
                    </p>
                  </div>
                </div>
                <div v-else>
                  <div class="mission">
                    <h4>Vision, Mission, and Strategy</h4>
                    <p>
                      The Account Owner has not yet entered the Vision, Mission, and Strategy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="mission">
            <div class="column" v-show="!loading">
              <div class="row">
                <div class="column dash">
                  <h2>
                    Executive Summary for {{ account.account_name }}
                  </h2>
                </div>
                <div class="column dash2">
                  <div style="text-align: right;margin:20px 0 0 0;">
                    <a href="#" v-on:click.prevent="generateAccountSummary()">
                      <img :src="require('@/assets/ic_sparkle.png')" width="24" height="24" alt="AI Summary" title="Generate AI Summary" style="padding:2px;margin:2px;cursor: pointer;border:2px solid #0099FF;border-radius: 50%;" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="mission">
                <div style="padding: 10px 0 ;">
                  <div>
                    <span v-if="scorecards">
                      <span v-if="generate_summary_link === ''">
                        <a href="#" v-on:click.prevent="streamResponse(account.summary);generate_summary_link='analyzing...'" v-show="account.summary.length > 0">
                          Get the executive summary for this account...
                          <img :src="require('@/assets/ic_sparkle.png')" width="20" height="20" alt="AI Summary" title="Generate AI Summary" style="cursor:pointer;margin-right:8px;" />
                        </a>
                      </span>
                      <span v-show="!account.summary">
                        Foclarity AI generates an executive summary from activity in your boards. Click the blue sparkle button to try it out.
                      </span>
                      <p v-if="summary_stream.length > 0"  style="white-space: break-spaces;"><span v-for="(message,index) in summary_stream" v-bind:key="index">{{ message.text }}</span></p>
                      <div v-show="generate_summary_link === 'Regenerate Account Summary'">
                        <br>
                        Updated {{ account.updated_at | moment("MMM DD, YYYY") }}<br>
                        <a href="#" v-on:click.prevent="generateAccountSummary()">
                          {{ generate_summary_link }}
                          <img :src="require('@/assets/ic_sparkle.png')" width="20" height="20" alt="AI Summary" title="Generate AI Summary" style="cursor:pointer;margin-right:8px;" />
                        </a>
                      </div>
                      <div v-show="generate_summary_link === '...'">
                        Processing, please wait while we analyze the data...
                      </div>
                    </span>
                    <span v-else>
                      <a href="#" v-on:click.prevent="showVideo = true">Click here to see how Foclarity works...</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
  
        <!-- use the modal component, pass in the prop -->
        <modal v-if="showVideo" @close="showVideo = false">
          <template v-slot:header>
            <h3>Foclarity Demo Video</h3>
          </template>
          <template v-slot:body>
            <iframe style="margin-top:10px;width: 100%; aspect-ratio: 16/9;" src="https://www.youtube.com/embed/Wi9PWCJ_2U0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        
          </template>
          <template v-slot:footer>
            <p>Foclarity helps your team get focused.</p>
          </template>
        </modal>                
  
      </div>
    </div>
  </template>
  
  <script>
  import { userService } from "../_services/user.service"
  import { accountService } from "../_services/account.service"
  import { router } from '../_helpers';
  import { scorecardService, snapshotService } from '../_services';
  import modal from "@/components/includes/InfoModal"
  import searchbar from "@/components/includes/SearchBar"
  import MyTasks from "@/components/includes/MyTasks"
  
  export default {
    title: 'Progress Boards | Foclarity',
    name: "Dashboards",
    data () {
        return {
          user: '',
          account: '',
          greeting_message: 'Hello',
          welcome_message: "Here's your overview for today.",
          account_summary: '',
          full_summary: false,
          summary_stream: [],
          generate_summary_link: "Generate an account summary using AI.",
          members: [],
          account_score: 0,
          taskrender: 0,
          snapshots: [],
          scid: 0,
          summary: "",
          loading: true,
          scploading: true,
          active: false,
          message: "",
          invite_email: "",
          invite_message: "",
          show_invite_message: false,
          open_invites: [],
          inviting: false,
          emsg: "",
          error: "",
          submitted: false,
          help: false,
          mission: "",
          report: [],
          showVideo: false,
          scorecards: [],
          showall: "",
          disableDraggable: true,
          show_pending: false,
          showMission: true,
          showInitiatives: true,
          showTeam: true,
          showAccount: true,
          toggleTeam: false,
          blurry: true
        }
    },
    methods: {
      toggleshow: function () {
        this.showall = !this.showall
        localStorage.setItem('showall', JSON.stringify(this.showall));
      },
      deleteMember: function (mid) {
        if(confirm("Delete this team member?")){
          if (this.account.user_id === this.user.user_id) {
          // user is account owner
          accountService.deleteMembership(mid).then(
            data => {
              this.invite_message = data.message
              this.show_invite_message = true ;
              this.getMembers(); // update the members list
              setTimeout(() => this.show_invite_message = false, 3000);
            },
            error => {
              this.invite_message = error
              this.show_invite_message = true ;
              setTimeout(() => this.show_invite_message = false, 3000);
            }
          )
        } else {
          // user is not account owner
          this.invite_message = "Only Account Owners can remove members."
          this.show_invite_message = true ;
          setTimeout(() => this.show_invite_message = false, 3000);
          }
        }
      },
      generateAccountSummary: function () {
        if(confirm("Generate a new account summary?")){
          this.account.summary = ""
          this.summary_stream = []
          this.generate_summary_link = "..."
          accountService.getAccountSummary().then(
            data => {
              this.account_summary = data.account_summary
              this.streamResponse(this.account_summary);
              this.contactSupport(data.account_summary);            
            })
          }
      },
      streamResponse: function (fullResponse) {
        const chunkSize = 10; // Customize this based on your needs
        this.generate_summary_link = ""
        for (let i = 0; i < fullResponse.length; i += chunkSize) {
          const chunk = fullResponse.substring(i, i + chunkSize);
          setTimeout(() => {
            this.summary_stream.push({ text: chunk, sender: 'bot' });
            if (i >= fullResponse.length - 10) {
              this.generate_summary_link = "Regenerate Account Summary"
            }
          }, i * 10); // Adjust timing as needed
        }
      },
      deleteInvite: function (iid) {
        if(confirm("Delete this invitation?")){
          if (this.account.user_id === this.user.user_id) {
          // user is account owner
          accountService.deleteOpenInvitation(iid).then(
            data => {
              this.invite_message = data.message
              this.show_invite_message = true ;
              this.getOpenInvitations(); // update the invitations list
              setTimeout(() => this.show_invite_message = false, 3000);
            },
            error => {
              this.invite_message = error
              this.show_invite_message = true ;
              setTimeout(() => this.show_invite_message = false, 3000);
            }
          )
        } else {
          // user is not account owner
          this.invite_message = "Ask the Account Owner if you want to add a collaborator."
          this.show_invite_message = true ;
          setTimeout(() => this.show_invite_message = false, 3000);
          }
        }
      },
      makeinvite: function () {
        this.inviting = true
        if (this.account.user_id === this.user.user_id) {
          // user is account owner
          accountService.createInvitation(this.invite_email).then(
            data => {
              this.invite_email = ""
              this.invite_message = data.message
              this.show_invite_message = true ;
              this.getOpenInvitations(); // update the invitations list
              setTimeout(() => this.show_invite_message = false, 3000);
            },
            error => {
              this.invite_email = ""
              this.invite_message = error
              this.show_invite_message = true ;
              setTimeout(() => this.show_invite_message = false, 3000);
            }
          )
        } else {
          // user is not account owner
          this.invite_message = "Ask the Account Owner if you want to add a collaborator."
          this.show_invite_message = true ;
          setTimeout(() => this.show_invite_message = false, 3000);
        }
        this.inviting = false ;
      },
      contactSupport: function (msg) {
        const subj = "Beta Feedback"
        this.submitted = true ;
  
        if (msg != "") {
          this.message = msg
        }
  
        if (this.message === "") {
          this.emsg = "" ;
          this.error = "Please enter a message" ;
          this.submitted = false ;
          return ;
        } 
  
        accountService.contactSupport(subj, this.message).then(
          data => {
            if (data.status == 'failed') {
              this.submitted = false ;
              this.error = 'failed to send message' ;
            } else {
              this.error = ""
              this.message = ""
              this.submitted = false ;
              this.emsg = "Feedback sent, thank you!"
              this.help = false;
            }
        },
        error => {
          this.error = error
          this.loading = false
        });
      },
      construction: (d) => {
        alert(d)
      },
      cardcolor: function (score) {
        if (score > 0 && score < .3) {
          return {'emerging-bullet': true}
        } else if (score >= .3 && score < .5) {
          return {'below-average-bullet': true}
        } else if (score >= .5 && score < .6) {
          return {'average-bullet': true}
        } else if (score >= .7 && score < .8) {
          return {'above-average-bullet': true}
        } else if (score >= .9) {
          return {'leadership-bullet': true}
        } else {
          return 'inactive-bullet'
        }
      },
      getMembers: function () {
        accountService.readAccountMembers().then(
          data => {
            this.members = data.members
          }
        )
      },
      getSnapshots: function () {
       snapshotService.readAccountSnapshots().then(
         data => {
           this.snapshots = data.snapshots
           this.account_score = data.account_score
           //console.debug(data.account_score)
         }
       )
      },
      generateRandomColor() {
        const randomColor = Math.floor(Math.random() * 16777215).toString(16);
        const bgColor = "#" + randomColor + " !important";
        return bgColor;
      },
      getMission: function () {
        this.loading = true
        accountService.getMission().then(
          data => {
            // console.debug(data)
            this.mission = data
            this.loading = false
          }
        )
        .catch(error => {
          this.error = error
          this.loading = false ;
        });      
      },
      getScorecards: function () {
        scorecardService.readDashboards().then(
          // scorecardService.readScorecards().then(
            data => {
            this.scploading = true
            this.report = data.report
            this.scorecards = data.dashboards.scorecards ;
            this.scploading = false
          } 
        )
        .catch(error => {
            this.loading = false ;
            this.error = error
        });      
      },
      goto: function (q) {
          router.push(q)
      },
      deleteOpenInvitation: function () {
        // todo
      },
      getOpenInvitations: function () {
        accountService.getOpenInvitations().then(data => {
          this.open_invites = []
          for (const invite of data.invitations) {
            if (invite.deleted != true) {
              this.open_invites.push(invite)
            }
          }
        })
      },
      getStat(avg_score, alpha) {
        var completion = avg_score * 100
        if (alpha == undefined) {
          alpha = "FF"
        }
        if (completion > 0 && completion < 10) {
          // return "#f8ae25" // orange color
          // return "#666" // gray color
          if (alpha > 0) {
            return "#e73c3c" + alpha // red color plus alpha value
          } else {
            return "#e73c3c" // red color
          }
        } else if (completion >= 10 && completion < 30) {
          if (alpha > 0) {
            return "#e73c3c" + alpha // red color plus alpha value
          } else {
            return "#e73c3c" // red color
          }
        } else if (completion >= 30 && completion < 60) {
          if (alpha > 0) {
            return "#2477e1" + alpha // blue color plus alpha value
          } else {
            return "#2477e1" // blue color
          }
        } else if (completion >= 60 && completion < 80) {
          if (alpha > 0) {
            return "#8d66f9" + alpha // purple color plus alpha value
          } else {
            return "#8d66f9" // purple color
          }
        } else if (completion >= 80 ) {
          if (alpha > 0) {
            return "#22c55e" + alpha // green color plus alpha value
          } else {
            return "#22c55e" // green color
          }
        } else {
          if (alpha > 0) {
            return "#666666" + alpha // gray color plus alpha value
          } else {
            return "#666666" // gray color
          }
        }
      },
      gotomeasure: function (q) {
        router.push("/scorecards/" + q.scorecard_id + "/categories/" + q.recent_snapshot.category_id + "/measures/" + q.recent_snapshot.measure_id)
      },
      gotoScorecard: (q) => {
        if (q == 'marketing') {
          router.push("scorecards/1")
        } else if (q == 'sales') {
          router.push("scorecards/10")
        } else if (q == 'ideas') {
          router.push("scorecards/86")
        }
        else {
          // console.debug('going to ' + q)
          router.push("/scorecards/" + q)
        }
      },
      checkDateStuff: function (d) {
        // check if user has logged in within the last 7 days
        var last_login = new Date(d)
        var today = new Date()
        var diff = (today - last_login) / (1000 * 60 * 60 * 24)
        if (diff > 7) {
          // console.debug('last login was ' + diff + ' days ago')
          userService.logout() ;
          router.push("/login") ;
        } else {
          // if it's evening say good evening
          var hour = today.getHours()
          if (hour > 17) {
            this.greeting_message = "Good evening"
          } else if (hour > 12) {
            this.greeting_message = "Good afternoon"
          } else {
            this.greeting_message = "Good morning"
          }
        }
      },
    },
    components: {
      modal,
      searchbar,
      MyTasks
    },
    created () {
      userService.getMe().then(data => {
        this.user = data
        this.checkDateStuff(this.user.last_login)
        this.account = accountService.getActiveAccount()
        if (this.account == null) {
          router.push("/accounts")
          return
        }
        if (this.account.account_name) {
          window.document.title = this.account.account_name + " Active Boards | Foclarity"
        }
        if (this.account.summary.length > 1) {
          this.generate_summary_link = ""
        }
        //this.getSnapshots();
        this.getMembers();
        this.getMission();
        this.getScorecards();
        // this.getReport() ;
        this.getOpenInvitations();
        this.taskrender = Math.floor(Math.random() * 100) + 1          
      });
    },
    computed: {
    },
    filters: {
      capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
      }
    }  
  };
  </script>
  
  <style lang="scss" scoped>
  .categorycard {
    cursor: pointer ;
    & .catname {
      color: #333 !important;
    }
  }
  
  .score {
    font-size: 4rem ;
    color: #000 ;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  
  .mission {
    padding:0 10px 0px 10px;
    margin:0px 0px 0 0;
    border-radius: 0 0 4px 4px;
    border-top: 2px solid #000;
    color:#000;
    background: #fff;
    background-repeat: no-repeat;
    background-size: cover;
  }
  #mission {
    white-space: break-spaces;
  }
  
  .activeButton {
    background: #0066CC;
    color: #fff;
    border: 1px solid #ccc;
  }
  
  h2, h4 {
    color: #333;
  }
  
  .column.dashcard {
    flex: 0 ;
    background: #fff;
    box-shadow: 0px 2px 3px #ccc;
    border-radius: 4px;
    padding:0px;
    // min-width:338px;
    min-width: 32%;
    border:1px solid #919191;
    @media only screen and (max-width: 1500px) {
      // min-width:320px;
      min-width: 32%;
      // flex: 1;
    }
    @media only screen and (max-width: 1130px) {
      // min-width:320px;
      min-width: 32%;
      // flex: 1;
    }
    @media only screen and (max-width: 1050px) {
      // min-width:320px;
      min-width: 49%;
      // flex: 1;
    }
    @media only screen and (max-width: 780px) {
      // min-width:320px;
      min-width: 100%;
      // flex: 1;
    }
  }
  .dashrow::after {
    content: "";
    flex: 1;
    max-width: 32%;
    min-width: 32%;
    @media only screen and (max-width: 1500px) {
      // min-width:320px;
      max-width: 32%;
      // flex: 1;
    }
    @media only screen and (max-width: 1145px) {
      // min-width:320px;
      max-width: 32%;
      // flex: 1;
    }
    @media only screen and (max-width: 1050px) {
      // min-width:320px;
      max-width: 49%;
      // flex: 1;
    }
    @media only screen and (max-width: 780px) {
      // min-width:320px;
      max-width: 100%;
      // flex: 1;
    }
  }
  .greeting {
    margin:10px 0;
    font-size: 1.25em;
    font-weight: bold;
    color: #000;
  }
  .greeting-small {
    margin:0;
    font-size: 1em;
    color:#666;
  }
  
  // todo - create a blur on mouseover for the card, not all the cards
  .blur000 {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    width: 100px;
    height: 100px;
    background-color: #fff;
  }
  </style>
  
  