<template>
  <div class="content">
    <div class="middled">
      <div class="breadcrumbs">
        <router-link :to="{ name: 'clients'}">Clients</router-link>
      </div>
      <div>
        <div class="row">
          <div class="column">
            <h1>
              All Invoices
            </h1>
            <div style="display: flex ; flex-direction: row ; flex-wrap: nowrap ; justify-content: space-between ;border-bottom:1px solid #ccc;">
              <div style="flex: 1;font-weight:bold;">
                Invoice
              </div>
              <div style="flex: 3;font-weight:bold;">
                Client
              </div>
              <div style="flex: 1;font-weight:bold;">
                Due
              </div>
              <div style="flex: 1;text-align:right;font-weight:bold;">
                Total
              </div>
            </div>

            <div style="display: flex ; flex-direction: row ; flex-wrap: nowrap ; justify-content: space-between ; cursor:pointer;  margin:2px 0 0 0;padding:8px;background:#eee;" v-on:click="gotoInvoice(invoice.client_id, invoice.invoice_id)" v-for="invoice in invoices" :key="invoice.invoice_id">
              <div style="flex: 1;">
                #{{invoice.invoice_number}}
              </div>
              <div style="flex: 3;">
                {{ invoice.billing_company_name }}
              </div>
              <div style="flex: 1; color:#676767;">
                <span v-if="invoice.invoice_paid == false" >{{ invoice.invoice_due_date | moment("MMM DD, YYYY") }}</span>
                <span v-else>PAID</span>
              </div>
              <div style="flex: 1;color:#676767;text-align:right;">
                {{ invoice.invoice_total / 100 | toCurrency }}
              </div>
            </div>

            <div v-show="invoices.length < 1" class="row spacer" >
              <div class="catname" style="padding:10px 0 ;text-align:center;width:100%;">
                Add <router-link :to="{ name: 'clients'}">Clients</router-link> first, then create Invoices for them.
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";
import { router } from '../_helpers';
import { invoiceService } from '../_services';

export default {
  title: 'Invoices | Foclarity',
  name: "Invoices",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  data () {
      return {
        name: '',
        invoices: [],
        loading: true,
        returnUrl: '',
        error: '',
        lastlogin: ''
      }
  },
  methods: {
    premiumFeature: (msg) => {
      alert(msg + " is a Premium Feature. Contact us to upgrade.")
    },
    isLoggedIn() {
      return isLoggedIn();
    },
    getInvoices: function () {
      this.loading = true ;
      invoiceService.readAccountInvoices().then(
      data => {
        //console.log(data.invoices)
        this.invoices = data.invoices ;
        this.loading = false;
      }
      )
      .catch(error => {
          console.debug(error)
          this.error = error ;
          this.loading = false ;
      });      
    },
    gotoInvoice: function (cid, iid) {
      //console.log(cid + ' ' + iid)
      router.push("/clients/" + cid + "/invoices/" + iid)
    }    
  },
  components: {},
  created () {
    this.getInvoices();
  }
};
</script>

<style lang="scss" scoped>
.welcomeback {
  padding: 40px;
  text-align: center;
  border: 1px solid #ccc;
}

.heroblue {
  background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
  & a {
    text-decoration: none;
    color: #fff;
    & .signupbox {
      text-align: center;
      padding: 8px;
      font-size: 24px;
      font-weight: bold;
      background: #ff7f24;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: auto;
    }
  }
}

</style>

