<template>
  <div class="content bg8">
    <div class="middled">
      <div class="verify" style="margin:auto;">
        <h1 style="margin:20px 0;">Verify</h1>
        <form @submit.prevent="handleSubmit">
            <div class="form-grp">
                <label for="verification_id">Verification ID</label>
                <input type="text" v-model="verification_id" name="verification_id" class="form-control" />
                <div v-show="submitted && !verification_id" class="invalid-feedback">Verification ID is required</div>
            </div>
            <div class="form-grp">
                <button class="btn btn-primary" :disabled="loading">Verify</button>
                <img v-show="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
            <div v-if="error" class="alert alert-danger">{{error}}</div>
            <div>
                <p>
                    Don't have a Verification ID?<br />
                    First, check your inbox and junk mail. If you still can't find it get another one
                    <router-link to="/resend-verification-id">here</router-link>
                </p>
            </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { router } from '../_helpers';
import { userService } from '../_services';

export default {
  title: 'Verify | Foclarity',
    data () {
        return {
            verification_id: this.$route.query.q,
            submitted: false,
            loading: false,
            returnUrl: '',
            error: ''
        }
    },
    created () {
        // reset login status
        userService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = '/login';
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        handleSubmit (e) {
            this.submitted = true;
            const { verification_id } = this;

            // stop here if form is invalid
            if (!(verification_id)) {
                return;
            }

            this.loading = true;
            userService.verify(verification_id)
                .then(
                    // eslint-disable-next-line no-unused-vars
                    user => router.push(this.returnUrl),
                    error => {
                        if (error == "sql: no rows in result set") {
                            this.error = "Verification ID is not valid"
                        } else {
                            this.error = error;
                        }
                        this.loading = false;
                    }
                );
        }
    }
};
</script>

<style lang="scss" scoped>
.verify {
  max-width: 400px;
  padding: 20px;
  & input {
    background: #eee ;
  }
}

.welcomeback {
  padding: 40px;
  text-align: center;
  border: 1px solid #ccc;
}
label {
  display: block;
  margin-top: 10px;
  display: none;
}
</style>
