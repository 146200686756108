import Vue from 'vue';
import Router from 'vue-router';

import HomePage from '../views/HomePage'
import Contact from '../views/Contact'
import WhoWeAre from '../views/WhoWeAre'
import WhoWeServe from '../views/WhoWeServe'
import Incubators from '../views/Incubators'
import Privacy from '../views/Privacy'
import BusinessTransformation from '../views/BusinessTransformation.vue'
import TheFoclarityWay from '../views/TheFoclarityWay'
import StartupLaunch from '../views/StartupLaunch'
import Resources from '../views/Resources'
import StartupFounders from '../views/StartupFounders.vue'
import Pricing from '../views/Pricing'
import TermsAndConditions from '../views/TermsAndConditions'
import BusinessCoaching from '../views/BusinessCoaching'
import Dashboard from '../views/Dashboard'
import Boards from '../views/Boards'
import Team from '../views/Team'
import Accounts from '../views/Accounts'
import Account from '../views/Account'
import Clients from '../views/Clients'
import Client from '../views/Client'
import NewClient from '../views/NewClient'
import Invoices from '../views/Invoices'
import Invoice from '../views/Invoice'
import Todos from '../views/Todos'
import Todo from '../views/Todo'
import Payments from '../views/Payments'
import Project from '../views/Project'
import NewProject from '../views/NewProject'
import Scorecards from '../views/Scorecards'
import Templates from '../views/Templates'
import Scorecard from '../views/Scorecard'
// import Canvas from '../views/Canvas'
import NewScorecard from '../views/NewScorecard'
import Category from '../views/Category'
import NewCategory from '../views/NewCategory'
import NewMeasure from '../views/NewMeasure'
import Measure from '../views/Measure'
import Benchmark from '../views/Benchmark'
import Profile from '../views/Profile'
import Mission from '../views/Mission'
import EmailReport from '../views/EmailReport'
import LoginPage from '../views/LoginPage'
import VerifyPage from '../views/VerifyPage'
import GetVerificationID from '../views/GetVerificationID'
import ForgotPassword from '../views/ForgotPassword'
import ResetPassword from '../views/ResetPassword'
import RegisterPage from '../views/RegisterPage'

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  scrollBehavior: function (to) {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    { path: '/', name: 'home', component: HomePage },
    { path: '/contact', name: 'contact', component: Contact },
    { path: '/who-we-are', name: 'who-we-are', component: WhoWeAre },
    { path: '/who-we-serve', name: 'who-we-serve', component: WhoWeServe },
    { path: '/incubators', name: 'incubators', component: Incubators },
    { path: '/privacy', name: 'privacy', component: Privacy },
    { path: '/terms-and-conditions', name: 'terms-and-conditions', component: TermsAndConditions, props: true },
    { path: '/business-transformation', name: 'business-transformation', component: BusinessTransformation },
    { path: '/the-foclarity-way', name: 'the-foclarity-way', component: TheFoclarityWay },
    { path: '/startup-launch', name: 'startup-launch', component: StartupLaunch },
    { path: '/resources', name: 'resources', component: Resources },
    { path: '/startup-founders', name: 'startup-founders', component: StartupFounders },
    { path: '/pricing', name: 'pricing', component: Pricing },
    { path: '/business-coaching', name: 'business-coaching', component: BusinessCoaching },
    { path: '/login', name: 'login', component: LoginPage },
    { path: '/verify', name: 'verify', component: VerifyPage },
    { path: '/resend-verification-id', name: 'resend-verification-id', component: GetVerificationID },
    { path: '/forgot-password', name: 'forgot-password', component: ForgotPassword },
    { path: '/reset-password', name: 'reset-password', component: ResetPassword },
    { path: '/register', name: 'register', component: RegisterPage, props: true },
    { path: '/dashboard', name: 'dashboard', component: Dashboard },
    { path: '/dashboards', name: 'dashboards', component: Boards },
    { path: '/team', name: 'team', component: Team },
    { path: '/accounts', name: 'accounts', component: Accounts },
    { path: '/accounts/:account_id', name: 'account', component: Account, props: true },
    { path: '/accounts/:account_id/invoices', name: 'invoices', component: Invoices },
    { path: '/accounts/:account_id/payments', name: 'payments', component: Payments },
    { path: '/todos', name: 'todos', component: Todos },
    { path: '/todos/:todo_id', name: 'todo', component: Todo },
    { path: '/clients', name: 'clients', component: Clients },
    { path: '/clients/:client_id', name: 'client', component: Client, props: true },
    // { path: '/clients/:client_id/invoices', name: 'invoices', component: Invoices, props: true },
    { path: '/clients/:client_id/invoices/:invoice_id', name: 'invoice', component: Invoice, props: true },
    { path: '/clients/:client_id/projects/:project_id', name: 'project', component: Project, props: true },
    { path: '/clients/:client_id/new-project', name: 'new-project', component: NewProject },
    { path: '/new-client', name: 'new-client', component: NewClient },
    { path: '/new-scorecard', name: 'new-scorecard', component: NewScorecard },
    { path: '/scorecards/:scorecard_id/new-category', name: 'new-category', component: NewCategory, props: true },
    { path: '/scorecards', name: 'scorecards', component: Scorecards },
    { path: '/templates', name: 'templates', component: Templates },
    { path: '/scorecards/:scorecard_id', name: 'scorecard', component: Scorecard, props: true },
    { path: '/scorecards/:scorecard_id/categories/:category_id', name: 'category', component: Category, props: true },
    { path: '/scorecards/:scorecard_id/categories/:category_id/new-measure', name: 'new-measure', component: NewMeasure, props: true },
    { path: '/scorecards/:scorecard_id/categories/:category_id/measures/:measure_id', name: 'measure', component: Measure, props: true },
    { path: '/scorecards/:scorecard_id/categories/:category_id/measures/:measure_id/benchmarks/:benchmark_id', name: 'benchmark', component: Benchmark, props: true },
    { path: '/profile', name: 'profile', component: Profile },
    { path: '/mission', name: 'mission', component: Mission },
    { path: '/emreport', name: 'emreport', component: EmailReport },
    { path: '/strategic-planning', redirect: '/business-transformation'},

    // otherwise redirect to home
    { path: '*', redirect: '/' }
  ]
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    '/', 
    '/contact', 
    '/who-we-are', 
    '/who-we-serve', 
    '/incubators', 
    '/privacy', 
    '/terms-and-conditions',
    '/business-transformation',
    '/the-foclarity-way',
    '/startup-launch', 
    '/resources', 
    '/startup-founders', 
    '/pricing', 
    '/business-coaching', 
    '/login', 
    '/register', 
    '/verify', 
    '/resend-verification-id', 
    '/forgot-password',
    '/reset-password'
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next({ 
      path: '/login', 
      query: { returnUrl: to.path } 
    });
  }

  next();
})
