<template>    
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <div @click="$emit('close')" style="float:right;cursor:pointer;">
                Close
            </div>
            <slot name="header">
                <h4>Upload a Logo:</h4>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
                <div>
                    <img v-show="image" style="max-width:300px;" :src="image" alt="logo">
                    <div style="margin-top:10px;">
                        <input type="file" name="logo" accept="image/png, image/jpeg" @change="onFileChange">
                    </div>
                    <div style="margin-top:10px;">
                        <button class="btn-add" @click="uploadLogo" style="margin-right:10px;">Save</button>
                    </div>

                    <div v-if="msg" class="emsg">{{msg}}</div>
                    <div v-if="error" class="alert alert-danger">{{error}}</div>
                </div>
            </slot>
          </div>

          <div class="modal-footer">
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { accountService } from '../../_services'
export default {
    props: [
        'account'
    ],
    data () {
        return {
            message: "",
            image: '',
            error: "",
            msg: "",
            submitted: false
        }
    },
    methods: {
        uploadLogo: function () {            
            // const formData = new FormData();
            const fileField = document.querySelector('input[name="logo"]');
            // console.log(fileField)
            // formData.append('logo', fileField.files[0]);

            // fetch('https://example.com/profile/avatar', {
            //     method: 'PUT',
            //     body: formData
            // })
            // .then(response => response.json())
            // .then(result => {
            //     console.log('Success:', result);
            // })
            // .catch(error => {
            //     console.error('Error:', error);
            // });

            accountService.uploadLogo(fileField.files[0]).then(
                data => {
                    this.msg = data.message
                    this.account.logo_url = data.new_filename
                    console.log(data.new_filename)
                    this.loading = false
                    this.$emit('close')
                },
                error => {
                    this.error = error
                    this.loading = false
                }
            )
        },
        onFileChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) {
            console.log("No file length")
            return;
        }
        
        // console.log(files[0].length)
        this.createImage(files[0]);
        },
        createImage(file) {
            var reader = new FileReader();
            reader.onload = (e) => {
                this.image = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }
}
</script>
