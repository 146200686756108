import { authHeader, router } from '../_helpers';

export const ptaskService = {
    createPTask,
    readPTasks,
    readClientTasks,
    readCreatorTasks,
    updatePTask,
    togglePTask,
    prioritizePTask,
    deletePTask
};

function createPTask(cid, pid, ptask_name, ptask_description, prioritized, assigned_user_id) {
    /*
		assigned_user_id, // default blank for now
		task_description,
		prioritized // default 0 for now
    */
    if (prioritized == "") {
        prioritized = false
    }
    if (assigned_user_id == "") {
        assigned_user_id = null
    }
    if (ptask_name == "") {
        return Promise.reject("Task must include a name");
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ 
            ptask_name,
            ptask_description, 
            prioritized, 
            assigned_user_id
        })
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/clients/" + cid
        + "/projects/" + pid
        + "/ptasks"
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function readPTasks(cid, pid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/clients/" + cid
        + "/projects/" + pid
        + "/ptasks"
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // console.log(data)
            return data;
        });
}

function readClientTasks(cid) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    if (localStorage.getItem('client') === null) {
        router.push("/clients")
        return Promise.reject("No client selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/clients/" + cid 
        + "/ptasks"
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            // localStorage.setItem('snapshots', JSON.stringify(data.snapshots));
            return data;
        });
}

function readCreatorTasks() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let uid = JSON.parse(localStorage.getItem('user')).user_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/ptasks"
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data
        });
}

function updatePTask(
        cid,
        pid,
        ptask_id, 
        ptask_name, 
        ptask_description, 
        due_by, 
        prioritized ) {

    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ 
            ptask_name,
            ptask_description,
            due_by,
            prioritized
        })
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/clients/" + cid 
        + "/projects/" + pid 
        + "/ptasks/" + ptask_id
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function togglePTask(cid, pid, ptask_id, completed ) {
    if (completed == "") {
        completed = false
    }

    completed = !completed
    
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ 
            completed
        })
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/clients/" + cid
        + "/projects/" + pid
        + "/ptasks/" + ptask_id
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function prioritizePTask(cid, pid, ptask_id, prioritized ) {
    if (prioritized == "") {
        prioritized = false
    }

    prioritized = !prioritized
    
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({ 
            prioritized
        })
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/clients/" + cid
        + "/projects/" + pid
        + "/ptasks/" + ptask_id
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function deletePTask(cid, pid, ptask_id) {
    const deleted = true ;
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify({ 
            deleted
        })
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/clients/" + cid
        + "/projects/" + pid
        + "/ptasks/" + ptask_id
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}



function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

